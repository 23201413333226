.ready-to-win {
	background: url(../images/backgrounds/ready-to-win-bg.png) center no-repeat;
	background-size: cover;
	text-align: center;
	padding: rem(140) 0;


	h2 {
		font-size: 45px;
	}

	.btn {
		display: block;
		width: 230px;
		font-size: 14px;
		margin: 0 auto;
	}

	.btn-fb {
		text-transform: initial !important;
		background: #2e8ce4 url(../images/svg/fb-like.svg) 20px center no-repeat;

		&:hover {
			background-color: #1670c5;
		}
	}

	.btn-favourites {
		color: rgba(#fff,.67);
		text-transform: initial !important;
		text-decoration: underline;
		background: url(../images/svg/btn-favourites-op.svg) 20px center no-repeat;

		&:hover {
			color: #fff;
					background: url(../images/svg/btn-favourites.svg) 20px center no-repeat;
		}
	}
}



@include media-breakpoint-up(md) {
	.ready-to-win {
		height: rem(490);
	}
}

@include media-breakpoint-down(sm) {

	.ready-to-win {
		padding: 50px 0;

		h2 {
			font-size: 25px;
		}
	}
}

@include media-breakpoint-down(xs) {

	.ready-to-win {

		.btn {
			width: 100%;
		}

		.btn-fb,
		.btn-favourites {
			background-position: calc(50% - 90px);
		}
	}
}

