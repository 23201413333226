.countries {
	background-color: #f2f4f5;
	padding: 20px 30px 0;

	ul {

		li {
			color: #666;
			line-height: 1;
			padding: 12px 0;
		}

		li:not(:last-child) {
			border-bottom: 1px solid rgba(#000,.05);
		}
	}
}

.additions-accepts-content {

	.accepts-title {
		line-height: 1;
	}
}

@include media-breakpoint-down(xs) {

	.additions-accepts-content,
	.additions-restricted-content {

		.accepts-title {
			position: relative;
			padding-left: 40px;
		}

		h2 {
			font-size: 24px;
		}
	}

	.accepts-list-icon {
		position: absolute;
		left: 0;
		top: 0;
	}
}

@include media-breakpoint-only(sm) {
	.additions-accepts-content {

		.bonus-baner {
			padding: 55px 65px 35px;
		}
	}
}