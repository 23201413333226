.filter-wrapper {
	background-color: #f0f1f3;
}

.btn {

	&__state {


    &--default {

      .btn__state[aria-expanded="true"] &,
      .btn__state.active:not([aria-expanded]) & {
        @extend .d-none;
      }
    }

    &--active {
    	// background-color: #fff;
        
      .btn__state[aria-expanded="false"] &,
      .btn__state:not(.active):not([aria-expanded]) & {
        @extend .d-none;
      }
    }
  }
}

.filter-button {
	width: 100%;
	height: 53px;
	font-size: 18px;
	line-height: (53/18);
	color: #4e5965;
	box-shadow: inset -1px 0 0 rgba(#000,.05);
	padding: 0 15px;
	@include media-breakpoint-down(sm) {
		font-size: 15px;
	}

	&:hover {
    background-color: #fff;
  }

  &.active {
  	background-color: #fff;
  	border-right: none;
  	border-bottom: 1px solid #f0f1f3;
  }

  span {
  	margin-right: 10px;
  }
}

.filter {
		
		.swiper-slide {
			width: auto;
		}
}

.slots-filters {
	font-size: 14px;
	border-bottom: 1px solid rgba(#000,.05);
}

.filter-label {
	// font-size: 14px;
	color: rgba(#292c2e,.5);
}

.slots-sort-btn {
	color: #292c2e;
	background-color: #f0f1f3;
	border: 1px solid #ccc;
	padding: 10px 17px;

	&.active {
		background-color: #fff;
		color: rgba(#292c2e,.7);
	}
}

.volatility-btn {
	position: relative;
	background-color: #f0f1f3;
	color: rgba(#292c2e,.7);
	padding-left: 40px;

	&.active {
		background-color: #fff;
		color: #292c2e;

		.radio-btn {
			border-color: $red;

			&:before {
				position: absolute;
				content: '';
				width: 10px;
				height: 10px;
				background-color: $red;
				@extend .rounded-circle;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto; 
			}
		}
	}
}

.select-btn {
	background: #fff str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='6' height='5' viewBox='0 0 6 5'%3e%3cpath fill='#7f7f7f' d='M3.002,5.000 L-0.000,-0.004 L6.005,-0.004 L3.002,5.000 Z'/%3e%3c/svg%3e"), "#", "%23") calc(100% - 17px) center no-repeat;
	width: 220px;
}

.radio-btn {
	position: absolute;
	background-color: #fff;
	width: 18px;
	height: 18px;
	line-height: 46px;
	left: 10px;
	top: 0;
	bottom: 0;
	margin: auto;
	border: 1px solid #ccc;
}

.btn-morer-slots {
	font-size: 12px;
}

@include media-breakpoint-down(md) {

	.select-btn {
		width: 100%;
	}
}

@include media-breakpoint-down(xs) {

	.slots-sort-btn {
		padding: 10px 13px;
	}

	.volatility-btn {
		padding-left: 35px;
	}
}