.divider-section {
	border-top: 2px solid fade($black, 5);
}

.big-f-size {
	font-size: 40px;
	line-height: 1;
}

.h-100 {
	height: 100%!important;
}

.w-100 {
	width: 100%!important;
}

.rules-title {
	font-size: 18px;
}

.f-size-0 {
	font-size: 0;
}

.small-text {
	font-size: 11px;
}

@include media-breakpoint-down(sm) {
	.reduce-figure {
		max-width: 50%;
		margin: 0 auto;
	}
}

@include media-breakpoint-down(md) {

	.big-f-size {
		font-size: 30px;
	}
}
