html.lb-disable-scrolling {
  overflow: hidden;
  /* Position fixed required for iOS. Just putting overflow: hidden; on the body is not enough. */
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;

  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;

  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1;
}
.swiper-container-no-flexbox .swiper-slide {
  float: left;
}
.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}
/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Muli", sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.6;
  color: #000;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #cd0303;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #666666;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.3;
  color: inherit; }

h1, .h1 {
  font-size: 2.1875rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 15px; }

h6, .h6 {
  font-size: 12px; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.3; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.3; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.3; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.3; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: -4px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 0;
  font-size: 15px; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #666666; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #666666; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f2f2f2;
  border-radius: 4px; }
  a > code {
    padding: 0;
    color: inherit;
    background-color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1230px) {
    .container {
      max-width: 1200px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row, .subscripe-box form, .subscribe form {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .subscripe-box input[type="text"],
.subscripe-box input[type="email"], .subscripe-box input[type="submit"], .subscribe input[type=text], .subscribe input[type=submit], .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12, .subscripe-box input[type="text"],
.subscripe-box input[type="email"], .subscripe-box input[type="submit"], .subscribe input[type=text], .subscribe input[type=submit] {
  flex: 0 0 100%;
  max-width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; } }

@media (min-width: 1230px) {
  .col-lg, .subscripe-box input[type="text"],
  .subscripe-box input[type="email"], .subscribe input[type=text] {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto, .subscripe-box input[type="submit"], .subscribe input[type=submit] {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9ecef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef; }
  .table tbody + tbody {
    border-top: 2px solid #e9ecef; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e9ecef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e9ecef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f5f6f6; }

.table-hover .table-secondary:hover {
  background-color: #e8eaea; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #e8eaea; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c6f1c6; }

.table-hover .table-success:hover {
  background-color: #b2ecb2; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b2ecb2; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f1b8b8; }

.table-hover .table-danger:hover {
  background-color: #eda3a3; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #eda3a3; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfbfb; }

.table-hover .table-light:hover {
  background-color: #eeeeee; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eeeeee; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.thead-inverse th {
  color: #fff;
  background-color: #212529; }

.thead-default th {
  color: #4c4c4c;
  background-color: #e9ecef; }

.table-inverse {
  color: #fff;
  background-color: #212529; }
  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    border-color: #32383e; }
  .table-inverse.table-bordered {
    border: 0; }
  .table-inverse.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-inverse.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 1229px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive.table-bordered {
      border: 0; } }

.form-control {
  display: block;
  width: 100%;
  padding: 15px 20px;
  font-size: 15px;
  line-height: 1;
  color: #4c4c4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #4c4c4c;
    background-color: #fff;
    border-color: #80bdff;
    outline: none; }
  .form-control::placeholder {
    color: #666666;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(15px + 4px); }

select.form-control:focus::-ms-value {
  color: #4c4c4c;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block; }

.col-form-label {
  padding-top: calc(15px - 2px * 2);
  padding-bottom: calc(15px - 2px * 2);
  margin-bottom: 0; }

.col-form-label-lg {
  padding-top: calc(13px - 2px * 2);
  padding-bottom: calc(13px - 2px * 2);
  font-size: 16px; }

.col-form-label-sm {
  padding-top: calc(6px - 2px * 2);
  padding-bottom: calc(6px - 2px * 2);
  font-size: 12px; }

.col-form-legend {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 0;
  font-size: 15px; }

.form-control-plaintext {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 0;
  line-height: 1;
  border: solid transparent;
  border-width: 2px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .form-control-plaintext.input-group-addon,
  .input-group-sm > .input-group-btn > .form-control-plaintext.btn, .subscripe-box .input-group-sm > .input-group-btn > input.form-control-plaintext[type="submit"], .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .form-control-plaintext.input-group-addon,
  .input-group-lg > .input-group-btn > .form-control-plaintext.btn, .subscripe-box .input-group-lg > .input-group-btn > input.form-control-plaintext[type="submit"] {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn, .subscripe-box .input-group-sm > .input-group-btn > input[type="submit"] {
  padding: 6px 8px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: 12px; }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn, .subscripe-box .input-group-lg > .input-group-btn > input[type="submit"] {
  padding: 13px 4.0625rem;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(16px + 4px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem; }
  .form-check.disabled .form-check-label {
    color: #666666; }

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }
  .form-check-input:only-child {
    position: static; }

.form-check-inline {
  display: inline-block; }
  .form-check-inline .form-check-label {
    vertical-align: middle; }
  .form-check-inline + .form-check-inline {
    margin-left: 0.75rem; }

.invalid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #cd0303; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(205, 3, 3, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #33cc33; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 51, 0.25); }
  .was-validated .form-control:valid ~ .invalid-feedback,
  .was-validated .form-control:valid ~ .invalid-tooltip, .form-control.is-valid ~ .invalid-feedback,
  .form-control.is-valid ~ .invalid-tooltip, .was-validated
  .custom-select:valid ~ .invalid-feedback,
  .was-validated
  .custom-select:valid ~ .invalid-tooltip,
  .custom-select.is-valid ~ .invalid-feedback,
  .custom-select.is-valid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #33cc33; }

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(51, 204, 51, 0.25); }

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #33cc33; }

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #33cc33; }
  .was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 204, 51, 0.25); }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #cd0303; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(205, 3, 3, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #cd0303; }

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(205, 3, 3, 0.25); }

.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #cd0303; }

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #cd0303; }
  .was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 3, 3, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-top: 0;
      margin-bottom: 0; }
    .form-inline .form-check-label {
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0; }
    .form-inline .custom-control-indicator {
      position: static;
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: text-bottom; }
    .form-inline .has-feedback .form-control-feedback {
      top: 0; } }

.btn, .subscripe-box input[type="submit"] {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 2px solid transparent;
  padding: 15px 20px;
  font-size: 15px;
  line-height: 1;
  border-radius: 4px;
  transition: all 0.25s ease-in-out; }
  .btn:focus, .subscripe-box input:focus[type="submit"], .btn:hover, .subscripe-box input:hover[type="submit"] {
    text-decoration: none; }
  .btn:focus, .subscripe-box input:focus[type="submit"], .btn.focus, .subscripe-box input.focus[type="submit"] {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .subscripe-box input.disabled[type="submit"], .btn:disabled, .subscripe-box input:disabled[type="submit"] {
    opacity: .65; }
  .btn:active, .subscripe-box input:active[type="submit"], .btn.active, .subscripe-box input.active[type="submit"] {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #0069d9;
    background-image: none;
    border-color: #0062cc; }

.btn-secondary {
  color: #111;
  background-color: #dbdee0;
  border-color: #dbdee0; }
  .btn-secondary:hover {
    color: #111;
    background-color: #c6cbce;
    border-color: #c0c5c8; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 3px rgba(219, 222, 224, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #dbdee0;
    border-color: #dbdee0; }
  .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #c6cbce;
    background-image: none;
    border-color: #c0c5c8; }

.btn-success {
  color: #fff;
  background-color: #33cc33;
  border-color: #33cc33; }
  .btn-success:hover {
    color: #fff;
    background-color: #2bad2b;
    border-color: #29a329; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 3px rgba(51, 204, 51, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #33cc33;
    border-color: #33cc33; }
  .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    background-color: #2bad2b;
    background-image: none;
    border-color: #29a329; }

.btn-danger, .subscripe-box input[type="submit"] {
  color: #fff;
  background-color: #cd0303;
  border-color: #cd0303; }
  .btn-danger:hover, .subscripe-box input:hover[type="submit"] {
    color: #fff;
    background-color: #a70202;
    border-color: #9b0202; }
  .btn-danger:focus, .subscripe-box input:focus[type="submit"], .btn-danger.focus, .subscripe-box input.focus[type="submit"] {
    box-shadow: 0 0 0 3px rgba(205, 3, 3, 0.5); }
  .btn-danger.disabled, .subscripe-box input.disabled[type="submit"], .btn-danger:disabled, .subscripe-box input:disabled[type="submit"] {
    background-color: #cd0303;
    border-color: #cd0303; }
  .btn-danger:active, .subscripe-box input:active[type="submit"], .btn-danger.active, .subscripe-box input.active[type="submit"],
  .show > .btn-danger.dropdown-toggle,
  .subscripe-box .show > input.dropdown-toggle[type="submit"] {
    background-color: #a70202;
    background-image: none;
    border-color: #9b0202; }

.btn-light {
  color: #111;
  background-color: #f2f2f2;
  border-color: #f2f2f2; }
  .btn-light:hover {
    color: #111;
    background-color: #dfdfdf;
    border-color: #d9d8d8; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 3px rgba(242, 242, 242, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    background-color: #dfdfdf;
    background-image: none;
    border-color: #d9d8d8; }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:active, .btn-outline-primary.active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.btn-outline-secondary {
  color: #dbdee0;
  background-color: transparent;
  background-image: none;
  border-color: #dbdee0; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #dbdee0;
    border-color: #dbdee0; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 3px rgba(219, 222, 224, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #dbdee0;
    background-color: transparent; }
  .btn-outline-secondary:active, .btn-outline-secondary.active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #dbdee0;
    border-color: #dbdee0; }

.btn-outline-success {
  color: #33cc33;
  background-color: transparent;
  background-image: none;
  border-color: #33cc33; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #33cc33;
    border-color: #33cc33; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 3px rgba(51, 204, 51, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #33cc33;
    background-color: transparent; }
  .btn-outline-success:active, .btn-outline-success.active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #33cc33;
    border-color: #33cc33; }

.btn-outline-danger {
  color: #cd0303;
  background-color: transparent;
  background-image: none;
  border-color: #cd0303; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #cd0303;
    border-color: #cd0303; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 3px rgba(205, 3, 3, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #cd0303;
    background-color: transparent; }
  .btn-outline-danger:active, .btn-outline-danger.active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #cd0303;
    border-color: #cd0303; }

.btn-outline-light {
  color: #f2f2f2;
  background-color: transparent;
  background-image: none;
  border-color: #f2f2f2; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 3px rgba(242, 242, 242, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f2f2f2;
    background-color: transparent; }
  .btn-outline-light:active, .btn-outline-light.active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #f2f2f2;
    border-color: #f2f2f2; }

.btn-link {
  font-weight: normal;
  color: inherit;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
    background-color: transparent; }
  .btn-link, .btn-link:focus, .btn-link:active {
    border-color: transparent;
    box-shadow: none; }
  .btn-link:hover {
    border-color: transparent; }
  .btn-link:focus, .btn-link:hover {
    color: #cd0303;
    text-decoration: none;
    background-color: transparent; }
  .btn-link:disabled {
    color: #666666; }
    .btn-link:disabled:focus, .btn-link:disabled:hover {
      text-decoration: none; }

.btn-lg, .btn-group-lg > .btn, .subscripe-box .btn-group-lg > input[type="submit"] {
  padding: 13px 4.0625rem;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .subscripe-box .btn-group-sm > input[type="submit"] {
  padding: 6px 8px;
  font-size: 12px;
  line-height: 1;
  border-radius: 4px; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 15px;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px; }

.dropdown-divider {
  height: 0;
  margin: 0 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f2f2f2; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #666666;
    background-color: transparent; }

.show > a {
  outline: 0; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 12px;
  color: #666666;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .subscripe-box .btn-group > input[type="submit"],
  .btn-group-vertical > .btn,
  .subscripe-box .btn-group-vertical > input[type="submit"] {
    position: relative;
    flex: 0 1 auto;
    margin-bottom: 0; }
    .btn-group > .btn:hover, .subscripe-box .btn-group > input:hover[type="submit"],
    .btn-group-vertical > .btn:hover,
    .subscripe-box .btn-group-vertical > input:hover[type="submit"] {
      z-index: 2; }
    .btn-group > .btn:focus, .subscripe-box .btn-group > input:focus[type="submit"], .btn-group > .btn:active, .subscripe-box .btn-group > input:active[type="submit"], .btn-group > .btn.active, .subscripe-box .btn-group > input.active[type="submit"],
    .btn-group-vertical > .btn:focus,
    .subscripe-box .btn-group-vertical > input:focus[type="submit"],
    .btn-group-vertical > .btn:active,
    .subscripe-box .btn-group-vertical > input:active[type="submit"],
    .btn-group-vertical > .btn.active,
    .subscripe-box .btn-group-vertical > input.active[type="submit"] {
      z-index: 2; }
  .btn-group .btn + .btn, .btn-group .subscripe-box input[type="submit"] + .btn, .subscripe-box .btn-group input[type="submit"] + .btn, .btn-group .subscripe-box .btn + input[type="submit"], .subscripe-box .btn-group .btn + input[type="submit"], .btn-group .subscripe-box input[type="submit"] + input[type="submit"], .subscripe-box .btn-group input[type="submit"] + input[type="submit"],
  .btn-group .btn + .btn-group,
  .btn-group .subscripe-box input[type="submit"] + .btn-group,
  .subscripe-box .btn-group input[type="submit"] + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .subscripe-box .btn-group + input[type="submit"],
  .subscripe-box .btn-group .btn-group + input[type="submit"],
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .subscripe-box input[type="submit"] + .btn,
  .subscripe-box .btn-group-vertical input[type="submit"] + .btn,
  .btn-group-vertical .subscripe-box .btn + input[type="submit"],
  .subscripe-box .btn-group-vertical .btn + input[type="submit"],
  .btn-group-vertical .subscripe-box input[type="submit"] + input[type="submit"],
  .subscripe-box .btn-group-vertical input[type="submit"] + input[type="submit"],
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .subscripe-box input[type="submit"] + .btn-group,
  .subscripe-box .btn-group-vertical input[type="submit"] + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .subscripe-box .btn-group + input[type="submit"],
  .subscripe-box .btn-group-vertical .btn-group + input[type="submit"],
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -2px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle), .subscripe-box .btn-group > input:not(:first-child):not(:last-child):not(.dropdown-toggle)[type="submit"] {
  border-radius: 0; }

.btn-group > .btn:first-child, .subscripe-box .btn-group > input:first-child[type="submit"] {
  margin-left: 0; }
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle), .subscripe-box .btn-group > input:first-child:not(:last-child):not(.dropdown-toggle)[type="submit"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child), .subscripe-box .btn-group > input:last-child:not(:first-child)[type="submit"],
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn, .subscripe-box .btn-group > .btn-group:not(:first-child):not(:last-child) > input[type="submit"] {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .subscripe-box .btn-group > .btn-group:first-child:not(:last-child) > input:last-child[type="submit"],
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child, .subscripe-box .btn-group > .btn-group:last-child:not(:first-child) > input:first-child[type="submit"] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn + .dropdown-toggle-split, .subscripe-box input[type="submit"] + .dropdown-toggle-split {
  padding-right: 15px;
  padding-left: 15px; }
  .btn + .dropdown-toggle-split::after, .subscripe-box input[type="submit"] + .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .subscripe-box .btn-group-sm > input[type="submit"] + .dropdown-toggle-split {
  padding-right: 6px;
  padding-left: 6px; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .subscripe-box .btn-group-lg > input[type="submit"] + .dropdown-toggle-split {
  padding-right: 3.046875rem;
  padding-left: 3.046875rem; }

.btn-group-vertical {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn, .btn-group-vertical .subscripe-box input[type="submit"], .subscripe-box .btn-group-vertical input[type="submit"],
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn, .subscripe-box .btn-group-vertical > input[type="submit"] + .btn, .subscripe-box .btn-group-vertical > .btn + input[type="submit"], .subscripe-box .btn-group-vertical > input[type="submit"] + input[type="submit"],
  .btn-group-vertical > .btn + .btn-group,
  .subscripe-box .btn-group-vertical > input[type="submit"] + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .subscripe-box .btn-group-vertical > .btn-group + input[type="submit"],
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -2px;
    margin-left: 0; }

.btn-group-vertical > .btn:not(:first-child):not(:last-child), .subscripe-box .btn-group-vertical > input:not(:first-child):not(:last-child)[type="submit"] {
  border-radius: 0; }

.btn-group-vertical > .btn:first-child:not(:last-child), .subscripe-box .btn-group-vertical > input:first-child:not(:last-child)[type="submit"] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:last-child:not(:first-child), .subscripe-box .btn-group-vertical > input:last-child:not(:first-child)[type="submit"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn, .subscripe-box .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > input[type="submit"] {
  border-radius: 0; }

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child, .subscripe-box .btn-group-vertical > .btn-group:first-child:not(:last-child) > input:last-child[type="submit"],
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child, .subscripe-box .btn-group-vertical > .btn-group:last-child:not(:first-child) > input:first-child[type="submit"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

[data-toggle="buttons"] > .btn input[type="radio"], .subscripe-box [data-toggle="buttons"] > input[type="submit"] input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
.subscripe-box [data-toggle="buttons"] > input[type="submit"] input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
.subscripe-box [data-toggle="buttons"] > .btn-group > input[type="submit"] input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"],
.subscripe-box [data-toggle="buttons"] > .btn-group > input[type="submit"] input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  width: 100%; }
  .input-group .form-control {
    position: relative;
    z-index: 2;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
      z-index: 3; }

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center; }
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0; }

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle; }

.input-group-addon {
  padding: 15px 20px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: normal;
  line-height: 1;
  color: #4c4c4c;
  text-align: center;
  background-color: #e9ecef;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px; }
  .input-group-addon.form-control-sm,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .input-group-addon.btn, .subscripe-box .input-group-sm > .input-group-btn > input.input-group-addon[type="submit"] {
    padding: 6px 8px;
    font-size: 12px;
    border-radius: 0.2rem; }
  .input-group-addon.form-control-lg,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .input-group-addon.btn, .subscripe-box .input-group-lg > .input-group-btn > input.input-group-addon[type="submit"] {
    padding: 13px 4.0625rem;
    font-size: 16px;
    border-radius: 0.3rem; }
  .input-group-addon input[type="radio"],
  .input-group-addon input[type="checkbox"] {
    margin-top: 0; }

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.subscripe-box .input-group-btn:not(:last-child) > input[type="submit"],
.input-group-btn:not(:last-child) > .btn-group > .btn,
.subscripe-box .input-group-btn:not(:last-child) > .btn-group > input[type="submit"],
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.subscripe-box .input-group-btn:not(:first-child) > input:not(:last-child):not(.dropdown-toggle)[type="submit"],
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn,
.subscripe-box .input-group-btn:not(:first-child) > .btn-group:not(:last-child) > input[type="submit"] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group-addon:not(:last-child) {
  border-right: 0; }

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.subscripe-box .input-group-btn:not(:first-child) > input[type="submit"],
.input-group-btn:not(:first-child) > .btn-group > .btn,
.subscripe-box .input-group-btn:not(:first-child) > .btn-group > input[type="submit"],
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.subscripe-box .input-group-btn:not(:last-child) > input:not(:first-child)[type="submit"],
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn,
.subscripe-box .input-group-btn:not(:last-child) > .btn-group:not(:first-child) > input[type="submit"] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0; }

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }
  .input-group-btn > .btn, .subscripe-box .input-group-btn > input[type="submit"] {
    position: relative; }
    .input-group-btn > .btn + .btn, .subscripe-box .input-group-btn > input[type="submit"] + .btn, .subscripe-box .input-group-btn > .btn + input[type="submit"], .subscripe-box .input-group-btn > input[type="submit"] + input[type="submit"] {
      margin-left: -2px; }
    .input-group-btn > .btn:focus, .subscripe-box .input-group-btn > input:focus[type="submit"], .input-group-btn > .btn:active, .subscripe-box .input-group-btn > input:active[type="submit"], .input-group-btn > .btn:hover, .subscripe-box .input-group-btn > input:hover[type="submit"] {
      z-index: 3; }
  
  .input-group-btn:not(:last-child) > .btn,
  .subscripe-box .input-group-btn:not(:last-child) > input[type="submit"],
  .input-group-btn:not(:last-child) > .btn-group {
    margin-right: -2px; }
  
  .input-group-btn:not(:first-child) > .btn,
  .subscripe-box .input-group-btn:not(:first-child) > input[type="submit"],
  .input-group-btn:not(:first-child) > .btn-group {
    z-index: 2;
    margin-left: -2px; }
    .input-group-btn:not(:first-child) > .btn:focus, .subscripe-box .input-group-btn:not(:first-child) > input:focus[type="submit"], .input-group-btn:not(:first-child) > .btn:active, .subscripe-box .input-group-btn:not(:first-child) > input:active[type="submit"], .input-group-btn:not(:first-child) > .btn:hover, .subscripe-box .input-group-btn:not(:first-child) > input:hover[type="submit"],
    .input-group-btn:not(:first-child) > .btn-group:focus,
    .input-group-btn:not(:first-child) > .btn-group:active,
    .input-group-btn:not(:first-child) > .btn-group:hover {
      z-index: 3; }

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.6rem;
  padding-left: 1.5rem;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-indicator {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #007bff; }
  .custom-control-input:active ~ .custom-control-indicator {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-indicator {
    background-color: #e9ecef; }
  .custom-control-input:disabled ~ .custom-control-description {
    color: #666666; }

.custom-control-indicator {
  position: absolute;
  top: 0.3rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-indicator {
  border-radius: 4px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #007bff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-indicator {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-controls-stacked {
  display: flex;
  flex-direction: column; }
  .custom-controls-stacked .custom-control {
    margin-bottom: 0.25rem; }
    .custom-controls-stacked .custom-control + .custom-control {
      margin-left: 0; }

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(15px + 4px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1;
  color: #4c4c4c;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: none; }
    .custom-select:focus::-ms-value {
      color: #4c4c4c;
      background-color: #fff; }
  .custom-select:disabled {
    color: #666666;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: 12px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 2.5rem;
  margin-bottom: 0; }

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
  opacity: 0; }

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #4c4c4c;
  pointer-events: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px; }
  .custom-file-control:lang(en):empty::after {
    content: "Choose file..."; }
  .custom-file-control::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #4c4c4c;
    background-color: #e9ecef;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0 4px 4px 0; }
  .custom-file-control:lang(en)::before {
    content: "Browse"; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: #666666; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #e9ecef #e9ecef #ddd; }
    .nav-tabs .nav-link.disabled {
      color: #666666;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #4c4c4c;
    background-color: #fff;
    border-color: #ddd #ddd #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 4px; }
  .nav-pills .nav-link.active,
  .show > .nav-pills .nav-link {
    color: #fff;
    background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: -5.3px;
  padding-bottom: -5.3px;
  margin-right: 0;
  font-size: 16px;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 16px;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 767px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-sm {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 991px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-md {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1229px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1230px) {
  .navbar-expand-lg {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }

.navbar-dark .navbar-brand {
  color: white; }
  .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: white; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(4px - 1px) calc(4px - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(4px - 1px) calc(4px - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(4px - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(4px - 1px);
  border-top-right-radius: calc(4px - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(4px - 1px);
  border-bottom-left-radius: calc(4px - 1px); }

@media (min-width: 768px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: 15px;
      margin-left: 15px; } }

@media (min-width: 768px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group .card {
      flex: 1 0 0%; }
      .card-group .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group .card:first-child .card-img-top {
          border-top-right-radius: 0; }
        .card-group .card:first-child .card-img-bottom {
          border-bottom-right-radius: 0; }
      .card-group .card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group .card:last-child .card-img-top {
          border-top-left-radius: 0; }
        .card-group .card:last-child .card-img-bottom {
          border-bottom-left-radius: 0; }
      .card-group .card:not(:first-child):not(:last-child) {
        border-radius: 0; }
        .card-group .card:not(:first-child):not(:last-child) .card-img-top,
        .card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
          border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 768px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 4px; }
  .breadcrumb::after {
    display: block;
    clear: both;
    content: ""; }

.breadcrumb-item {
  float: left; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #666666;
    content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #666666; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 4px; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #666666;
  pointer-events: none;
  background-color: #fff;
  border-color: #ddd; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: inherit;
  background-color: #fff;
  border: 1px solid #ddd; }
  .page-link:focus, .page-link:hover {
    color: #cd0303;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #ddd; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 16px;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  line-height: 1; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px; }
  .badge:empty {
    display: none; }

.btn .badge, .subscripe-box input[type="submit"] .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:focus, .badge-primary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #111;
  background-color: #dbdee0; }
  .badge-secondary[href]:focus, .badge-secondary[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #c0c5c8; }

.badge-success {
  color: #fff;
  background-color: #33cc33; }
  .badge-success[href]:focus, .badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #29a329; }

.badge-danger {
  color: #fff;
  background-color: #cd0303; }
  .badge-danger[href]:focus, .badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #9b0202; }

.badge-light {
  color: #111;
  background-color: #f2f2f2; }
  .badge-light[href]:focus, .badge-light[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #d9d8d8; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 768px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 4px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible .close {
  position: relative;
  top: -0.75rem;
  right: -1.25rem;
  padding: 0.75rem 1.25rem;
  color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #727374;
  background-color: #f8f8f9;
  border-color: #f5f6f6; }
  .alert-secondary hr {
    border-top-color: #e8eaea; }
  .alert-secondary .alert-link {
    color: #595a5a; }

.alert-success {
  color: #1b6a1b;
  background-color: #d6f5d6;
  border-color: #c6f1c6; }
  .alert-success hr {
    border-top-color: #b2ecb2; }
  .alert-success .alert-link {
    color: #114111; }

.alert-danger {
  color: #6b0202;
  background-color: #f5cdcd;
  border-color: #f1b8b8; }
  .alert-danger hr {
    border-top-color: #eda3a3; }
  .alert-danger .alert-link {
    color: #390101; }

.alert-light {
  color: #7e7e7e;
  background-color: #fcfcfc;
  border-color: #fbfbfb; }
  .alert-light hr {
    border-top-color: #eeeeee; }
  .alert-light .alert-link {
    color: #656464; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: #e9ecef;
  border-radius: 4px; }

.progress-bar {
  height: 1rem;
  line-height: 1rem;
  color: #fff;
  background-color: #007bff;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #4c4c4c;
  text-align: inherit; }
  .list-group-item-action:focus, .list-group-item-action:hover {
    color: #4c4c4c;
    text-decoration: none;
    background-color: #f2f2f2; }
  .list-group-item-action:active {
    color: #000;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }
  .list-group-item:focus, .list-group-item:hover {
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #666666;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

a.list-group-item-primary,
button.list-group-item-primary {
  color: #004085; }
  a.list-group-item-primary:focus, a.list-group-item-primary:hover,
  button.list-group-item-primary:focus,
  button.list-group-item-primary:hover {
    color: #004085;
    background-color: #9fcdff; }
  a.list-group-item-primary.active,
  button.list-group-item-primary.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #727374;
  background-color: #f5f6f6; }

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #727374; }
  a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
  button.list-group-item-secondary:focus,
  button.list-group-item-secondary:hover {
    color: #727374;
    background-color: #e8eaea; }
  a.list-group-item-secondary.active,
  button.list-group-item-secondary.active {
    color: #fff;
    background-color: #727374;
    border-color: #727374; }

.list-group-item-success {
  color: #1b6a1b;
  background-color: #c6f1c6; }

a.list-group-item-success,
button.list-group-item-success {
  color: #1b6a1b; }
  a.list-group-item-success:focus, a.list-group-item-success:hover,
  button.list-group-item-success:focus,
  button.list-group-item-success:hover {
    color: #1b6a1b;
    background-color: #b2ecb2; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    color: #fff;
    background-color: #1b6a1b;
    border-color: #1b6a1b; }

.list-group-item-danger {
  color: #6b0202;
  background-color: #f1b8b8; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #6b0202; }
  a.list-group-item-danger:focus, a.list-group-item-danger:hover,
  button.list-group-item-danger:focus,
  button.list-group-item-danger:hover {
    color: #6b0202;
    background-color: #eda3a3; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    color: #fff;
    background-color: #6b0202;
    border-color: #6b0202; }

.list-group-item-light {
  color: #7e7e7e;
  background-color: #fbfbfb; }

a.list-group-item-light,
button.list-group-item-light {
  color: #7e7e7e; }
  a.list-group-item-light:focus, a.list-group-item-light:hover,
  button.list-group-item-light:focus,
  button.list-group-item-light:hover {
    color: #7e7e7e;
    background-color: #eeeeee; }
  a.list-group-item-light.active,
  button.list-group-item-light.active {
    color: #fff;
    background-color: #7e7e7e;
    border-color: #7e7e7e; }

.close {
  float: right;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.9); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.9; }

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.6; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1230px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Muli", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 12px;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 5px;
    height: 5px; }
  .tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
    padding: 5px 0; }
    .tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
      margin-left: -3px;
      content: "";
      border-width: 5px 5px 0;
      border-top-color: #000; }
  .tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 5px; }
    .tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
      left: 0; }
    .tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
      margin-top: -3px;
      content: "";
      border-width: 5px 5px 5px 0;
      border-right-color: #000; }
  .tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 5px 0; }
    .tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      margin-left: -3px;
      content: "";
      border-width: 0 5px 5px;
      border-bottom-color: #000; }
  .tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 5px; }
    .tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
      right: 0; }
    .tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
      right: 0;
      margin-top: -3px;
      content: "";
      border-width: 5px 0 5px 5px;
      border-left-color: #000; }
  .tooltip .arrow::before {
    position: absolute;
    border-color: transparent;
    border-style: solid; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  padding: 1px;
  font-family: "Muli", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 12px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 5px; }
  .popover .arrow::before,
  .popover .arrow::after {
    position: absolute;
    display: block;
    border-color: transparent;
    border-style: solid; }
  .popover .arrow::before {
    content: "";
    border-width: 11px; }
  .popover .arrow::after {
    content: "";
    border-width: 11px; }
  .popover.bs-popover-top, .popover.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 10px; }
    .popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before,
    .popover.bs-popover-top .arrow::after,
    .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      border-bottom-width: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before {
      bottom: -11px;
      margin-left: -6px;
      border-top-color: rgba(0, 0, 0, 0.25); }
    
    .popover.bs-popover-top .arrow::after,
    .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      bottom: -10px;
      margin-left: -6px;
      border-top-color: #fff; }
  .popover.bs-popover-right, .popover.bs-popover-auto[x-placement^="right"] {
    margin-left: 10px; }
    .popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^="right"] .arrow {
      left: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before,
    .popover.bs-popover-right .arrow::after,
    .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      margin-top: -8px;
      border-left-width: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before {
      left: -11px;
      border-right-color: rgba(0, 0, 0, 0.25); }
    
    .popover.bs-popover-right .arrow::after,
    .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      left: -10px;
      border-right-color: #fff; }
  .popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 10px; }
    .popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
    .popover.bs-popover-bottom .arrow::after,
    .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      margin-left: -7px;
      border-top-width: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
      top: -11px;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    
    .popover.bs-popover-bottom .arrow::after,
    .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      top: -10px;
      border-bottom-color: #fff; }
    .popover.bs-popover-bottom .popover-header::before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 20px;
      margin-left: -10px;
      content: "";
      border-bottom: 1px solid #f7f7f7; }
  .popover.bs-popover-left, .popover.bs-popover-auto[x-placement^="left"] {
    margin-right: 10px; }
    .popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^="left"] .arrow {
      right: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before,
    .popover.bs-popover-left .arrow::after,
    .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      margin-top: -8px;
      border-right-width: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before {
      right: -11px;
      border-left-color: rgba(0, 0, 0, 0.25); }
    
    .popover.bs-popover-left .arrow::after,
    .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      right: -10px;
      border-left-color: #fff; }

.popover-header {
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 15px;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 9px 14px;
  color: #000; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:focus, .carousel-control-prev:hover,
  .carousel-control-next:focus,
  .carousel-control-next:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #dbdee0 !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #c0c5c8 !important; }

.bg-success {
  background-color: #33cc33 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #29a329 !important; }

.bg-danger {
  background-color: #cd0303 !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #9b0202 !important; }

.bg-light {
  background-color: #f2f2f2 !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #d9d8d8 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #dbdee0 !important; }

.border-success {
  border-color: #33cc33 !important; }

.border-danger {
  border-color: #cd0303 !important; }

.border-light {
  border-color: #f2f2f2 !important; }

.border-white {
  border-color: #fff !important; }

.rounded, .subscripe-box form, .subscribe form, .rules-border:before {
  border-radius: 4px !important; }

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; }

.rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

.rounded-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

.rounded-circle, .rank-num-icon, .craps-point:after, .red-num, .blackjack-strategies ol li:before, .volatility-btn.active .radio-btn:before, .providers__menu ol li:before {
  border-radius: 50%; }

.rounded-0 {
  border-radius: 0; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none, .btn__state[aria-expanded="true"] .btn__state--default,
.btn__state.active:not([aria-expanded]) .btn__state--default, .btn__state[aria-expanded="false"] .btn__state--active,
.btn__state:not(.active):not([aria-expanded]) .btn__state--active {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block, .btn-arrow-down {
  display: inline-block !important; }

.d-block, .thumb {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1230px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1230px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1230px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m-5 {
  margin: 5px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mr-5 {
  margin-right: 5px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.ml-5 {
  margin-left: 5px !important; }

.mx-5 {
  margin-right: 5px !important;
  margin-left: 5px !important; }

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.m-10 {
  margin: 10px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.ml-10 {
  margin-left: 10px !important; }

.mx-10 {
  margin-right: 10px !important;
  margin-left: 10px !important; }

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.m-15 {
  margin: 15px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.ml-15 {
  margin-left: 15px !important; }

.mx-15 {
  margin-right: 15px !important;
  margin-left: 15px !important; }

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.m-20 {
  margin: 20px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.ml-20 {
  margin-left: 20px !important; }

.mx-20 {
  margin-right: 20px !important;
  margin-left: 20px !important; }

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.m-25 {
  margin: 25px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mr-25 {
  margin-right: 25px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.ml-25 {
  margin-left: 25px !important; }

.mx-25 {
  margin-right: 25px !important;
  margin-left: 25px !important; }

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.m-30 {
  margin: 30px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.ml-30 {
  margin-left: 30px !important; }

.mx-30 {
  margin-right: 30px !important;
  margin-left: 30px !important; }

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.m-35 {
  margin: 35px !important; }

.mt-35 {
  margin-top: 35px !important; }

.mr-35 {
  margin-right: 35px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.ml-35 {
  margin-left: 35px !important; }

.mx-35 {
  margin-right: 35px !important;
  margin-left: 35px !important; }

.my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important; }

.m-40 {
  margin: 40px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.ml-40 {
  margin-left: 40px !important; }

.mx-40 {
  margin-right: 40px !important;
  margin-left: 40px !important; }

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.m-45 {
  margin: 45px !important; }

.mt-45 {
  margin-top: 45px !important; }

.mr-45 {
  margin-right: 45px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.ml-45 {
  margin-left: 45px !important; }

.mx-45 {
  margin-right: 45px !important;
  margin-left: 45px !important; }

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important; }

.m-50 {
  margin: 50px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mr-50 {
  margin-right: 50px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.ml-50 {
  margin-left: 50px !important; }

.mx-50 {
  margin-right: 50px !important;
  margin-left: 50px !important; }

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.m-55 {
  margin: 55px !important; }

.mt-55 {
  margin-top: 55px !important; }

.mr-55 {
  margin-right: 55px !important; }

.mb-55 {
  margin-bottom: 55px !important; }

.ml-55 {
  margin-left: 55px !important; }

.mx-55 {
  margin-right: 55px !important;
  margin-left: 55px !important; }

.my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important; }

.m-60 {
  margin: 60px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mr-60 {
  margin-right: 60px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.ml-60 {
  margin-left: 60px !important; }

.mx-60 {
  margin-right: 60px !important;
  margin-left: 60px !important; }

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.m-85 {
  margin: 85px !important; }

.mt-85 {
  margin-top: 85px !important; }

.mr-85 {
  margin-right: 85px !important; }

.mb-85 {
  margin-bottom: 85px !important; }

.ml-85 {
  margin-left: 85px !important; }

.mx-85 {
  margin-right: 85px !important;
  margin-left: 85px !important; }

.my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-5 {
  padding: 5px !important; }

.pt-5 {
  padding-top: 5px !important; }

.pr-5 {
  padding-right: 5px !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.pl-5 {
  padding-left: 5px !important; }

.px-5 {
  padding-right: 5px !important;
  padding-left: 5px !important; }

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.p-10 {
  padding: 10px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pl-10 {
  padding-left: 10px !important; }

.px-10 {
  padding-right: 10px !important;
  padding-left: 10px !important; }

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.p-15 {
  padding: 15px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pr-15 {
  padding-right: 15px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pl-15 {
  padding-left: 15px !important; }

.px-15 {
  padding-right: 15px !important;
  padding-left: 15px !important; }

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.p-20 {
  padding: 20px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pl-20 {
  padding-left: 20px !important; }

.px-20 {
  padding-right: 20px !important;
  padding-left: 20px !important; }

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.p-25 {
  padding: 25px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pr-25 {
  padding-right: 25px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.pl-25 {
  padding-left: 25px !important; }

.px-25 {
  padding-right: 25px !important;
  padding-left: 25px !important; }

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important; }

.p-30 {
  padding: 30px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pl-30 {
  padding-left: 30px !important; }

.px-30 {
  padding-right: 30px !important;
  padding-left: 30px !important; }

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.p-35 {
  padding: 35px !important; }

.pt-35 {
  padding-top: 35px !important; }

.pr-35 {
  padding-right: 35px !important; }

.pb-35 {
  padding-bottom: 35px !important; }

.pl-35 {
  padding-left: 35px !important; }

.px-35 {
  padding-right: 35px !important;
  padding-left: 35px !important; }

.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important; }

.p-40 {
  padding: 40px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pl-40, .subscripe-box input[type="text"],
.subscripe-box input[type="email"] {
  padding-left: 40px !important; }

.px-40 {
  padding-right: 40px !important;
  padding-left: 40px !important; }

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.p-45 {
  padding: 45px !important; }

.pt-45 {
  padding-top: 45px !important; }

.pr-45 {
  padding-right: 45px !important; }

.pb-45 {
  padding-bottom: 45px !important; }

.pl-45 {
  padding-left: 45px !important; }

.px-45 {
  padding-right: 45px !important;
  padding-left: 45px !important; }

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important; }

.p-50 {
  padding: 50px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pr-50 {
  padding-right: 50px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pl-50 {
  padding-left: 50px !important; }

.px-50 {
  padding-right: 50px !important;
  padding-left: 50px !important; }

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.p-55 {
  padding: 55px !important; }

.pt-55 {
  padding-top: 55px !important; }

.pr-55 {
  padding-right: 55px !important; }

.pb-55 {
  padding-bottom: 55px !important; }

.pl-55 {
  padding-left: 55px !important; }

.px-55 {
  padding-right: 55px !important;
  padding-left: 55px !important; }

.py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important; }

.p-60 {
  padding: 60px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pr-60 {
  padding-right: 60px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.pl-60 {
  padding-left: 60px !important; }

.px-60 {
  padding-right: 60px !important;
  padding-left: 60px !important; }

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important; }

.p-85 {
  padding: 85px !important; }

.pt-85 {
  padding-top: 85px !important; }

.pr-85 {
  padding-right: 85px !important; }

.pb-85 {
  padding-bottom: 85px !important; }

.pl-85 {
  padding-left: 85px !important; }

.px-85 {
  padding-right: 85px !important;
  padding-left: 85px !important; }

.py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-auto {
  margin-left: auto !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-sm-5 {
    margin: 5px !important; }
  .mt-sm-5 {
    margin-top: 5px !important; }
  .mr-sm-5 {
    margin-right: 5px !important; }
  .mb-sm-5 {
    margin-bottom: 5px !important; }
  .ml-sm-5 {
    margin-left: 5px !important; }
  .mx-sm-5 {
    margin-right: 5px !important;
    margin-left: 5px !important; }
  .my-sm-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .m-sm-10 {
    margin: 10px !important; }
  .mt-sm-10 {
    margin-top: 10px !important; }
  .mr-sm-10 {
    margin-right: 10px !important; }
  .mb-sm-10 {
    margin-bottom: 10px !important; }
  .ml-sm-10 {
    margin-left: 10px !important; }
  .mx-sm-10 {
    margin-right: 10px !important;
    margin-left: 10px !important; }
  .my-sm-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .m-sm-15 {
    margin: 15px !important; }
  .mt-sm-15 {
    margin-top: 15px !important; }
  .mr-sm-15 {
    margin-right: 15px !important; }
  .mb-sm-15 {
    margin-bottom: 15px !important; }
  .ml-sm-15 {
    margin-left: 15px !important; }
  .mx-sm-15 {
    margin-right: 15px !important;
    margin-left: 15px !important; }
  .my-sm-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .m-sm-20 {
    margin: 20px !important; }
  .mt-sm-20 {
    margin-top: 20px !important; }
  .mr-sm-20 {
    margin-right: 20px !important; }
  .mb-sm-20 {
    margin-bottom: 20px !important; }
  .ml-sm-20 {
    margin-left: 20px !important; }
  .mx-sm-20 {
    margin-right: 20px !important;
    margin-left: 20px !important; }
  .my-sm-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .m-sm-25 {
    margin: 25px !important; }
  .mt-sm-25 {
    margin-top: 25px !important; }
  .mr-sm-25 {
    margin-right: 25px !important; }
  .mb-sm-25 {
    margin-bottom: 25px !important; }
  .ml-sm-25 {
    margin-left: 25px !important; }
  .mx-sm-25 {
    margin-right: 25px !important;
    margin-left: 25px !important; }
  .my-sm-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .m-sm-30 {
    margin: 30px !important; }
  .mt-sm-30 {
    margin-top: 30px !important; }
  .mr-sm-30 {
    margin-right: 30px !important; }
  .mb-sm-30 {
    margin-bottom: 30px !important; }
  .ml-sm-30 {
    margin-left: 30px !important; }
  .mx-sm-30 {
    margin-right: 30px !important;
    margin-left: 30px !important; }
  .my-sm-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .m-sm-35 {
    margin: 35px !important; }
  .mt-sm-35 {
    margin-top: 35px !important; }
  .mr-sm-35 {
    margin-right: 35px !important; }
  .mb-sm-35 {
    margin-bottom: 35px !important; }
  .ml-sm-35 {
    margin-left: 35px !important; }
  .mx-sm-35 {
    margin-right: 35px !important;
    margin-left: 35px !important; }
  .my-sm-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .m-sm-40 {
    margin: 40px !important; }
  .mt-sm-40 {
    margin-top: 40px !important; }
  .mr-sm-40 {
    margin-right: 40px !important; }
  .mb-sm-40 {
    margin-bottom: 40px !important; }
  .ml-sm-40 {
    margin-left: 40px !important; }
  .mx-sm-40 {
    margin-right: 40px !important;
    margin-left: 40px !important; }
  .my-sm-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .m-sm-45 {
    margin: 45px !important; }
  .mt-sm-45 {
    margin-top: 45px !important; }
  .mr-sm-45 {
    margin-right: 45px !important; }
  .mb-sm-45 {
    margin-bottom: 45px !important; }
  .ml-sm-45 {
    margin-left: 45px !important; }
  .mx-sm-45 {
    margin-right: 45px !important;
    margin-left: 45px !important; }
  .my-sm-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important; }
  .m-sm-50 {
    margin: 50px !important; }
  .mt-sm-50 {
    margin-top: 50px !important; }
  .mr-sm-50 {
    margin-right: 50px !important; }
  .mb-sm-50 {
    margin-bottom: 50px !important; }
  .ml-sm-50 {
    margin-left: 50px !important; }
  .mx-sm-50 {
    margin-right: 50px !important;
    margin-left: 50px !important; }
  .my-sm-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .m-sm-55 {
    margin: 55px !important; }
  .mt-sm-55 {
    margin-top: 55px !important; }
  .mr-sm-55 {
    margin-right: 55px !important; }
  .mb-sm-55 {
    margin-bottom: 55px !important; }
  .ml-sm-55 {
    margin-left: 55px !important; }
  .mx-sm-55 {
    margin-right: 55px !important;
    margin-left: 55px !important; }
  .my-sm-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important; }
  .m-sm-60 {
    margin: 60px !important; }
  .mt-sm-60 {
    margin-top: 60px !important; }
  .mr-sm-60 {
    margin-right: 60px !important; }
  .mb-sm-60 {
    margin-bottom: 60px !important; }
  .ml-sm-60 {
    margin-left: 60px !important; }
  .mx-sm-60 {
    margin-right: 60px !important;
    margin-left: 60px !important; }
  .my-sm-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .m-sm-85 {
    margin: 85px !important; }
  .mt-sm-85 {
    margin-top: 85px !important; }
  .mr-sm-85 {
    margin-right: 85px !important; }
  .mb-sm-85 {
    margin-bottom: 85px !important; }
  .ml-sm-85 {
    margin-left: 85px !important; }
  .mx-sm-85 {
    margin-right: 85px !important;
    margin-left: 85px !important; }
  .my-sm-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-sm-5 {
    padding: 5px !important; }
  .pt-sm-5 {
    padding-top: 5px !important; }
  .pr-sm-5 {
    padding-right: 5px !important; }
  .pb-sm-5 {
    padding-bottom: 5px !important; }
  .pl-sm-5 {
    padding-left: 5px !important; }
  .px-sm-5 {
    padding-right: 5px !important;
    padding-left: 5px !important; }
  .py-sm-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .p-sm-10 {
    padding: 10px !important; }
  .pt-sm-10 {
    padding-top: 10px !important; }
  .pr-sm-10 {
    padding-right: 10px !important; }
  .pb-sm-10 {
    padding-bottom: 10px !important; }
  .pl-sm-10 {
    padding-left: 10px !important; }
  .px-sm-10 {
    padding-right: 10px !important;
    padding-left: 10px !important; }
  .py-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .p-sm-15 {
    padding: 15px !important; }
  .pt-sm-15 {
    padding-top: 15px !important; }
  .pr-sm-15 {
    padding-right: 15px !important; }
  .pb-sm-15 {
    padding-bottom: 15px !important; }
  .pl-sm-15 {
    padding-left: 15px !important; }
  .px-sm-15 {
    padding-right: 15px !important;
    padding-left: 15px !important; }
  .py-sm-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .p-sm-20 {
    padding: 20px !important; }
  .pt-sm-20 {
    padding-top: 20px !important; }
  .pr-sm-20 {
    padding-right: 20px !important; }
  .pb-sm-20 {
    padding-bottom: 20px !important; }
  .pl-sm-20 {
    padding-left: 20px !important; }
  .px-sm-20 {
    padding-right: 20px !important;
    padding-left: 20px !important; }
  .py-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .p-sm-25 {
    padding: 25px !important; }
  .pt-sm-25 {
    padding-top: 25px !important; }
  .pr-sm-25 {
    padding-right: 25px !important; }
  .pb-sm-25 {
    padding-bottom: 25px !important; }
  .pl-sm-25 {
    padding-left: 25px !important; }
  .px-sm-25 {
    padding-right: 25px !important;
    padding-left: 25px !important; }
  .py-sm-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .p-sm-30 {
    padding: 30px !important; }
  .pt-sm-30 {
    padding-top: 30px !important; }
  .pr-sm-30 {
    padding-right: 30px !important; }
  .pb-sm-30 {
    padding-bottom: 30px !important; }
  .pl-sm-30 {
    padding-left: 30px !important; }
  .px-sm-30 {
    padding-right: 30px !important;
    padding-left: 30px !important; }
  .py-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .p-sm-35 {
    padding: 35px !important; }
  .pt-sm-35 {
    padding-top: 35px !important; }
  .pr-sm-35 {
    padding-right: 35px !important; }
  .pb-sm-35 {
    padding-bottom: 35px !important; }
  .pl-sm-35 {
    padding-left: 35px !important; }
  .px-sm-35 {
    padding-right: 35px !important;
    padding-left: 35px !important; }
  .py-sm-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .p-sm-40 {
    padding: 40px !important; }
  .pt-sm-40 {
    padding-top: 40px !important; }
  .pr-sm-40 {
    padding-right: 40px !important; }
  .pb-sm-40 {
    padding-bottom: 40px !important; }
  .pl-sm-40 {
    padding-left: 40px !important; }
  .px-sm-40 {
    padding-right: 40px !important;
    padding-left: 40px !important; }
  .py-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .p-sm-45 {
    padding: 45px !important; }
  .pt-sm-45 {
    padding-top: 45px !important; }
  .pr-sm-45 {
    padding-right: 45px !important; }
  .pb-sm-45 {
    padding-bottom: 45px !important; }
  .pl-sm-45 {
    padding-left: 45px !important; }
  .px-sm-45 {
    padding-right: 45px !important;
    padding-left: 45px !important; }
  .py-sm-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important; }
  .p-sm-50 {
    padding: 50px !important; }
  .pt-sm-50 {
    padding-top: 50px !important; }
  .pr-sm-50 {
    padding-right: 50px !important; }
  .pb-sm-50 {
    padding-bottom: 50px !important; }
  .pl-sm-50 {
    padding-left: 50px !important; }
  .px-sm-50 {
    padding-right: 50px !important;
    padding-left: 50px !important; }
  .py-sm-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .p-sm-55 {
    padding: 55px !important; }
  .pt-sm-55 {
    padding-top: 55px !important; }
  .pr-sm-55 {
    padding-right: 55px !important; }
  .pb-sm-55 {
    padding-bottom: 55px !important; }
  .pl-sm-55 {
    padding-left: 55px !important; }
  .px-sm-55 {
    padding-right: 55px !important;
    padding-left: 55px !important; }
  .py-sm-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important; }
  .p-sm-60 {
    padding: 60px !important; }
  .pt-sm-60 {
    padding-top: 60px !important; }
  .pr-sm-60 {
    padding-right: 60px !important; }
  .pb-sm-60 {
    padding-bottom: 60px !important; }
  .pl-sm-60 {
    padding-left: 60px !important; }
  .px-sm-60 {
    padding-right: 60px !important;
    padding-left: 60px !important; }
  .py-sm-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .p-sm-85 {
    padding: 85px !important; }
  .pt-sm-85 {
    padding-top: 85px !important; }
  .pr-sm-85 {
    padding-right: 85px !important; }
  .pb-sm-85 {
    padding-bottom: 85px !important; }
  .pl-sm-85 {
    padding-left: 85px !important; }
  .px-sm-85 {
    padding-right: 85px !important;
    padding-left: 85px !important; }
  .py-sm-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-md-5 {
    margin: 5px !important; }
  .mt-md-5 {
    margin-top: 5px !important; }
  .mr-md-5 {
    margin-right: 5px !important; }
  .mb-md-5 {
    margin-bottom: 5px !important; }
  .ml-md-5 {
    margin-left: 5px !important; }
  .mx-md-5 {
    margin-right: 5px !important;
    margin-left: 5px !important; }
  .my-md-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .m-md-10 {
    margin: 10px !important; }
  .mt-md-10 {
    margin-top: 10px !important; }
  .mr-md-10 {
    margin-right: 10px !important; }
  .mb-md-10 {
    margin-bottom: 10px !important; }
  .ml-md-10 {
    margin-left: 10px !important; }
  .mx-md-10 {
    margin-right: 10px !important;
    margin-left: 10px !important; }
  .my-md-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .m-md-15 {
    margin: 15px !important; }
  .mt-md-15 {
    margin-top: 15px !important; }
  .mr-md-15 {
    margin-right: 15px !important; }
  .mb-md-15 {
    margin-bottom: 15px !important; }
  .ml-md-15 {
    margin-left: 15px !important; }
  .mx-md-15 {
    margin-right: 15px !important;
    margin-left: 15px !important; }
  .my-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .m-md-20 {
    margin: 20px !important; }
  .mt-md-20 {
    margin-top: 20px !important; }
  .mr-md-20 {
    margin-right: 20px !important; }
  .mb-md-20 {
    margin-bottom: 20px !important; }
  .ml-md-20 {
    margin-left: 20px !important; }
  .mx-md-20 {
    margin-right: 20px !important;
    margin-left: 20px !important; }
  .my-md-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .m-md-25 {
    margin: 25px !important; }
  .mt-md-25 {
    margin-top: 25px !important; }
  .mr-md-25 {
    margin-right: 25px !important; }
  .mb-md-25 {
    margin-bottom: 25px !important; }
  .ml-md-25 {
    margin-left: 25px !important; }
  .mx-md-25 {
    margin-right: 25px !important;
    margin-left: 25px !important; }
  .my-md-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .m-md-30 {
    margin: 30px !important; }
  .mt-md-30 {
    margin-top: 30px !important; }
  .mr-md-30 {
    margin-right: 30px !important; }
  .mb-md-30 {
    margin-bottom: 30px !important; }
  .ml-md-30 {
    margin-left: 30px !important; }
  .mx-md-30 {
    margin-right: 30px !important;
    margin-left: 30px !important; }
  .my-md-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .m-md-35 {
    margin: 35px !important; }
  .mt-md-35 {
    margin-top: 35px !important; }
  .mr-md-35 {
    margin-right: 35px !important; }
  .mb-md-35 {
    margin-bottom: 35px !important; }
  .ml-md-35 {
    margin-left: 35px !important; }
  .mx-md-35 {
    margin-right: 35px !important;
    margin-left: 35px !important; }
  .my-md-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .m-md-40 {
    margin: 40px !important; }
  .mt-md-40 {
    margin-top: 40px !important; }
  .mr-md-40 {
    margin-right: 40px !important; }
  .mb-md-40 {
    margin-bottom: 40px !important; }
  .ml-md-40 {
    margin-left: 40px !important; }
  .mx-md-40 {
    margin-right: 40px !important;
    margin-left: 40px !important; }
  .my-md-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .m-md-45 {
    margin: 45px !important; }
  .mt-md-45 {
    margin-top: 45px !important; }
  .mr-md-45 {
    margin-right: 45px !important; }
  .mb-md-45 {
    margin-bottom: 45px !important; }
  .ml-md-45 {
    margin-left: 45px !important; }
  .mx-md-45 {
    margin-right: 45px !important;
    margin-left: 45px !important; }
  .my-md-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important; }
  .m-md-50 {
    margin: 50px !important; }
  .mt-md-50 {
    margin-top: 50px !important; }
  .mr-md-50 {
    margin-right: 50px !important; }
  .mb-md-50 {
    margin-bottom: 50px !important; }
  .ml-md-50 {
    margin-left: 50px !important; }
  .mx-md-50 {
    margin-right: 50px !important;
    margin-left: 50px !important; }
  .my-md-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .m-md-55 {
    margin: 55px !important; }
  .mt-md-55 {
    margin-top: 55px !important; }
  .mr-md-55 {
    margin-right: 55px !important; }
  .mb-md-55 {
    margin-bottom: 55px !important; }
  .ml-md-55 {
    margin-left: 55px !important; }
  .mx-md-55 {
    margin-right: 55px !important;
    margin-left: 55px !important; }
  .my-md-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important; }
  .m-md-60 {
    margin: 60px !important; }
  .mt-md-60 {
    margin-top: 60px !important; }
  .mr-md-60 {
    margin-right: 60px !important; }
  .mb-md-60 {
    margin-bottom: 60px !important; }
  .ml-md-60 {
    margin-left: 60px !important; }
  .mx-md-60 {
    margin-right: 60px !important;
    margin-left: 60px !important; }
  .my-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .m-md-85 {
    margin: 85px !important; }
  .mt-md-85 {
    margin-top: 85px !important; }
  .mr-md-85 {
    margin-right: 85px !important; }
  .mb-md-85 {
    margin-bottom: 85px !important; }
  .ml-md-85 {
    margin-left: 85px !important; }
  .mx-md-85 {
    margin-right: 85px !important;
    margin-left: 85px !important; }
  .my-md-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-md-5 {
    padding: 5px !important; }
  .pt-md-5 {
    padding-top: 5px !important; }
  .pr-md-5 {
    padding-right: 5px !important; }
  .pb-md-5 {
    padding-bottom: 5px !important; }
  .pl-md-5 {
    padding-left: 5px !important; }
  .px-md-5 {
    padding-right: 5px !important;
    padding-left: 5px !important; }
  .py-md-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .p-md-10 {
    padding: 10px !important; }
  .pt-md-10 {
    padding-top: 10px !important; }
  .pr-md-10 {
    padding-right: 10px !important; }
  .pb-md-10 {
    padding-bottom: 10px !important; }
  .pl-md-10 {
    padding-left: 10px !important; }
  .px-md-10 {
    padding-right: 10px !important;
    padding-left: 10px !important; }
  .py-md-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .p-md-15 {
    padding: 15px !important; }
  .pt-md-15 {
    padding-top: 15px !important; }
  .pr-md-15 {
    padding-right: 15px !important; }
  .pb-md-15 {
    padding-bottom: 15px !important; }
  .pl-md-15 {
    padding-left: 15px !important; }
  .px-md-15 {
    padding-right: 15px !important;
    padding-left: 15px !important; }
  .py-md-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .p-md-20 {
    padding: 20px !important; }
  .pt-md-20 {
    padding-top: 20px !important; }
  .pr-md-20 {
    padding-right: 20px !important; }
  .pb-md-20 {
    padding-bottom: 20px !important; }
  .pl-md-20 {
    padding-left: 20px !important; }
  .px-md-20 {
    padding-right: 20px !important;
    padding-left: 20px !important; }
  .py-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .p-md-25 {
    padding: 25px !important; }
  .pt-md-25 {
    padding-top: 25px !important; }
  .pr-md-25 {
    padding-right: 25px !important; }
  .pb-md-25 {
    padding-bottom: 25px !important; }
  .pl-md-25 {
    padding-left: 25px !important; }
  .px-md-25 {
    padding-right: 25px !important;
    padding-left: 25px !important; }
  .py-md-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .p-md-30 {
    padding: 30px !important; }
  .pt-md-30 {
    padding-top: 30px !important; }
  .pr-md-30 {
    padding-right: 30px !important; }
  .pb-md-30 {
    padding-bottom: 30px !important; }
  .pl-md-30 {
    padding-left: 30px !important; }
  .px-md-30 {
    padding-right: 30px !important;
    padding-left: 30px !important; }
  .py-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .p-md-35 {
    padding: 35px !important; }
  .pt-md-35 {
    padding-top: 35px !important; }
  .pr-md-35 {
    padding-right: 35px !important; }
  .pb-md-35 {
    padding-bottom: 35px !important; }
  .pl-md-35 {
    padding-left: 35px !important; }
  .px-md-35 {
    padding-right: 35px !important;
    padding-left: 35px !important; }
  .py-md-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .p-md-40 {
    padding: 40px !important; }
  .pt-md-40 {
    padding-top: 40px !important; }
  .pr-md-40 {
    padding-right: 40px !important; }
  .pb-md-40 {
    padding-bottom: 40px !important; }
  .pl-md-40 {
    padding-left: 40px !important; }
  .px-md-40 {
    padding-right: 40px !important;
    padding-left: 40px !important; }
  .py-md-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .p-md-45 {
    padding: 45px !important; }
  .pt-md-45 {
    padding-top: 45px !important; }
  .pr-md-45 {
    padding-right: 45px !important; }
  .pb-md-45 {
    padding-bottom: 45px !important; }
  .pl-md-45 {
    padding-left: 45px !important; }
  .px-md-45 {
    padding-right: 45px !important;
    padding-left: 45px !important; }
  .py-md-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important; }
  .p-md-50 {
    padding: 50px !important; }
  .pt-md-50 {
    padding-top: 50px !important; }
  .pr-md-50 {
    padding-right: 50px !important; }
  .pb-md-50 {
    padding-bottom: 50px !important; }
  .pl-md-50 {
    padding-left: 50px !important; }
  .px-md-50 {
    padding-right: 50px !important;
    padding-left: 50px !important; }
  .py-md-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .p-md-55 {
    padding: 55px !important; }
  .pt-md-55 {
    padding-top: 55px !important; }
  .pr-md-55 {
    padding-right: 55px !important; }
  .pb-md-55 {
    padding-bottom: 55px !important; }
  .pl-md-55 {
    padding-left: 55px !important; }
  .px-md-55 {
    padding-right: 55px !important;
    padding-left: 55px !important; }
  .py-md-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important; }
  .p-md-60 {
    padding: 60px !important; }
  .pt-md-60 {
    padding-top: 60px !important; }
  .pr-md-60 {
    padding-right: 60px !important; }
  .pb-md-60 {
    padding-bottom: 60px !important; }
  .pl-md-60 {
    padding-left: 60px !important; }
  .px-md-60 {
    padding-right: 60px !important;
    padding-left: 60px !important; }
  .py-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .p-md-85 {
    padding: 85px !important; }
  .pt-md-85 {
    padding-top: 85px !important; }
  .pr-md-85 {
    padding-right: 85px !important; }
  .pb-md-85 {
    padding-bottom: 85px !important; }
  .pl-md-85 {
    padding-left: 85px !important; }
  .px-md-85 {
    padding-right: 85px !important;
    padding-left: 85px !important; }
  .py-md-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1230px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-lg-5 {
    margin: 5px !important; }
  .mt-lg-5 {
    margin-top: 5px !important; }
  .mr-lg-5 {
    margin-right: 5px !important; }
  .mb-lg-5 {
    margin-bottom: 5px !important; }
  .ml-lg-5 {
    margin-left: 5px !important; }
  .mx-lg-5 {
    margin-right: 5px !important;
    margin-left: 5px !important; }
  .my-lg-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .m-lg-10 {
    margin: 10px !important; }
  .mt-lg-10 {
    margin-top: 10px !important; }
  .mr-lg-10 {
    margin-right: 10px !important; }
  .mb-lg-10 {
    margin-bottom: 10px !important; }
  .ml-lg-10 {
    margin-left: 10px !important; }
  .mx-lg-10 {
    margin-right: 10px !important;
    margin-left: 10px !important; }
  .my-lg-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .m-lg-15 {
    margin: 15px !important; }
  .mt-lg-15 {
    margin-top: 15px !important; }
  .mr-lg-15 {
    margin-right: 15px !important; }
  .mb-lg-15 {
    margin-bottom: 15px !important; }
  .ml-lg-15 {
    margin-left: 15px !important; }
  .mx-lg-15 {
    margin-right: 15px !important;
    margin-left: 15px !important; }
  .my-lg-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .m-lg-20 {
    margin: 20px !important; }
  .mt-lg-20 {
    margin-top: 20px !important; }
  .mr-lg-20 {
    margin-right: 20px !important; }
  .mb-lg-20 {
    margin-bottom: 20px !important; }
  .ml-lg-20 {
    margin-left: 20px !important; }
  .mx-lg-20 {
    margin-right: 20px !important;
    margin-left: 20px !important; }
  .my-lg-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .m-lg-25 {
    margin: 25px !important; }
  .mt-lg-25 {
    margin-top: 25px !important; }
  .mr-lg-25 {
    margin-right: 25px !important; }
  .mb-lg-25 {
    margin-bottom: 25px !important; }
  .ml-lg-25 {
    margin-left: 25px !important; }
  .mx-lg-25 {
    margin-right: 25px !important;
    margin-left: 25px !important; }
  .my-lg-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .m-lg-30 {
    margin: 30px !important; }
  .mt-lg-30 {
    margin-top: 30px !important; }
  .mr-lg-30 {
    margin-right: 30px !important; }
  .mb-lg-30 {
    margin-bottom: 30px !important; }
  .ml-lg-30 {
    margin-left: 30px !important; }
  .mx-lg-30 {
    margin-right: 30px !important;
    margin-left: 30px !important; }
  .my-lg-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .m-lg-35 {
    margin: 35px !important; }
  .mt-lg-35 {
    margin-top: 35px !important; }
  .mr-lg-35 {
    margin-right: 35px !important; }
  .mb-lg-35 {
    margin-bottom: 35px !important; }
  .ml-lg-35 {
    margin-left: 35px !important; }
  .mx-lg-35 {
    margin-right: 35px !important;
    margin-left: 35px !important; }
  .my-lg-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .m-lg-40 {
    margin: 40px !important; }
  .mt-lg-40 {
    margin-top: 40px !important; }
  .mr-lg-40 {
    margin-right: 40px !important; }
  .mb-lg-40 {
    margin-bottom: 40px !important; }
  .ml-lg-40 {
    margin-left: 40px !important; }
  .mx-lg-40 {
    margin-right: 40px !important;
    margin-left: 40px !important; }
  .my-lg-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .m-lg-45 {
    margin: 45px !important; }
  .mt-lg-45 {
    margin-top: 45px !important; }
  .mr-lg-45 {
    margin-right: 45px !important; }
  .mb-lg-45 {
    margin-bottom: 45px !important; }
  .ml-lg-45 {
    margin-left: 45px !important; }
  .mx-lg-45 {
    margin-right: 45px !important;
    margin-left: 45px !important; }
  .my-lg-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important; }
  .m-lg-50 {
    margin: 50px !important; }
  .mt-lg-50 {
    margin-top: 50px !important; }
  .mr-lg-50 {
    margin-right: 50px !important; }
  .mb-lg-50 {
    margin-bottom: 50px !important; }
  .ml-lg-50 {
    margin-left: 50px !important; }
  .mx-lg-50 {
    margin-right: 50px !important;
    margin-left: 50px !important; }
  .my-lg-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .m-lg-55 {
    margin: 55px !important; }
  .mt-lg-55 {
    margin-top: 55px !important; }
  .mr-lg-55 {
    margin-right: 55px !important; }
  .mb-lg-55 {
    margin-bottom: 55px !important; }
  .ml-lg-55 {
    margin-left: 55px !important; }
  .mx-lg-55 {
    margin-right: 55px !important;
    margin-left: 55px !important; }
  .my-lg-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important; }
  .m-lg-60 {
    margin: 60px !important; }
  .mt-lg-60 {
    margin-top: 60px !important; }
  .mr-lg-60 {
    margin-right: 60px !important; }
  .mb-lg-60 {
    margin-bottom: 60px !important; }
  .ml-lg-60 {
    margin-left: 60px !important; }
  .mx-lg-60 {
    margin-right: 60px !important;
    margin-left: 60px !important; }
  .my-lg-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .m-lg-85 {
    margin: 85px !important; }
  .mt-lg-85 {
    margin-top: 85px !important; }
  .mr-lg-85 {
    margin-right: 85px !important; }
  .mb-lg-85 {
    margin-bottom: 85px !important; }
  .ml-lg-85 {
    margin-left: 85px !important; }
  .mx-lg-85 {
    margin-right: 85px !important;
    margin-left: 85px !important; }
  .my-lg-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-lg-5 {
    padding: 5px !important; }
  .pt-lg-5 {
    padding-top: 5px !important; }
  .pr-lg-5 {
    padding-right: 5px !important; }
  .pb-lg-5 {
    padding-bottom: 5px !important; }
  .pl-lg-5 {
    padding-left: 5px !important; }
  .px-lg-5 {
    padding-right: 5px !important;
    padding-left: 5px !important; }
  .py-lg-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .p-lg-10 {
    padding: 10px !important; }
  .pt-lg-10 {
    padding-top: 10px !important; }
  .pr-lg-10 {
    padding-right: 10px !important; }
  .pb-lg-10 {
    padding-bottom: 10px !important; }
  .pl-lg-10 {
    padding-left: 10px !important; }
  .px-lg-10 {
    padding-right: 10px !important;
    padding-left: 10px !important; }
  .py-lg-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .p-lg-15 {
    padding: 15px !important; }
  .pt-lg-15 {
    padding-top: 15px !important; }
  .pr-lg-15 {
    padding-right: 15px !important; }
  .pb-lg-15 {
    padding-bottom: 15px !important; }
  .pl-lg-15 {
    padding-left: 15px !important; }
  .px-lg-15 {
    padding-right: 15px !important;
    padding-left: 15px !important; }
  .py-lg-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .p-lg-20 {
    padding: 20px !important; }
  .pt-lg-20 {
    padding-top: 20px !important; }
  .pr-lg-20 {
    padding-right: 20px !important; }
  .pb-lg-20 {
    padding-bottom: 20px !important; }
  .pl-lg-20 {
    padding-left: 20px !important; }
  .px-lg-20 {
    padding-right: 20px !important;
    padding-left: 20px !important; }
  .py-lg-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .p-lg-25 {
    padding: 25px !important; }
  .pt-lg-25 {
    padding-top: 25px !important; }
  .pr-lg-25 {
    padding-right: 25px !important; }
  .pb-lg-25 {
    padding-bottom: 25px !important; }
  .pl-lg-25 {
    padding-left: 25px !important; }
  .px-lg-25 {
    padding-right: 25px !important;
    padding-left: 25px !important; }
  .py-lg-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .p-lg-30 {
    padding: 30px !important; }
  .pt-lg-30 {
    padding-top: 30px !important; }
  .pr-lg-30 {
    padding-right: 30px !important; }
  .pb-lg-30 {
    padding-bottom: 30px !important; }
  .pl-lg-30 {
    padding-left: 30px !important; }
  .px-lg-30 {
    padding-right: 30px !important;
    padding-left: 30px !important; }
  .py-lg-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .p-lg-35 {
    padding: 35px !important; }
  .pt-lg-35 {
    padding-top: 35px !important; }
  .pr-lg-35 {
    padding-right: 35px !important; }
  .pb-lg-35 {
    padding-bottom: 35px !important; }
  .pl-lg-35 {
    padding-left: 35px !important; }
  .px-lg-35 {
    padding-right: 35px !important;
    padding-left: 35px !important; }
  .py-lg-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .p-lg-40 {
    padding: 40px !important; }
  .pt-lg-40 {
    padding-top: 40px !important; }
  .pr-lg-40 {
    padding-right: 40px !important; }
  .pb-lg-40 {
    padding-bottom: 40px !important; }
  .pl-lg-40 {
    padding-left: 40px !important; }
  .px-lg-40 {
    padding-right: 40px !important;
    padding-left: 40px !important; }
  .py-lg-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .p-lg-45 {
    padding: 45px !important; }
  .pt-lg-45 {
    padding-top: 45px !important; }
  .pr-lg-45 {
    padding-right: 45px !important; }
  .pb-lg-45 {
    padding-bottom: 45px !important; }
  .pl-lg-45 {
    padding-left: 45px !important; }
  .px-lg-45 {
    padding-right: 45px !important;
    padding-left: 45px !important; }
  .py-lg-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important; }
  .p-lg-50 {
    padding: 50px !important; }
  .pt-lg-50 {
    padding-top: 50px !important; }
  .pr-lg-50 {
    padding-right: 50px !important; }
  .pb-lg-50 {
    padding-bottom: 50px !important; }
  .pl-lg-50 {
    padding-left: 50px !important; }
  .px-lg-50 {
    padding-right: 50px !important;
    padding-left: 50px !important; }
  .py-lg-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .p-lg-55 {
    padding: 55px !important; }
  .pt-lg-55 {
    padding-top: 55px !important; }
  .pr-lg-55 {
    padding-right: 55px !important; }
  .pb-lg-55 {
    padding-bottom: 55px !important; }
  .pl-lg-55 {
    padding-left: 55px !important; }
  .px-lg-55 {
    padding-right: 55px !important;
    padding-left: 55px !important; }
  .py-lg-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important; }
  .p-lg-60 {
    padding: 60px !important; }
  .pt-lg-60 {
    padding-top: 60px !important; }
  .pr-lg-60 {
    padding-right: 60px !important; }
  .pb-lg-60 {
    padding-bottom: 60px !important; }
  .pl-lg-60 {
    padding-left: 60px !important; }
  .px-lg-60 {
    padding-right: 60px !important;
    padding-left: 60px !important; }
  .py-lg-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .p-lg-85 {
    padding: 85px !important; }
  .pt-lg-85 {
    padding-top: 85px !important; }
  .pr-lg-85 {
    padding-right: 85px !important; }
  .pb-lg-85 {
    padding-bottom: 85px !important; }
  .pl-lg-85 {
    padding-left: 85px !important; }
  .px-lg-85 {
    padding-right: 85px !important;
    padding-left: 85px !important; }
  .py-lg-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center, .providers__menu ol li:before {
  text-align: center !important; }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1230px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase, .btn, .subscripe-box input[type="submit"] {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-white, .how-it-works-right ol li, .providers__menu ol li:before {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #dbdee0 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #c0c5c8 !important; }

.text-success {
  color: #33cc33 !important; }

a.text-success:focus, a.text-success:hover {
  color: #29a329 !important; }

.text-danger {
  color: #cd0303 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #9b0202 !important; }

.text-light {
  color: #f2f2f2 !important; }

a.text-light:focus, a.text-light:hover {
  color: #d9d8d8 !important; }

.text-muted {
  color: #666666 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

*,
*:before,
*:after {
  box-sizing: border-box; }

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  display: block; }

h1, h2, h3, h4, h5, h6, p, ol, ul,
blockquote, figure, label {
  margin-bottom: 0; }

input, select, textarea, button {
  padding: 0;
  border: none;
  background: none;
  appearance: none; }
  input:focus, select:focus, textarea:focus, button:focus {
    outline: none; }

img {
  display: inline-block;
  max-width: 100%;
  height: auto; }

ul,
ol {
  padding-left: 0;
  list-style: none; }

b,
strong {
  font-weight: bold; }

a,
button,
input[type="submit"],
input[type="reset"] {
  cursor: pointer;
  transition: 250ms ease-in-out; }
  a:focus,
  button:focus,
  input[type="submit"]:focus,
  input[type="reset"]:focus {
    outline: none; }

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #cd0303;
    text-decoration: none; }

button {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  button:hover {
    color: #cd0303; }

p:last-child {
  margin-bottom: 0; }

p a {
  color: #cd0303; }
  p a:hover {
    text-decoration: underline; }

.mw-none {
  max-width: none !important; }

.muli-l, .subscripe-box input[type="text"],
.subscripe-box input[type="email"], .subscribe input[type=text], .bet-table-right li, .question p {
  font-family: "Muli", sans-serif !important;
  font-weight: 300 !important;
  font-style: normal !important; }

.muli-l-it {
  font-family: "Muli", sans-serif !important;
  font-weight: 300 !important;
  font-style: italic !important; }

.muli, .game-review .entry-content h4, .craps-rules .entry-content h2 span, .online-slots-intro h1 span {
  font-family: "Muli", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important; }

.muli-it, .entry-content blockquote p {
  font-family: "Muli", sans-serif !important;
  font-weight: 400 !important;
  font-style: italic !important; }

.muli-sb, .listing-menu a {
  font-family: "Muli", sans-serif !important;
  font-weight: 600 !important;
  font-style: normal !important; }

.muli-b, .menu-item-title, .slots-table .row .col:nth-child(even), .slots-table .subscripe-box form .col:nth-child(even), .subscripe-box .slots-table form .col:nth-child(even), .slots-table .subscribe form .col:nth-child(even), .subscribe .slots-table form .col:nth-child(even), .payouts-box li, .contact-form-right .entry-content address a, .listing-content .entry-content h2, .bonuses-live-games .casino-highlights li, .li-item-line > li, .blackjack-strategies ol li, .range-value, .providers__menu ol li:before {
  font-family: "Muli", sans-serif !important;
  font-weight: 700 !important;
  font-style: normal !important; }

.muli-b-it {
  font-family: "Muli", sans-serif !important;
  font-weight: 700 !important;
  font-style: italic !important; }

.muli-ex-b, .rank-num-icon, .video-poker-rules table thead td {
  font-family: "Muli", sans-serif !important;
  font-weight: 800 !important;
  font-style: normal !important; }

.muli-bl, .subscribe input[type=submit], .pros li:before,
.coins li:before, .slot-nav ul a, .listing-content .entry-content h3, .craps-rules .entry-content h2, .bet-table-right li span {
  font-family: "Muli", sans-serif !important;
  font-weight: 900 !important;
  font-style: normal !important; }

.muli-bl-it {
  font-family: "Muli", sans-serif !important;
  font-weight: 900 !important;
  font-style: italic !important; }

.poppins-m {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important; }

@media (max-width: 991px) {
  h1, .h1 {
    font-size: 30px; }
  h2, .h2 {
    font-size: 25px; }
  h3, .h3 {
    font-size: 22px; }
  h4, .h4 {
    font-size: 18px; }
  h5, .h5 {
    font-size: 14px; } }

@media (max-width: 767px) {
  h1, .h1 {
    font-size: 25px; }
  h2, .h2 {
    font-size: 22px; }
  h3, .h3 {
    font-size: 20px; }
  h4, .h4 {
    font-size: 16px; }
  h5, .h5 {
    font-size: 12px; } }

.text-nowrap {
  white-space: normal !important; }

.text-underline {
  text-decoration: underline !important; }

.font-weight-black {
  font-weight: 900; }

.text-gray-500 {
  color: #77787a !important; }

a.text-gray-500:focus, a.text-gray-500:hover,
button.text-gray-500:focus,
button.text-gray-500:hover {
  color: #cd0303 !important; }

.text-gray-600, .slots-table .row .col:nth-child(even), .slots-table .subscripe-box form .col:nth-child(even), .subscripe-box .slots-table form .col:nth-child(even), .slots-table .subscribe form .col:nth-child(even), .subscribe .slots-table form .col:nth-child(even), .li-item-line > li {
  color: #666666 !important; }

a.text-gray-600:focus, .slots-table .row a.col:focus:nth-child(even), .slots-table .subscripe-box form a.col:focus:nth-child(even), .subscripe-box .slots-table form a.col:focus:nth-child(even), .slots-table .subscribe form a.col:focus:nth-child(even), .subscribe .slots-table form a.col:focus:nth-child(even), a.text-gray-600:hover, .slots-table .row a.col:hover:nth-child(even), .slots-table .subscripe-box form a.col:hover:nth-child(even), .subscripe-box .slots-table form a.col:hover:nth-child(even), .slots-table .subscribe form a.col:hover:nth-child(even), .subscribe .slots-table form a.col:hover:nth-child(even),
button.text-gray-600:focus,
.slots-table .row button.col:focus:nth-child(even),
.slots-table .subscripe-box form button.col:focus:nth-child(even),
.subscripe-box .slots-table form button.col:focus:nth-child(even),
.slots-table .subscribe form button.col:focus:nth-child(even),
.subscribe .slots-table form button.col:focus:nth-child(even),
button.text-gray-600:hover,
.slots-table .row button.col:hover:nth-child(even),
.slots-table .subscripe-box form button.col:hover:nth-child(even),
.subscripe-box .slots-table form button.col:hover:nth-child(even),
.slots-table .subscribe form button.col:hover:nth-child(even),
.subscribe .slots-table form button.col:hover:nth-child(even) {
  color: #cd0303 !important; }

.text-gray-700 {
  color: #4c4c4c !important; }

a.text-gray-700:focus, a.text-gray-700:hover,
button.text-gray-700:focus,
button.text-gray-700:hover {
  color: #cd0303 !important; }

.text-gray-800 {
  color: #333 !important; }

a.text-gray-800:focus, a.text-gray-800:hover,
button.text-gray-800:focus,
button.text-gray-800:hover {
  color: #cd0303 !important; }

.bg-e0e3e7 {
  background-color: #e0e3e7 !important; }

.bg-e6e6e6 {
  background-color: #e6e6e6 !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.divider-section {
  border-top: 2px solid rgba(0, 0, 0, 0.05); }

.big-f-size {
  font-size: 40px;
  line-height: 1; }

.h-100 {
  height: 100% !important; }

.w-100 {
  width: 100% !important; }

.rules-title {
  font-size: 18px; }

.f-size-0 {
  font-size: 0; }

.small-text {
  font-size: 11px; }

@media (max-width: 991px) {
  .reduce-figure {
    max-width: 50%;
    margin: 0 auto; } }

@media (max-width: 1229px) {
  .big-f-size {
    font-size: 30px; } }

.subscripe-box {
  border: 1px dashed #cd0303;
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 8px rgba(255, 255, 255, 0.75); }
  .subscripe-box form {
    margin: 0;
    background-color: #fff; }
  .subscripe-box input[type="text"],
  .subscripe-box input[type="email"] {
    color: #000;
    font-size: 12px;
    height: 40px;
    box-shadow: 0 5px 15px rgba(1, 1, 1, 0.1);
    background: url("../images/icons/at.svg") 15px center no-repeat; }
    .subscripe-box input[type="text"]::-placeholder,
    .subscripe-box input[type="email"]::-placeholder {
      color: #666666; }

@media (min-width: 1230px) {
  .subscripe-box {
    width: 334px; } }

@media (max-width: 991px) {
  .subscripe-box {
    position: static !important;
    max-width: 334px;
    margin: 0 auto; }
    .subscripe-box.is_stuck + div {
      display: none !important; } }

.quick-links {
  border: 1px solid #cccccc;
  box-shadow: 0 15px 30px rgba(1, 1, 1, 0.1); }
  .quick-links h5 {
    background-color: #f6f7f7; }
  .quick-links li {
    border-bottom: 1px solid #e5e5e5;
    font-size: 18px;
    color: #4b5966; }
    .quick-links li:last-of-type {
      border-bottom: 1px solid transparent; }
  .quick-links .icon-14,
  .quick-links .icon-11,
  .quick-links .icon-15 {
    font-size: 31px; }
  .quick-links .icon-12 {
    font-size: 27px; }
  .quick-links .icon-13 {
    font-size: 33px; }

@media (max-width: 1229px) {
  .quick-links li {
    font-size: 15px; } }

@media (max-width: 767px) {
  .quick-links {
    max-width: 270px;
    margin: 0 auto; } }

.btn-link {
  font-weight: 700;
  font-size: 13px;
  text-decoration: underline; }
  .btn-link:focus, .btn-link:hover {
    text-decoration: underline; }

.btn-border {
  color: #666;
  border-color: #f2f2f2; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary .btn-arrow-down {
    border-top-color: #fff; }
  .btn-primary:hover {
    color: #fff; }
    .btn-primary:hover .btn-arrow-down {
      border-top-color: #fff; }
  .btn-primary:hover {
    background-color: #0062cc;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: none; }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #0062cc;
    background-image: none;
    border-color: #0062cc; }

.btn-secondary {
  color: #848484;
  background-color: #dbdee0;
  border-color: #dbdee0; }
  .btn-secondary .btn-arrow-down {
    border-top-color: #848484; }
  .btn-secondary:hover {
    color: #5e5e5e; }
    .btn-secondary:hover .btn-arrow-down {
      border-top-color: #5e5e5e; }
  .btn-secondary:hover {
    background-color: #c0c5c8;
    border-color: #c0c5c8; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: none; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #dbdee0;
    border-color: #dbdee0; }
  .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #c0c5c8;
    background-image: none;
    border-color: #c0c5c8; }

.btn-success {
  color: #fff;
  background-color: #33cc33;
  border-color: #33cc33; }
  .btn-success .btn-arrow-down {
    border-top-color: #fff; }
  .btn-success:hover {
    color: #fff; }
    .btn-success:hover .btn-arrow-down {
      border-top-color: #fff; }
  .btn-success:hover {
    background-color: #29a329;
    border-color: #29a329; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: none; }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #33cc33;
    border-color: #33cc33; }
  .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    background-color: #29a329;
    background-image: none;
    border-color: #29a329; }

.btn-danger, .subscripe-box input[type="submit"] {
  color: #fff;
  background-color: #cd0303;
  border-color: #cd0303; }
  .btn-danger .btn-arrow-down, .subscripe-box input[type="submit"] .btn-arrow-down {
    border-top-color: #fff; }
  .btn-danger:hover, .subscripe-box input:hover[type="submit"] {
    color: #fff; }
    .btn-danger:hover .btn-arrow-down, .subscripe-box input:hover[type="submit"] .btn-arrow-down {
      border-top-color: #fff; }
  .btn-danger:hover, .subscripe-box input:hover[type="submit"] {
    background-color: #9b0202;
    border-color: #9b0202; }
  .btn-danger:focus, .subscripe-box input:focus[type="submit"], .btn-danger.focus, .subscripe-box input.focus[type="submit"] {
    box-shadow: none; }
  .btn-danger.disabled, .subscripe-box input.disabled[type="submit"], .btn-danger:disabled, .subscripe-box input:disabled[type="submit"] {
    background-color: #cd0303;
    border-color: #cd0303; }
  .btn-danger:active, .subscripe-box input:active[type="submit"], .btn-danger.active, .subscripe-box input.active[type="submit"],
  .show > .btn-danger.dropdown-toggle,
  .subscripe-box .show > input.dropdown-toggle[type="submit"] {
    background-color: #9b0202;
    background-image: none;
    border-color: #9b0202; }

.btn-light {
  color: #848484;
  background-color: #f2f2f2;
  border-color: #f2f2f2; }
  .btn-light .btn-arrow-down {
    border-top-color: #848484; }
  .btn-light:hover {
    color: #5e5e5e; }
    .btn-light:hover .btn-arrow-down {
      border-top-color: #5e5e5e; }
  .btn-light:hover {
    background-color: #d9d8d8;
    border-color: #d9d8d8; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: none; }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    background-color: #d9d8d8;
    background-image: none;
    border-color: #d9d8d8; }

.btn-outline-primary {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary .btn-arrow-down {
    border-top-color: #fff; }
  .btn-outline-primary:hover {
    color: #fff; }
    .btn-outline-primary:hover .btn-arrow-down {
      border-top-color: #fff; }
  .btn-outline-primary:hover {
    color: #000;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: none; }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:active, .btn-outline-primary.active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #000;
    background-color: #007bff;
    border-color: #007bff; }

.btn-outline-secondary {
  color: #848484;
  background-color: transparent;
  background-image: none;
  border-color: #dbdee0; }
  .btn-outline-secondary .btn-arrow-down {
    border-top-color: #848484; }
  .btn-outline-secondary:hover {
    color: #5e5e5e; }
    .btn-outline-secondary:hover .btn-arrow-down {
      border-top-color: #5e5e5e; }
  .btn-outline-secondary:hover {
    color: #000;
    background-color: #dbdee0;
    border-color: #dbdee0; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: none; }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #dbdee0;
    background-color: transparent; }
  .btn-outline-secondary:active, .btn-outline-secondary.active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #000;
    background-color: #dbdee0;
    border-color: #dbdee0; }

.btn-outline-success {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #33cc33; }
  .btn-outline-success .btn-arrow-down {
    border-top-color: #fff; }
  .btn-outline-success:hover {
    color: #fff; }
    .btn-outline-success:hover .btn-arrow-down {
      border-top-color: #fff; }
  .btn-outline-success:hover {
    color: #000;
    background-color: #33cc33;
    border-color: #33cc33; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: none; }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #33cc33;
    background-color: transparent; }
  .btn-outline-success:active, .btn-outline-success.active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #000;
    background-color: #33cc33;
    border-color: #33cc33; }

.btn-outline-danger {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #cd0303; }
  .btn-outline-danger .btn-arrow-down {
    border-top-color: #fff; }
  .btn-outline-danger:hover {
    color: #fff; }
    .btn-outline-danger:hover .btn-arrow-down {
      border-top-color: #fff; }
  .btn-outline-danger:hover {
    color: #000;
    background-color: #cd0303;
    border-color: #cd0303; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: none; }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #cd0303;
    background-color: transparent; }
  .btn-outline-danger:active, .btn-outline-danger.active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #000;
    background-color: #cd0303;
    border-color: #cd0303; }

.btn-outline-light {
  color: #848484;
  background-color: transparent;
  background-image: none;
  border-color: #f2f2f2; }
  .btn-outline-light .btn-arrow-down {
    border-top-color: #848484; }
  .btn-outline-light:hover {
    color: #5e5e5e; }
    .btn-outline-light:hover .btn-arrow-down {
      border-top-color: #5e5e5e; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: none; }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f2f2f2;
    background-color: transparent; }
  .btn-outline-light:active, .btn-outline-light.active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #000;
    background-color: #f2f2f2;
    border-color: #f2f2f2; }

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000; }
  .btn-black .btn-arrow-down {
    border-top-color: #fff; }
  .btn-black:hover {
    color: #fff; }
    .btn-black:hover .btn-arrow-down {
      border-top-color: #fff; }
  .btn-black:hover {
    background-color: #cd0303;
    border-color: #cd0303; }
  .btn-black:focus, .btn-black.focus {
    box-shadow: none; }
  .btn-black.disabled, .btn-black:disabled {
    background-color: #000;
    border-color: #000; }
  .btn-black:active, .btn-black.active,
  .show > .btn-black.dropdown-toggle {
    background-color: #cd0303;
    background-image: none;
    border-color: #cd0303; }

.btn-lg, .btn-group-lg > .btn, .subscripe-box .btn-group-lg > input[type="submit"] {
  padding: 13px 4.0625rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 4px; }

.btn-md, .subscripe-box input[type="submit"], .btn-md-ellipse {
  padding: 12px 20px;
  font-size: 12px;
  line-height: 1;
  border-radius: 4px; }

.btn-sm, .btn-group-sm > .btn, .subscripe-box .btn-group-sm > input[type="submit"] {
  padding: 6px 8px;
  font-size: 10px;
  line-height: 1;
  border-radius: 4px; }

.btn-md-ellipse {
  border-radius: 20px; }

.btn-arrow-down {
  width: 0;
  height: 0;
  margin-bottom: 2px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid transparent; }

.btn-slide-circle {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
  width: 66px;
  height: 66px;
  background-color: #fff;
  border: 2px solid rgba(1, 1, 1, 0.1); }
  .btn-slide-circle:before, .btn-slide-circle:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 22px;
    height: 5px;
    background-color: #4c4c4c;
    transition: 250ms ease-in-out; }
  .btn-slide-circle.btn-slide-prev {
    left: 0; }
    .btn-slide-circle.btn-slide-prev:before {
      transform: translate(-2px, 6px) rotate(45deg); }
    .btn-slide-circle.btn-slide-prev:after {
      transform: translate(-2px, -6px) rotate(-45deg); }
  .btn-slide-circle.btn-slide-next {
    right: 0; }
    .btn-slide-circle.btn-slide-next:before {
      transform: translate(2px, -6px) rotate(45deg); }
    .btn-slide-circle.btn-slide-next:after {
      transform: translate(2px, 6px) rotate(-45deg); }
  .btn-slide-circle:hover {
    background-color: #cd0303;
    border-color: #cd0303; }
    .btn-slide-circle:hover:before, .btn-slide-circle:hover:after {
      background-color: #fff; }

@media (max-width: 767px) {
  .btn-slide-circle {
    width: 45px;
    height: 45px; }
    .btn-slide-circle:before, .btn-slide-circle:after {
      width: 14px;
      height: 3px; }
    .btn-slide-circle.btn-slide-prev {
      left: 0; }
      .btn-slide-circle.btn-slide-prev:before {
        transform: translate(-1px, 4px) rotate(45deg); }
      .btn-slide-circle.btn-slide-prev:after {
        transform: translate(-1px, -4px) rotate(-45deg); }
    .btn-slide-circle.btn-slide-next {
      right: 0; }
      .btn-slide-circle.btn-slide-next:before {
        transform: translate(1px, -4px) rotate(45deg); }
      .btn-slide-circle.btn-slide-next:after {
        transform: translate(1px, 4px) rotate(-45deg); } }

.thumb {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-align: center;
  transition: 250ms ease-in-out; }
  .thumb img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -9999px;
    right: -9999px;
    margin: auto;
    backface-visibility: hidden;
    max-width: none;
    transition: 250ms ease-in-out; }
  .thumb a:hover img {
    transform: scale(1.03); }

.thumb-auto img {
  position: static;
  max-width: 100%;
  height: auto;
  height: auto; }

.thumb-opacity {
  background-color: #000; }
  .thumb-opacity img {
    opacity: .5; }

.thumb-mpc {
  width: 30px;
  height: 30px; }

.thumb-rhb {
  width: 104px;
  height: 104px; }

.thumb-noc {
  width: 102px;
  height: 102px; }

.thumb-news-item {
  width: 100%;
  height: 173px; }

.thumb-casino-rev {
  width: 138px;
  height: 138px; }

.thumb-casino-introduct {
  width: 470px;
  height: 260px; }

.thumb-casino-profile {
  width: 70px;
  height: 70px; }

.thumb-comments-profile {
  width: 112px;
  height: 112px; }

.thumb-lg {
  height: 480px; }

.thumb-logo-sm {
  width: 40px;
  height: 40px; }

.thumb-sticky-headers {
  width: 66px;
  height: 66px; }

.thumb-sticky-bonuses {
  width: 91px;
  height: 91px; }

.thumb-news {
  width: 150px;
  height: 130px; }

.thumb-botton-banner {
  width: 80px;
  height: 80px; }

@media (min-width: 1230px) {
  .thumb-home-lg-casino {
    width: 580px;
    height: 372px; }
  .thumb-home-md-casino {
    width: 333px;
    height: 181px; }
  .thumb-home-sm-casino {
    width: 161px;
    height: 181px; } }

@media (min-width: 992px) and (max-width: 1229px) {
  .thumb-sports {
    width: auto;
    height: 575px; }
  .thumb-post {
    width: 290px;
    height: 290px; }
  .thumb-cat {
    width: 100px;
    height: 100px; }
  .thumb-list {
    width: 86px;
    height: 86px; }
  .thumb-comment {
    width: 86px;
    height: 86px; }
  .thumb-home-lg-casino {
    width: 460px;
    height: 372px; }
  .thumb-home-md-casino {
    width: 333px;
    height: 181px; }
  .thumb-home-sm-casino {
    width: 161px;
    height: 181px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .thumb-sports {
    width: auto;
    height: 575px; }
  .thumb-post {
    width: 330px;
    height: 330px; }
  .thumb-cat {
    width: 100px;
    height: 100px; }
  .thumb-list {
    width: 86px;
    height: 86px; }
  .thumb-comment {
    width: 100px;
    height: 100px; }
  .thumb-home-lg-casino {
    width: auto;
    height: 372px; }
  .thumb-home-md-casino {
    width: auto;
    height: 181px; }
  .thumb-home-sm-casino {
    width: auto;
    height: 181px; }
  .thumb-news {
    width: 115px;
    height: 100px; } }

@media (max-width: 767px) {
  .thumb-sports {
    width: auto;
    height: 480px; }
  .thumb-post {
    width: 290px;
    height: 290px; }
  .thumb-cat {
    width: 80px;
    height: 80px; }
  .thumb-list {
    width: 100px;
    height: 100px; }
  .thumb-comment {
    width: 45px;
    height: 45px; }
  .thumb-home-lg-casino {
    width: auto;
    height: 260px; }
  .thumb-home-md-casino {
    width: auto;
    height: 181px; }
  .thumb-home-sm-casino {
    width: auto;
    height: 181px; }
  .thumb-news {
    width: 115px;
    height: 100px; }
  .thumb-botton-banner {
    width: 50px;
    height: 50px; } }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (10px * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (10px * 2));
    content: ""; }

.raty-red-stars {
  font-size: 0;
  text-align: center; }
  .raty-red-stars span {
    height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
    .raty-red-stars span.star-red-on {
      background-image: url("../images/icons/star-red-on.svg"); }
    .raty-red-stars span.star-red-off {
      background-image: url("../images/icons/star-red-off.svg"); }
    .raty-red-stars span.star-red-half {
      background-image: url("../images/icons/star-red-half.svg"); }
    .raty-red-stars span.star-white-on,
    .raty-red-stars span .star-white-gray-on {
      background-image: url("../images/icons/star-white-on.svg"); }
    .raty-red-stars span.star-white-off {
      background-image: url("../images/icons/star-white-off.svg"); }
    .raty-red-stars span.star-white-half {
      background-image: url("../images/icons/star-white-half.svg"); }
    .raty-red-stars span.star-white-gray-off {
      background-image: url("../images/icons/star-white-off-gray.svg"); }
    .raty-red-stars span.star-white-gray-half {
      background-image: url("../images/icons/star-white-half-gray.svg"); }

.raty-score-sm {
  position: relative;
  font-size: 14px; }
  .raty-score-sm:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1); }
  .raty-score-sm span {
    position: relative;
    z-index: 2; }

.raty-red-stars-sm span {
  height: 12px; }

.entry-content {
  color: #666666; }
  .entry-content h1 {
    font-weight: 900; }
  .entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
    color: #333; }
  .entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6, .entry-content p {
    margin-bottom: 1.25rem; }
    .entry-content h1:last-child, .entry-content h2:last-child, .entry-content h3:last-child, .entry-content h4:last-child, .entry-content h5:last-child, .entry-content h6:last-child, .entry-content p:last-child {
      margin-bottom: 0; }
  .entry-content ol {
    counter-reset: items;
    margin-bottom: 20px; }
    .entry-content ol li {
      position: relative;
      padding-left: 35px; }
      .entry-content ol li:before {
        position: absolute;
        counter-increment: items;
        content: counter(items) ". ";
        font-weight: 700;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto; }
  .entry-content ul li {
    padding-left: 30px;
    margin-bottom: 30px;
    background: url("../images/icons/green-item.svg") 0 5px no-repeat; }
  .entry-content blockquote {
    padding: 50px;
    border: 2px solid #eaeaea;
    border-style: dotted;
    position: relative;
    background: url(../images/icons/quote.png) 20px 20px no-repeat; }
    @media (max-width: 991px) {
      .entry-content blockquote {
        padding: 20px; } }
    .entry-content blockquote p {
      font-size: 20px;
      color: #333; }
  .entry-content table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 4px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #edeeef; }
    .entry-content table td, .entry-content table th {
      padding: 15px; }
    .entry-content table thead th {
      font-size: 11px;
      color: #4b5966;
      text-transform: uppercase; }
      .entry-content table thead th:not(:first-child) {
        text-align: center; }
      .entry-content table thead th:first-child {
        padding-left: 30px; }
    .entry-content table tbody td {
      text-align: center; }
    .entry-content table tr {
      border-bottom: 1px solid #edeeef; }
    .entry-content table .play-real-money {
      padding: 0; }
    .entry-content table .bonus-gray {
      width: 380px; }
    .entry-content table .casino-bonus {
      flex: 0 0 auto;
      max-width: 380px;
      padding: 0;
      margin: 0 auto; }
    .entry-content table .casino-buttons {
      padding-right: 10px; }
    @media (min-width: 768px) {
      .entry-content table .casino-name {
        padding-left: 5px; } }
    .entry-content table .casino-name .thumb {
      width: 60px;
      height: 60px; }
      @media (max-width: 767px) {
        .entry-content table .casino-name .thumb {
          width: 45px;
          height: 45px; }
          .entry-content table .casino-name .thumb img {
            max-width: 100%; } }
    .entry-content table .casino-name a {
      color: #333; }

@media (max-width: 991px) {
  .entry-content table .bonus-gray {
    width: 100px; } }

@media (max-width: 1229px) {
  .casino-name {
    width: 190px; } }

@media (max-width: 767px) {
  .entry-content .casino-name {
    width: 170px; }
  .entry-content > p,
  .entry-content strong {
    text-align: justify; }
  .entry-content table {
    display: block;
    overflow: auto; }
    .entry-content table thead,
    .entry-content table tbody {
      display: table;
      width: 100%;
      min-width: 767px;
      table-layout: fixed; }
  h2 {
    text-align: center; } }

.site-header {
  position: relative;
  z-index: 777; }

.logo {
  line-height: 1; }

.site-nav {
  font-size: 0; }

.main-menu > li {
  position: relative;
  float: left; }
  .main-menu > li:hover a:before, .main-menu > li.hover a:before {
    transform: scaleX(1); }
  .main-menu > li.hover {
    color: #cd0303; }
    .main-menu > li.hover .sub-menu,
    .main-menu > li.hover .menu-two-col {
      display: block; }
  .main-menu > li > a {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    color: #4c4c4c;
    font-weight: 900;
    font-size: 13px;
    padding-top: 27px;
    padding-bottom: 27px; }
    @media (min-width: 992px) and (max-width: 1229px) {
      .main-menu > li > a {
        padding-top: 15px;
        padding-bottom: 15px; } }
    .main-menu > li > a:before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 3px;
      left: 0;
      height: 5px;
      background-color: #cd0303;
      transform: scaleX(0);
      transition: .25s ease-in-out; }
  .main-menu > li:first-child > a {
    padding-left: 0; }

.main-menu a {
  display: block; }
  .main-menu a:hover {
    color: #cd0303; }

.sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  text-align: left;
  background-color: #cd0303;
  text-transform: none;
  z-index: 1;
  padding: 20px; }
  @media (max-width: 767px) {
    .sub-menu {
      position: static; } }
  .sub-menu li {
    margin-bottom: 20px; }
    .sub-menu li:last-of-type {
      margin-bottom: 0; }
    .sub-menu li a {
      font-size: 14px;
      color: #fff;
      white-space: nowrap;
      line-height: 1; }
      .sub-menu li a:hover {
        color: #000; }

.menu-two-col {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  text-align: left;
  background-color: #cd0303;
  text-transform: none;
  z-index: 1;
  padding: 15px; }
  @media (min-width: 768px) {
    .menu-two-col {
      width: 380px; } }
  @media (max-width: 767px) {
    .menu-two-col {
      position: static; } }
  .menu-two-col li {
    margin-bottom: 20px; }
    .menu-two-col li:last-of-type {
      margin-bottom: 0; }
    .menu-two-col li a {
      font-size: 14px;
      color: #fff;
      white-space: nowrap;
      line-height: 1; }
      .menu-two-col li a:hover {
        color: #000; }

.menu-item-title {
  font-size: 13px;
  margin-bottom: 20px; }

.btn-tgl-search:not(.btn) {
  color: #4c4c4c;
  font-weight: 900;
  font-size: 13px; }
  .btn-tgl-search:not(.btn) .icon {
    font-size: 16px; }

.logging-in {
  color: #666666;
  border-left: 1px solid rgba(0, 0, 0, 0.1); }
  .logging-in .icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.3); }
  .logging-in a:hover .icon {
    color: inherit; }

.search-form {
  top: 0;
  right: -1px;
  position: absolute;
  z-index: 2; }
  .search-form:not(.opened) {
    display: none; }
  .search-form input {
    height: 40px;
    color: #4c4c4c;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff; }
    .search-form input::placeholder {
      color: #4c4c4c; }
    .search-form input:focus, .search-form input:valid {
      border-color: rgba(0, 0, 0, 0.2); }
    .search-form input:valid {
      background-color: #e6e6e6; }
      .search-form input:valid + .icon {
        color: #4c4c4c; }
      .search-form input:valid ~ .btn-danger, .search-form .subscripe-box input:valid ~ input[type="submit"], .subscripe-box .search-form input:valid ~ input[type="submit"] {
        display: block; }
      .search-form input:valid ~ .btn-secondary {
        display: none; }
  .search-form .icon,
  .search-form button {
    position: absolute;
    top: 0;
    z-index: 2; }
  .search-form .icon {
    left: 0;
    width: 50px;
    height: 40px;
    color: rgba(76, 76, 76, 0.5);
    line-height: 40px; }
  .search-form button {
    right: 0; }
  .search-form .btn-danger, .search-form .subscripe-box input[type="submit"], .subscripe-box .search-form input[type="submit"] {
    display: none; }

@media (min-width: 1230px) {
  .search-form input {
    width: 665px;
    padding-right: 145px; }
  .search-form button {
    width: 124px; } }

@media (max-width: 1229px) {
  .main-menu > li > a {
    padding-left: 8px;
    padding-right: 8px; }
  .search-form input {
    width: 365px;
    padding-right: 145px; }
  .search-form button {
    width: 90px; } }

@media (max-width: 767px) {
  .main-menu > li {
    float: none; }
    .main-menu > li > a {
      font-size: 16px;
      padding: 5px 0; }
  .button-menu {
    position: relative;
    width: 40px;
    height: 40px;
    transition: none; }
    .button-menu:before, .button-menu:after {
      content: ''; }
    .button-menu span, .button-menu:before, .button-menu:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 24px;
      height: 4px;
      background-color: #292c2f;
      border-radius: 2px;
      transition: all 250ms ease; }
    .button-menu:before {
      transform: translate(0, -8px); }
    .button-menu:after {
      transform: translate(0, 8px); }
    .button-menu:hover span, .button-menu:hover:before, .button-menu:hover:after {
      background-color: #cd0303; }
    .button-menu[aria-expanded="true"] span,
    .menu-opened .button-menu span {
      opacity: 0; }
    .button-menu[aria-expanded="true"]:before,
    .menu-opened .button-menu:before {
      transform: rotate(45deg) translate(0, 0); }
    .button-menu[aria-expanded="true"]:after,
    .menu-opened .button-menu:after {
      transform: rotate(-45deg) translate(0, 0); }
  .site-nav {
    display: none; }
  .logging-in {
    border-left: 0; }
    .logging-in .icon {
      font-size: 19px; }
  .btn-tgl-search:not(.btn) .icon {
    font-size: 19px; }
  .search-form {
    top: 100%;
    right: 0;
    width: 100vw; }
  .position-st {
    position: static; } }

.hts {
  background: url("../images/backgrounds/htbg.jpg") center no-repeat;
  background-size: cover; }

.ht-news {
  position: relative; }
  .ht-news .thumb {
    background-color: #000; }
    .ht-news .thumb img {
      opacity: .4; }
  .ht-news a:not(.btn-success):hover {
    color: #33cc33; }
  .ht-news .title-limit {
    max-height: 100%;
    overflow: hidden; }
    .ht-news .title-limit.h2 {
      max-height: 60%; }

.htn-content,
.htn-actions {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

.htn-content {
  z-index: 2; }

.htn-actions {
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.9);
  transition: 250ms ease-in-out; }
  .ht-news:hover .htn-actions {
    visibility: visible;
    opacity: 1; }

.mpcasinos {
  overflow: hidden; }
  .mpcasinos a:not(.thrills) {
    position: relative;
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #cfcfcf; }
    .mpcasinos a:not(.thrills):hover {
      background-color: #fff; }
      .mpcasinos a:not(.thrills):hover .btn-black {
        display: block; }
  .mpcasinos .raty-red-stars {
    padding-right: 45px;
    padding-left: 10px; }
  .mpcasinos .btn-black {
    display: none;
    position: absolute;
    right: 12px; }

@media (max-width: 1229px) {
  .ht-news .title-limit {
    font-size: 16px; }
  .mpcasinos h5 {
    font-size: 13px; } }

@media (max-width: 767px) {
  .mpcasinos {
    max-width: 282px;
    margin: 0 auto; } }

.hot-bonuses-nav {
  border-bottom: 1px solid #d1d5da; }
  .hot-bonuses-nav .icon {
    font-size: 30px; }
  .hot-bonuses-nav button {
    position: relative;
    color: #999; }
    .hot-bonuses-nav button:hover {
      color: #000; }
      .hot-bonuses-nav button:hover .icon:before {
        color: #000; }
    .hot-bonuses-nav button .icon:before {
      color: #b0b1b2; }
    .hot-bonuses-nav button.active {
      color: #4b5966; }
      .hot-bonuses-nav button.active:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4px;
        background-color: #4b5966; }
      .hot-bonuses-nav button.active .icon:before {
        color: #4b5966; }

.hot-bonuses-nav-item {
  position: relative; }
  .hot-bonuses-nav-item:not(:last-child):before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 30px;
    margin: auto;
    border-right: 1px solid rgba(75, 89, 102, 0.1); }

.bonuses .tr-title {
  color: rgba(75, 89, 102, 0.8);
  font-weight: 400; }

.bonuses .casino-name a {
  font-size: 16px; }
  @media (max-width: 767px) {
    .bonuses .casino-name a {
      font-size: 20px; } }

.bonuses .casino-name p {
  font-weight: 400; }

.bonuses .thumb-rhb {
  width: 60px;
  height: 60px; }

.bonuses .raty-red-stars span {
  height: 11px; }

.bonuses .bonus-gray {
  display: inline-block;
  width: 130px;
  box-sizing: content-box;
  position: relative;
  background: #f6f7f7;
  color: #4b5966;
  text-align: center; }
  .bonuses .bonus-gray:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 10px solid #fff;
    border-left: 65px solid transparent;
    border-right: 65px solid transparent; }
  .bonuses .bonus-gray p {
    padding: 10px 15px 15px;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.5; }

.bonuses .casino-highlights li {
  font-size: 10px;
  padding: 5px 0 5px 25px;
  background: url("../images/icons/green-item.svg") 0 no-repeat; }

.bonuses .casino-buttons .btn, .bonuses .casino-buttons .subscripe-box input[type="submit"], .subscripe-box .bonuses .casino-buttons input[type="submit"] {
  font-size: 14px; }

.bonuses .casino-buttons .btn-link:not(:hover) {
  color: rgba(51, 51, 51, 0.7); }

.tr-casino:not(:last-child) {
  border-bottom: 1px solid #edeeef; }

.tr-casino > .row, .subscripe-box .tr-casino > form, .subscribe .tr-casino > form {
  margin-left: -13px;
  margin-right: -13px; }
  .tr-casino > .row > .col-12, .subscripe-box .tr-casino > form > .col-12, .subscribe .tr-casino > form > .col-12, .subscripe-box .tr-casino > .row > input[type="text"], .subscripe-box .tr-casino > form > input[type="text"], .subscripe-box .subscribe .tr-casino > form > input[type="text"],
  .subscripe-box .tr-casino > .row > input[type="email"],
  .subscripe-box .tr-casino > form > input[type="email"], .subscripe-box .subscribe .tr-casino > form > input[type="email"], .subscripe-box .tr-casino > .row > input[type="submit"], .subscripe-box .tr-casino > form > input[type="submit"], .subscripe-box .subscribe .tr-casino > form > input[type="submit"], .subscribe .tr-casino > .row > input[type=text], .subscribe .subscripe-box .tr-casino > form > input[type=text], .subscribe .tr-casino > form > input[type=text], .subscribe .tr-casino > .row > input[type=submit], .subscribe .subscripe-box .tr-casino > form > input[type=submit], .subscribe .tr-casino > form > input[type=submit] {
    padding-left: 13px;
    padding-right: 13px; }

.rhbs-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.rhb-bonus {
  position: relative;
  background-color: #4b5966;
  margin-right: -20px; }
  .rhb-bonus:before, .rhb-bonus:after {
    content: '';
    position: absolute;
    left: 100%; }
  .rhb-bonus:before {
    width: 10px;
    top: 0;
    bottom: 0;
    background-color: inherit; }
  .rhb-bonus:after {
    top: 100%;
    width: 0;
    height: 0;
    border-top: 18px solid #252b31;
    border-right: 10px solid transparent; }

.rhb-options {
  font-size: 14px;
  color: #4b5966;
  line-height: 1.4;
  background-color: rgba(75, 89, 102, 0.1); }
  .rhb-options li + li {
    position: relative; }
    .rhb-options li + li:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 1px;
      height: 19px;
      background-color: rgba(75, 89, 102, 0.1); }
  .rhb-options .icon {
    vertical-align: middle; }
  .rhb-options .icon-4 {
    font-size: 18px; }
  .rhb-options .icon-5 {
    font-size: 20px; }

@media (min-width: 1230px) {
  .casino-name {
    flex: 0 0 255px;
    max-width: 255px; }
  .casino-bonus {
    flex: 0 0 160px;
    max-width: 160px; }
  .casino-highlights {
    flex: 0 0 230px;
    max-width: 230px; }
  .casino-buttons {
    flex: 0 0 141px;
    max-width: 141px; } }

@media (min-width: 768px) and (max-width: 1229px) {
  .bonuses .casino-name {
    flex: 0 0 220px;
    max-width: 220px; }
  .bonuses .casino-highlights {
    flex: 0 0 180px;
    max-width: 180px; }
    .bonuses .casino-highlights li {
      padding-left: 20px; }
  .bonuses .casino-bonus {
    flex: 0 0 130px;
    max-width: 130px; }
  .bonuses .casino-buttons {
    flex: 0 0 141px;
    max-width: 141px; }
  .bonuses .bonus-gray {
    width: 100px; }
    .bonuses .bonus-gray:after {
      border-left: 50px solid transparent;
      border-right: 50px solid transparent; } }

@media (max-width: 767px) {
  .bonuses .bonus-gray {
    width: 100%; }
    .bonuses .bonus-gray:after {
      display: none; }
  .hot-bonuses-nav .icon {
    font-size: 23px; }
  .hot-bonuses-nav-item .h4 {
    font-size: 14px; }
  .casino-bonus {
    text-align: center; }
  .casino-bonus,
  .casino-highlights,
  .casino-buttons {
    margin-top: 15px; } }

.noc-section {
  background-color: #f7f7f7; }
  .noc-section .swiper-slide {
    width: 20%; }

.noc-slider-wrap {
  overflow: hidden; }
  @media (min-width: 768px) {
    .noc-slider-wrap {
      padding-right: 20%; }
      .noc-slider-wrap:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        width: 200px;
        background-image: linear-gradient(90deg, rgba(247, 247, 247, 0.2) 0, #f7f7f7 80%);
        background-repeat: repeat-x; } }

.noc-slider {
  overflow: visible; }

.noc-review {
  position: relative;
  border: 1px solid transparent;
  font-size: 12px;
  transition: 250ms ease-in-out; }
  .noc-review .entry-content {
    overflow: hidden;
    height: 76px; }
  .noc-review .btn-link {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    opacity: 0;
    background-color: #fff; }
  .noc-review .raty-score-sm span {
    background-color: #f7f7f7; }
  .noc-review .info {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    width: 28px;
    height: 28px;
    line-height: 28px;
    color: #000;
    background-color: rgba(0, 0, 0, 0.1); }
    .noc-review .info:hover {
      color: #fff;
      background-color: #cd0303; }
  .noc-review:hover {
    border: 1px solid #ccc;
    box-shadow: 0 15px 30px rgba(1, 1, 1, 0.1);
    background-color: #fff; }
    .noc-review:hover .btn-link {
      opacity: 1; }
    .noc-review:hover .raty-score-sm span {
      background-color: #fff; }

@media (min-width: 768px) and (max-width: 1229px) {
  .noc-section .swiper-slide {
    width: 25%; }
  .noc-slider-wrap {
    padding-right: 25%; } }

.ng-slot {
  padding-bottom: 30px;
  transition: 250ms ease-in-out; }
  .ng-slot .thumb {
    height: 152px;
    z-index: 2;
    background-color: #000; }
  .ng-slot .hover {
    transform: scale(0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
  .ng-slot .h5 {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    transition: 250ms ease-in-out; }
  .ng-slot:hover {
    padding-bottom: 0; }
    .ng-slot:hover .thumb {
      height: 182px; }
    .ng-slot:hover img {
      opacity: .22; }
    .ng-slot:hover .h5 {
      opacity: 0; }
    .ng-slot:hover .hover {
      z-index: 3;
      transform: scale(1); }

.ng-row {
  margin-left: -13px;
  margin-right: -13px; }
  .ng-row > .col,
  .ng-row > [class*=col-] {
    padding-left: 13px;
    padding-right: 13px; }

@media (max-width: 767px) {
  .ng-slot {
    max-width: 190px;
    margin: 0 auto; } }

.ng-row {
  margin-left: -12px;
  margin-right: -12px; }
  .ng-row > .col,
  .ng-row > [class*=col-] {
    padding-left: 12px;
    padding-right: 12px; }

.news-item {
  height: 412px;
  background-color: #f2f2f2;
  transition: 250ms ease-in-out; }
  .news-item figure {
    flex: 0 0 173px;
    max-height: 173px; }
  .news-item .h4 {
    max-height: 78px;
    line-height: 1.4;
    overflow: hidden; }
  .news-item .entry-content {
    flex-basis: 0;
    flex-grow: 1;
    max-height: 100%;
    font-size: 14px;
    overflow: hidden; }
  .news-item:hover {
    background-color: #fff;
    box-shadow: inset 0 0 0 1px #ccc, 0 15px 30px rgba(1, 1, 1, 0.1); }

.published {
  font-size: 12px; }
  .published .icon {
    font-size: 13px; }

.load-more-news:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05); }

.load-more-news span {
  z-index: 2; }

@media (max-width: 991px) {
  .news-item {
    max-width: 256px;
    margin: 0 auto; } }

.guides-row {
  margin-left: -5px;
  margin-right: -5px; }
  .guides-row .col-12, .guides-row .subscripe-box input[type="text"], .subscripe-box .guides-row input[type="text"],
  .guides-row .subscripe-box input[type="email"], .subscripe-box .guides-row input[type="email"], .guides-row .subscripe-box input[type="submit"], .subscripe-box .guides-row input[type="submit"], .guides-row .subscribe input[type=text], .subscribe .guides-row input[type=text], .guides-row .subscribe input[type=submit], .subscribe .guides-row input[type=submit] {
    padding-left: 5px;
    padding-right: 5px; }

.guides-box {
  padding: 20px 15px;
  background-color: #f2f2f2;
  height: 100%; }
  .guides-box:hover {
    background-color: #fff;
    box-shadow: inset 0 0 0 1px #ccc, 0 15px 30px rgba(1, 1, 1, 0.1); }

.guides-box-img {
  height: 91px; }

.faq .collapse.show {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.faq .collapse-border {
  border: 1px solid #ccc;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.faq .entry-content {
  border-top: 2px solid #f2f2f2; }
  .faq .entry-content p {
    color: #666; }
    .faq .entry-content p:not(:last-child) {
      margin-bottom: 30px; }

.btn-accordion {
  display: block;
  position: relative;
  width: 100%;
  min-height: 66px;
  font-size: 20px;
  text-align: left;
  color: #4b5966;
  background-color: #f2f2f2;
  border: 1px solid transparent;
  border-bottom: 0;
  border-radius: 5px;
  transition-delay: 250ms; }
  .btn-accordion:not(:first-child) {
    margin-top: 15px; }
  .btn-accordion:before, .btn-accordion:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    width: 14px;
    height: 3px;
    background-color: #c0c4c8; }
  .btn-accordion:before {
    transform: translate(-4px, 0) rotate(45deg); }
  .btn-accordion:after {
    transform: translate(4px, 0) rotate(-45deg); }
  .btn-accordion[aria-expanded="true"] {
    color: #000;
    background-color: #fff;
    border-color: #ccc;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: none;
    transition-delay: 1ms; }
    .btn-accordion[aria-expanded="true"]:before {
      background-color: #4b5966;
      transform: translate(4px, 0) rotate(45deg); }
    .btn-accordion[aria-expanded="true"]:after {
      background-color: #4b5966;
      transform: translate(-4px, 0) rotate(-45deg); }

@media (max-width: 767px) {
  .btn-accordion {
    min-height: 55px;
    padding: 10px 0;
    font-size: 16px; } }

.subscribe {
  background: #cd0303 url("../images/backgrounds/subscribe-bg.png") center center no-repeat; }
  .subscribe h4 {
    font-size: 25px;
    font-weight: 400; }
  .subscribe form {
    background-color: #d73535; }
  .subscribe input[type=text] {
    background: url("../images/icons/at-subscr.svg") 25px center no-repeat;
    color: #fff;
    font-size: 18px;
    height: 60px;
    padding-left: 60px; }
    .subscribe input[type=text]::placeholder {
      color: rgba(255, 255, 255, 0.7); }
  .subscribe input[type=submit] {
    font-size: 18px;
    color: #cd0303;
    border-radius: 4px;
    background-color: #fff;
    text-transform: uppercase;
    padding: 13px 68px; }

@media (min-width: 768px) and (max-width: 1229px) {
  .subscribe {
    background-size: 1180px; }
    .subscribe h4 {
      font-size: 22px; }
    .subscribe h3 {
      font-size: 28px; }
    .subscribe input[type=text] {
      font-size: 16px; }
    .subscribe input[type=submit] {
      font-size: 16px;
      padding: 13px 25px; } }

@media (max-width: 767px) {
  .subscribe {
    background-size: 900px; }
    .subscribe h4 {
      font-size: 18px; }
    .subscribe h3 {
      font-size: 22px; }
    .subscribe input[type=text] {
      font-size: 14px;
      height: 50px; }
    .subscribe input[type=submit] {
      font-size: 15px; } }

.review-section-wrapper {
  max-width: 1240px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .review-section-wrapper {
      padding: 30px 35px 0; } }
  @media (max-width: 767px) {
    .review-section-wrapper {
      padding: 15px 15px 0; } }

.review-section {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, transparent 100%);
  background-repeat: repeat-x; }
  @media (min-width: 768px) {
    .review-section {
      padding: 30px 15px; } }
  @media (max-width: 767px) {
    .review-section {
      padding: 15px 10px; } }
  .review-section .raty-red-stars {
    width: 180px; }
  .review-section h4 {
    color: #d9d9d9; }
  .review-section .thumb {
    border: 5px solid #fff; }
  @media (max-width: 767px) {
    .review-section .btn, .review-section .subscripe-box input[type="submit"], .subscripe-box .review-section input[type="submit"] {
      width: 100%; } }

.review-nav ul {
  font-size: 0;
  background-color: rgba(255, 255, 255, 0.1); }
  @media (min-width: 768px) {
    .review-nav ul {
      margin-top: 2.1875rem; } }
  @media (max-width: 767px) {
    .review-nav ul {
      margin-top: 20px; } }
  .review-nav ul li {
    position: relative;
    display: inline-block;
    font-size: 0;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase; }
    .review-nav ul li:not(:last-child):after {
      position: absolute;
      content: '';
      width: 2px;
      height: 25px;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.1);
      margin: auto; }
  .review-nav ul a {
    position: relative;
    display: block;
    font-size: 14px;
    line-height: 35px; }
    @media (min-width: 768px) {
      .review-nav ul a {
        padding: 10px 4.125rem; } }
    @media (max-width: 767px) {
      .review-nav ul a {
        padding: 10px 25px 10px 15px; } }
  .review-nav ul li:not(.flexMenu-viewMore) > a {
    position: relative; }
    .review-nav ul li:not(.flexMenu-viewMore) > a:hover {
      color: rgba(0, 0, 0, 0.9);
      background-color: #fff;
      border-radius: 4px; }
      .review-nav ul li:not(.flexMenu-viewMore) > a:hover:after {
        border-top-color: rgba(0, 0, 0, 0.9); }
    .review-nav ul li:not(.flexMenu-viewMore) > a:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 6px solid rgba(255, 255, 255, 0.5); }
      @media (min-width: 768px) {
        .review-nav ul li:not(.flexMenu-viewMore) > a:after {
          right: 26%; } }
      @media (max-width: 767px) {
        .review-nav ul li:not(.flexMenu-viewMore) > a:after {
          right: 12px; } }
  .review-nav ul li.flexMenu-viewMore {
    position: relative; }
    .review-nav ul li.flexMenu-viewMore > a {
      background: url(../images/svg/more-dots.svg) center no-repeat; }
      @media (min-width: 768px) {
        .review-nav ul li.flexMenu-viewMore > a {
          padding-left: 45px;
          padding-right: 45px; } }
      @media (max-width: 767px) {
        .review-nav ul li.flexMenu-viewMore > a {
          padding-left: 25px;
          padding-right: 25px; } }
  .review-nav ul .flexMenu-popup {
    position: relative;
    background-color: #fff;
    margin-top: 0;
    right: 0;
    box-shadow: 0 0 6px rgba(1, 1, 1, 0.1);
    z-index: 1; }
    .review-nav ul .flexMenu-popup:before {
      position: absolute;
      content: '';
      top: -11px;
      right: 35px;
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 11px solid #fff; }
    .review-nav ul .flexMenu-popup li {
      display: block;
      border-right: none; }
      .review-nav ul .flexMenu-popup li:not(:last-child) {
        border-bottom: 1px solid #f2f2f2; }
      .review-nav ul .flexMenu-popup li a {
        color: rgba(0, 0, 0, 0.9); }
        .review-nav ul .flexMenu-popup li a:hover {
          color: #cd0303; }
          .review-nav ul .flexMenu-popup li a:hover:after {
            border-top-color: #cd0303; }
        .review-nav ul .flexMenu-popup li a:after {
          border-top-color: rgba(0, 0, 0, 0.9); }

.players-from {
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  line-height: 1;
  padding: 4px 12px 4px 5px; }

.country-flag {
  width: 18px;
  height: 18px;
  margin-right: 11px; }

@media (max-width: 1229px) {
  .casino-review-name {
    font-size: 28px; } }

.casino-guts-title {
  font-size: 18px; }

.casino-guts-table .row:nth-child(even), .casino-guts-table .subscripe-box form:nth-child(even), .subscripe-box .casino-guts-table form:nth-child(even), .casino-guts-table .subscribe form:nth-child(even), .subscribe .casino-guts-table form:nth-child(even) {
  background-color: #eff1f3; }
  .casino-guts-table .row:nth-child(even) p, .casino-guts-table .subscripe-box form:nth-child(even) p, .subscripe-box .casino-guts-table form:nth-child(even) p, .casino-guts-table .subscribe form:nth-child(even) p, .subscribe .casino-guts-table form:nth-child(even) p {
    padding: 11px 0; }

.casino-guts-table p {
  padding: 15px 0; }

.pros {
  border: 3px solid #33cc33;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }
  .pros .casino-guts-title {
    background: url(../images/icons/green-item-big.svg) center left no-repeat; }
  .pros li {
    color: #666; }
    .pros li:before {
      content: '+'; }

.pros,
.coins {
  height: 100%; }
  .pros .casino-guts-title,
  .coins .casino-guts-title {
    margin-bottom: 20px;
    padding-left: 30px; }
  .pros li,
  .coins li {
    position: relative;
    font-size: 14px;
    line-height: 1.4285714286;
    padding-left: 35px; }
    .pros li:before,
    .coins li:before {
      position: absolute;
      top: 0;
      left: 6px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 18px;
      line-height: 1; }
    .pros li:not(:last-child),
    .coins li:not(:last-child) {
      margin-bottom: 13px; }

.coins {
  background-color: #f2f4f5;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }
  .coins .casino-guts-title {
    color: rgba(51, 51, 51, 0.8);
    background: url(../images/icons/gray-item.svg) left center no-repeat; }
  .coins li {
    color: rgba(102, 102, 102, 0.8); }
    .coins li:before {
      content: '-'; }

.deposit-languages .btn, .deposit-languages .subscripe-box input[type="submit"], .subscripe-box .deposit-languages input[type="submit"],
.deposit-withdraw .btn,
.deposit-withdraw .subscripe-box input[type="submit"],
.subscripe-box .deposit-withdraw input[type="submit"] {
  padding: 0 5px 5px 0; }

.languages .btn, .languages .subscripe-box input[type="submit"], .subscripe-box .languages input[type="submit"] {
  padding: 0 7px 10px 0; }

@media (max-width: 1229px) {
  .pros,
  .coins {
    margin-top: 20px; } }

@media (max-width: 767px) {
  .coins {
    margin-top: 40px; } }

.look-slider .thumb {
  height: 234px;
  z-index: 2;
  background-color: #000; }

.look-slider-item:hover .look-hover {
  transform: scale(1);
  z-index: 3; }

.look-slider-item:hover img {
  opacity: .2; }

.look-hover {
  transform: scale(0);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto; }
  .look-hover span {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: underline; }

.look-slider-wrapper .btn-slide-prev {
  margin-left: -7%; }

.look-slider-wrapper .btn-slide-next {
  margin-right: -7%; }

.look-pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  width: 6px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  margin: 0 5px; }

.look-pagination .swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #d01010; }

@media (max-width: 1229px) {
  .look-slider-wrapper .btn-slide-next {
    margin-right: 5px; }
  .look-slider-wrapper .btn-slide-prev {
    margin-left: 5px; } }

@media (min-width: 768px) {
  .bonus-baner {
    padding: 70px 70px 45px; } }

.bonuses-section .btn-lg, .bonuses-section .btn-group-lg > .btn, .bonuses-section .subscripe-box .btn-group-lg > input[type="submit"], .subscripe-box .bonuses-section .btn-group-lg > input[type="submit"],
.games-selection .btn-lg,
.games-selection .btn-group-lg > .btn,
.games-selection .subscripe-box .btn-group-lg > input[type="submit"],
.subscripe-box .games-selection .btn-group-lg > input[type="submit"] {
  padding-left: 30px;
  padding-right: 30px;
  z-index: 2;
  position: relative; }

.bg-title {
  position: absolute;
  top: 30px;
  left: 90px;
  font-size: 74px;
  color: #e60505;
  text-shadow: 0 20px 50px #c90303;
  z-index: 1; }

.bonus-baner-cont {
  position: relative;
  z-index: 2; }
  .bonus-baner-cont a {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.5);
    text-decoration: underline; }
    .bonus-baner-cont a:hover {
      color: #fff; }

@media (min-width: 768px) and (max-width: 1229px) {
  .bg-title {
    font-size: 60px; }
  .bonuses-section .btn-lg, .bonuses-section .btn-group-lg > .btn, .bonuses-section .subscripe-box .btn-group-lg > input[type="submit"], .subscripe-box .bonuses-section .btn-group-lg > input[type="submit"],
  .games-selection .btn-lg,
  .games-selection .btn-group-lg > .btn,
  .games-selection .subscripe-box .btn-group-lg > input[type="submit"],
  .subscripe-box .games-selection .btn-group-lg > input[type="submit"] {
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px; } }

@media (max-width: 767px) {
  .bonus-baner {
    padding: 30px;
    text-align: center; }
    .bonus-baner .bg-title {
      font-size: 30px;
      top: 47px;
      left: 0;
      right: 0; }
    .bonus-baner .btn, .bonus-baner .subscripe-box input[type="submit"], .subscripe-box .bonus-baner input[type="submit"] {
      width: 100%;
      margin-top: 15px; } }

.casino-score {
  background-color: #eff1f3;
  padding: 30px 0; }
  .casino-score p {
    font-size: 16px;
    line-height: 1.125;
    color: #666; }
  .casino-score > .row > .col-sm:nth-child(1), .subscripe-box .casino-score > form > .col-sm:nth-child(1), .subscribe .casino-score > form > .col-sm:nth-child(1),
  .casino-score > .row > .col-sm:nth-child(2),
  .subscripe-box .casino-score > form > .col-sm:nth-child(2),
  .subscribe .casino-score > form > .col-sm:nth-child(2) {
    border-right: 1px solid #d7d9db; }
  .casino-score > .row > .col-sm .col-auto, .subscripe-box .casino-score > form > .col-sm .col-auto, .subscribe .casino-score > form > .col-sm .col-auto {
    padding-left: 60px; }

@media (max-width: 1229px) {
  .casino-score > .row > .col-sm .col-auto, .subscripe-box .casino-score > form > .col-sm .col-auto, .subscribe .casino-score > form > .col-sm .col-auto {
    padding-left: 25px; } }

@media (max-width: 767px) {
  .casino-score .btn, .casino-score .subscripe-box input[type="submit"], .subscripe-box .casino-score input[type="submit"] {
    width: 100%; }
  .casino-score > .row > .col-sm:nth-child(1), .subscripe-box .casino-score > form > .col-sm:nth-child(1), .subscribe .casino-score > form > .col-sm:nth-child(1),
  .casino-score > .row > .col-sm:nth-child(2),
  .subscripe-box .casino-score > form > .col-sm:nth-child(2),
  .subscribe .casino-score > form > .col-sm:nth-child(2) {
    border-right: none; }
  .casino-score > .row > .col-sm .col-auto, .subscripe-box .casino-score > form > .col-sm .col-auto, .subscribe .casino-score > form > .col-sm .col-auto {
    padding-left: 10px; } }

.software-providers .thumb {
  height: 130px;
  z-index: 2; }

.btn-providers {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: none;
  z-index: 3;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: 250ms ease-in-out;
  box-shadow: inset 0 0 0 3px #eff1f3; }
  .btn-providers .btn, .btn-providers .subscripe-box input[type="submit"], .subscripe-box .btn-providers input[type="submit"] {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 0; }

.providers-box:hover .btn-providers {
  display: block; }

.providers-box .btn, .providers-box .subscripe-box input[type="submit"], .subscripe-box .providers-box input[type="submit"] {
  white-space: normal;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

@media (max-width: 767px) {
  .software-providers .thumb img {
    max-width: 100%; } }

.casino-boxes {
  display: flex;
  margin-right: -8px;
  margin-left: -8px; }

.casino-box-wrapper {
  position: relative;
  margin-bottom: 16px;
  padding-right: 8px;
  padding-left: 8px; }

.casino-box {
  height: 100%;
  border: 3px solid #eff1f3;
  padding: 23px 20px; }
  .casino-box figure {
    height: 102px; }
  .casino-box p {
    font-size: 12px;
    color: #666666; }

@media (min-width: 992px) {
  .casino-box-wrapper {
    flex: 0 0 20%;
    max-width: 20%; } }

@media (max-width: 1229px) {
  .casino-boxes h3 {
    font-size: 20px; }
  .casino-box {
    padding-left: 15px;
    padding-right: 15px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .casino-box-wrapper {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; } }

@media (max-width: 767px) {
  .casino-box-wrapper {
    flex: 0 0 100%;
    max-width: 100%; } }

.mobile-casino-banner {
  padding: 30px 0; }
  .mobile-casino-banner .btn, .mobile-casino-banner .subscripe-box input[type="submit"], .subscripe-box .mobile-casino-banner input[type="submit"] {
    padding-left: 30px;
    padding-right: 30px; }

@media (max-width: 1229px) {
  .mobile-casino-banner {
    padding: 20px 0; } }

.comments {
  padding: 3.75rem 0; }

.comments-wrapper {
  max-width: 1310px;
  padding: 35px 0 15px;
  margin: 0 auto; }
  .comments-wrapper .btn-lg, .comments-wrapper .btn-group-lg > .btn, .comments-wrapper .subscripe-box .btn-group-lg > input[type="submit"], .subscripe-box .comments-wrapper .btn-group-lg > input[type="submit"] {
    font-size: 14px;
    background-color: #cd0303;
    border-color: #cd0303;
    padding: 17px 55px; }
    @media (max-width: 767px) {
      .comments-wrapper .btn-lg, .comments-wrapper .btn-group-lg > .btn, .comments-wrapper .subscripe-box .btn-group-lg > input[type="submit"], .subscripe-box .comments-wrapper .btn-group-lg > input[type="submit"] {
        width: 100%; } }
    .comments-wrapper .btn-lg:hover, .comments-wrapper .btn-group-lg > .btn:hover, .comments-wrapper .subscripe-box .btn-group-lg > input:hover[type="submit"], .subscripe-box .comments-wrapper .btn-group-lg > input:hover[type="submit"] {
      background-color: #9b0202;
      border-color: #9b0202; }

.comments-sort li {
  display: inline-block;
  cursor: pointer; }
  @media (min-width: 768px) {
    .comments-sort li {
      padding-left: 2.1875rem;
      padding-right: 2.1875rem; } }
  @media (max-width: 767px) {
    .comments-sort li {
      padding-left: 6px;
      padding-right: 6px; } }
  .comments-sort li:first-of-type {
    padding-left: 0; }
  .comments-sort li:last-of-type {
    padding-left: 0; }

.comments-sort a:hover .btn-arrow-down {
  border-top-color: #cd0303; }

.comments-sort .btn-arrow-down {
  border-top-color: #848484; }

.write-review-title {
  font-size: 18px; }

.commets-review > li {
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 0;
  margin-bottom: 35px; }
  @media (min-width: 992px) {
    .commets-review > li > ol {
      padding-left: 10.625rem;
      padding-right: 20px; } }
  @media (max-width: 991px) {
    .commets-review > li > ol {
      padding-left: 15px;
      padding-right: 15px; } }
  .commets-review > li > ol > li {
    border-top: 1px solid #e5e5e5;
    margin-top: 20px; }
    @media (min-width: 992px) {
      .commets-review > li > ol > li {
        padding-right: 7.1875rem; } }
    .commets-review > li > ol > li .thumb {
      width: 66px;
      height: 66px; }

.commets-review .thumb-comments-profile {
  margin: 0 auto; }

.commets-review .btn-outline-light {
  font-size: 11px;
  border-color: #dbdee0; }

.profile-info p {
  font-size: 12px;
  color: #999; }

.profile-info img {
  margin-top: 10px; }

.review-comment h5 {
  font-size: 16px; }

.review-comment .raty-red-stars {
  width: 270px; }

.positive-comment {
  background: url(../images/svg/positive-comments.svg) 0 5px no-repeat; }

.negative-comment {
  background: url(../images/svg/negative-comments.svg) 0 5px no-repeat; }

.negative-comment p,
.positive-comment p {
  margin-bottom: 1.5625rem; }

.dots,
.dots:before,
.dots:after {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%; }

.dots {
  position: relative;
  display: inline-block; }
  .dots:before {
    position: absolute;
    content: '';
    top: 0;
    right: 10px;
    margin: auto; }
  .dots:after {
    position: absolute;
    content: '';
    top: 0;
    left: 10px;
    margin: auto; }

.dots-popup {
  position: relative;
  background-color: #cd0303;
  margin-top: 0;
  top: 10px;
  right: 33px;
  text-align: center;
  box-shadow: 0 0 6px rgba(1, 1, 1, 0.1);
  z-index: 1; }
  .dots-popup:before {
    position: absolute;
    content: '';
    top: -11px;
    right: 23px;
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #cd0303; }
  .dots-popup li {
    display: block;
    border-right: none; }
    .dots-popup li:not(:last-child) {
      border-bottom: 1px solid #fff; }
    .dots-popup li a {
      color: #fff;
      padding: 10px; }
      .dots-popup li a:hover {
        color: #000; }
        .dots-popup li a:hover:after {
          border-top-color: #cd0303; }
      .dots-popup li a:after {
        border-top-color: rgba(0, 0, 0, 0.9); }

.review-comment-options {
  height: 100%; }

.btn-like {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  font-size: 10px;
  color: rgba(102, 102, 102, 0.5); }
  .btn-like:not(.active) {
    background: url(../images/svg/btn-like-border.svg) 0 center no-repeat; }
  .btn-like.active {
    background: url(../images/svg/btn-liked.svg) 0 center no-repeat; }
  .btn-like.focus {
    color: #666666; }

.comments-nav {
  text-align: center;
  font-size: 0; }

.page-numbers {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  text-align: center;
  color: #999; }
  @media (min-width: 768px) {
    .page-numbers {
      width: 65px;
      height: 58px;
      line-height: 3.2222222222; } }
  @media (max-width: 767px) {
    .page-numbers {
      width: 45px;
      height: 45px;
      line-height: 45px; } }
  .page-numbers:hover {
    color: #cd0303; }
  .page-numbers:not(.current):after {
    position: absolute;
    content: '';
    width: 1px;
    height: 18px;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #e0e3e7; }
  .page-numbers.current {
    font-weight: 700;
    color: #cd0303;
    background-color: #fff;
    border-radius: 4px; }

.next-page,
.prev-page {
  opacity: 0.4; }
  .next-page:hover,
  .prev-page:hover {
    opacity: 1; }
  .next-page:after,
  .prev-page:after {
    display: none; }

.next-page {
  background: url(../images/arrows/pagination-next-arrow.svg) center center no-repeat; }

.prev-page {
  background: url(../images/arrows/pagination-prev-arrow.svg) center center no-repeat; }

.line-title {
  position: relative;
  font-size: 40px; }
  .line-title:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 5px;
    background-color: #33cc33;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: auto; }
  .line-title span {
    position: relative;
    display: inline-block;
    padding-right: 15px;
    background-color: #fff;
    z-index: 2; }

@media (min-width: 1230px) {
  .review-comment {
    margin-left: -30px; } }

@media (min-width: 992px) and (max-width: 1229px) {
  .review-comment {
    margin-left: -15px; } }

@media (max-width: 991px) {
  .line-title {
    font-size: 25px; }
    .line-title span {
      padding-left: 15px; } }

.comments-text .raty-red-stars {
  width: 270px; }

.comments-write {
  text-align: right;
  font-size: 11px;
  text-transform: uppercase;
  color: rgba(51, 51, 51, 0.5); }
  .comments-write textarea {
    display: block;
    width: 100%;
    height: 110px;
    resize: none; }
    .comments-write textarea::placeholder {
      font-size: 15px;
      padding-left: 20px; }

.comments-boxes {
  border: 2px solid #e0e3e7;
  padding: 30px 30px 0; }

.gray-line {
  width: 100%;
  height: 2px;
  background-color: #e0e3e7;
  margin-bottom: 30px; }

.checkboxes {
  font-size: 0; }

.form-checkbox {
  position: relative;
  margin-bottom: 30px; }

.form-checkbox-label {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #666666;
  line-height: 1.5714285714;
  padding-left: 40px; }
  .form-checkbox-label:before {
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
    left: 0;
    top: 0;
    border-radius: 4px;
    border: 2px solid #e5e6e6; }

.form-checkbox-input:checked ~ .form-checkbox-label:before {
  background: url(../images/icons/checked-icon.svg) center no-repeat;
  border-color: #cd0303;
  background-color: #cd0303; }

.ready-to-win {
  background: url(../images/backgrounds/ready-to-win-bg.png) center no-repeat;
  background-size: cover;
  text-align: center;
  padding: 8.75rem 0; }
  .ready-to-win h2 {
    font-size: 45px; }
  .ready-to-win .btn, .ready-to-win .subscripe-box input[type="submit"], .subscripe-box .ready-to-win input[type="submit"] {
    display: block;
    width: 230px;
    font-size: 14px;
    margin: 0 auto; }
  .ready-to-win .btn-fb {
    text-transform: initial !important;
    background: #2e8ce4 url(../images/svg/fb-like.svg) 20px center no-repeat; }
    .ready-to-win .btn-fb:hover {
      background-color: #1670c5; }
  .ready-to-win .btn-favourites {
    color: rgba(255, 255, 255, 0.67);
    text-transform: initial !important;
    text-decoration: underline;
    background: url(../images/svg/btn-favourites-op.svg) 20px center no-repeat; }
    .ready-to-win .btn-favourites:hover {
      color: #fff;
      background: url(../images/svg/btn-favourites.svg) 20px center no-repeat; }

@media (min-width: 992px) {
  .ready-to-win {
    height: 30.625rem; } }

@media (max-width: 991px) {
  .ready-to-win {
    padding: 50px 0; }
    .ready-to-win h2 {
      font-size: 25px; } }

@media (max-width: 767px) {
  .ready-to-win .btn, .ready-to-win .subscripe-box input[type="submit"], .subscripe-box .ready-to-win input[type="submit"] {
    width: 100%; }
  .ready-to-win .btn-fb,
  .ready-to-win .btn-favourites {
    background-position: calc(50% - 90px); } }

.sticky-headers-wrapper {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 250ms ease-in-out, transform 400ms ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; }

.sticky-headers {
  background-color: #171717;
  padding: 5px 10px; }
  .sticky-headers .raty-red-stars {
    width: 150px;
    display: inline-flex; }
    .sticky-headers .raty-red-stars span {
      font-size: 12px; }
  .sticky-headers .btn, .sticky-headers .subscripe-box input[type="submit"], .subscripe-box .sticky-headers input[type="submit"] {
    font-size: 14px;
    padding: 11px 26px; }
  .sticky-headers p {
    font-size: 15px; }
    @media (min-width: 1230px) {
      .sticky-headers p {
        font-size: 18px; } }
    @media (max-width: 767px) {
      .sticky-headers p {
        font-size: 13px; } }
    .sticky-headers p span {
      font-size: 12px;
      color: #c8c8c8; }

.sticky-header-show {
  opacity: 1;
  transform: translateY(0); }

@media (max-width: 767px) {
  .thumb-sticky-headers {
    width: 40px;
    height: 40px; }
  .sticky-headers .btn, .sticky-headers .subscripe-box input[type="submit"], .subscripe-box .sticky-headers input[type="submit"] {
    padding: 11px 19px; } }

.breadcrumb {
  background-color: #f2f4f5;
  font-size: 0;
  margin-bottom: 0; }
  .breadcrumb li {
    font-size: 10px;
    line-height: 18px;
    color: #666666; }
    .breadcrumb li:last-of-type {
      color: #000; }
    .breadcrumb li:not(:last-child):after {
      content: "\002F";
      display: inline-block;
      margin: 0 5px;
      vertical-align: middle;
      color: #e0e0e0; }
  .breadcrumb a {
    display: inline;
    vertical-align: middle; }
  .breadcrumb .breadcrumb-item .home {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(../images/logos/breadcrumb-casino-logo.svg) center no-repeat; }
    .breadcrumb .breadcrumb-item .home:hover {
      background: url(../images/logos/breadcrumb-casino-logo-red.svg) center no-repeat; }

.breadcrumb-item + .breadcrumb-item:before {
  display: none; }

.slot-header {
  background-image: radial-gradient(circle, #1a232b, #1d262e);
  background-repeat: no-repeat; }
  .slot-header p {
    display: inline-block; }
  .slot-header .raty-red-stars {
    display: inline-flex;
    width: 185px; }

.slot-video {
  height: 100%;
  position: relative; }
  .slot-video:hover img {
    opacity: 1; }
  @media (min-width: 992px) and (max-width: 1229px) {
    .slot-video .thumb {
      height: 100%; }
      .slot-video .thumb img {
        height: 100%; } }

.slot-cta {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2; }

.btn-slot-play {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5); }
  .btn-slot-play:hover {
    background-color: rgba(0, 0, 0, 0.1); }

.slot-play {
  width: 106px;
  height: 106px;
  line-height: 106px;
  font-size: 0;
  margin-bottom: 10px; }

.slot-content {
  background-color: #f2f4f5;
  height: 100%; }
  .slot-content .entry-content h3 {
    font-size: 18px; }
  .slot-content .entry-content p {
    font-size: 12px;
    color: rgba(102, 102, 102, 0.8); }
  .slot-content h5 {
    font-size: 14px; }
  .slot-content a {
    width: 100%; }

@media (max-width: 767px) {
  .slot-thumb {
    height: 250px; } }

.slot-nav {
  background-color: rgba(255, 255, 255, 0.1); }
  .slot-nav ul {
    font-size: 0; }
    .slot-nav ul li {
      display: inline-block;
      font-size: 14px;
      color: #fff;
      text-transform: uppercase; }
      @media (min-width: 768px) and (max-width: 991px) {
        .slot-nav ul li {
          font-size: 13px; } }
      @media (max-width: 767px) {
        .slot-nav ul li {
          display: block;
          text-align: center;
          border-bottom: 2px solid rgba(0, 0, 0, 0.5); }
          .slot-nav ul li:last-of-type {
            border-bottom: none; } }
      .slot-nav ul li:last-child a:after {
        display: none; }
      .slot-nav ul li span {
        display: inline-block;
        vertical-align: middle;
        padding-right: 15px; }
        @media (min-width: 768px) and (max-width: 991px) {
          .slot-nav ul li span {
            padding-right: 10px; } }
    .slot-nav ul a {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding: 17px 70px; }
      @media (min-width: 992px) and (max-width: 1229px) {
        .slot-nav ul a {
          padding: 17px 39px; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .slot-nav ul a {
          padding: 17px 16px; } }
      .slot-nav ul a:after {
        position: absolute;
        content: "";
        width: 2px;
        height: 22px;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        margin: auto; }
        @media (max-width: 767px) {
          .slot-nav ul a:after {
            display: none; } }
      .slot-nav ul a:hover {
        background-color: #cd0303;
        color: #fff; }
        .slot-nav ul a:hover:after {
          display: none; }

@media (max-width: 767px) {
  .slot-nav ul a {
    width: 100%;
    padding: 15px; } }

.game-review {
  padding: 25px 0; }
  .game-review .entry-content h4 {
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 35px; }

.slots-table .row:nth-child(odd), .slots-table .subscripe-box form:nth-child(odd), .subscripe-box .slots-table form:nth-child(odd), .slots-table .subscribe form:nth-child(odd), .subscribe .slots-table form:nth-child(odd) {
  background-color: #eff1f3; }

.slots-table .row .col:nth-child(odd), .slots-table .subscripe-box form .col:nth-child(odd), .subscripe-box .slots-table form .col:nth-child(odd), .slots-table .subscribe form .col:nth-child(odd), .subscribe .slots-table form .col:nth-child(odd) {
  font-size: 12px;
  text-transform: uppercase; }

.slots-table .row .col:nth-child(even), .slots-table .subscripe-box form .col:nth-child(even), .subscripe-box .slots-table form .col:nth-child(even), .slots-table .subscribe form .col:nth-child(even), .subscribe .slots-table form .col:nth-child(even) {
  text-align: right;
  font-size: 14px; }

.slots-table p {
  padding: 15px 0; }
  .slots-table p span {
    display: inline-block;
    vertical-align: bottom; }
    .slots-table p span img {
      vertical-align: bottom; }

.play-real-money {
  padding: 30px 0; }
  @media (min-width: 1230px) {
    .play-real-money .casino-bonus {
      flex: 0 0 375px;
      max-width: 375px; }
    .play-real-money .bonus-gray {
      width: 300px; }
      .play-real-money .bonus-gray:after {
        right: 0;
        left: auto;
        bottom: 0;
        top: 0;
        border-right: 13px solid #fff;
        border-top: 32px solid transparent;
        border-bottom: 32px solid transparent; }
      .play-real-money .bonus-gray p {
        padding: 23px 15px; } }
  @media (min-width: 992px) and (max-width: 1229px) {
    .play-real-money .casino-bonus {
      flex: 0 0 275px;
      max-width: 275px; }
    .play-real-money .bonus-gray {
      width: 250px; }
      .play-real-money .bonus-gray:after {
        right: 0;
        left: auto;
        bottom: 0;
        top: 0;
        border-right: 13px solid #fff;
        border-top: 32px solid transparent;
        border-bottom: 32px solid transparent; }
      .play-real-money .bonus-gray p {
        padding: 23px 15px; }
    .play-real-money .casino-highlights {
      flex: 0 0 250px;
      max-width: 250px; }
    .play-real-money .casino-buttons {
      flex: 0 0 171px;
      max-width: 171px; } }
  @media (max-width: 767px) {
    .play-real-money .bonus-gray {
      margin: 0 auto;
      width: 300px;
      max-width: 100%; }
      .play-real-money .bonus-gray:after {
        right: 0;
        left: auto;
        bottom: 0;
        top: 0;
        border-right: 13px solid #fff;
        border-top: 32px solid transparent;
        border-bottom: 32px solid transparent; }
      .play-real-money .bonus-gray p {
        padding: 23px 15px; } }
  @media (min-width: 1230px) {
    .play-real-money .casino-highlights {
      flex: 0 0 275px;
      max-width: 275px; }
    .play-real-money .casino-buttons {
      flex: 0 0 251px;
      max-width: 251px; } }

.payouts-row {
  margin-right: -8px;
  margin-left: -8px; }

.payouts-col {
  padding-right: 8px;
  padding-left: 8px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.payouts-box {
  margin-bottom: 16px;
  border: 1px solid #e0e3e7; }
  .payouts-box .thumb {
    width: 96px;
    height: 96px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .payouts-box li {
    padding-left: 20px;
    height: 32px; }
    .payouts-box li:nth-child(odd) {
      background-color: #fff;
      border-radius: 4px; }
  .payouts-box p {
    padding: 0 7px;
    font-size: 12px; }

@media (min-width: 1230px) {
  .payouts-col {
    flex: 0 0 20%;
    max-width: 20%; } }

.bottom-banner {
  background: #b81c0f url("../images/backgrounds/bottom-banner.svg") 86% bottom no-repeat; }
  @media (max-width: 1229px) {
    .bottom-banner {
      background-position: right bottom; } }
  .bottom-banner h3 {
    line-height: 1;
    font-size: 25px; }
    @media (min-width: 992px) {
      .bottom-banner h3 {
        font-size: 32px; } }
  @media (max-width: 1229px) {
    .bottom-banner h4 {
      font-size: 16px; } }
  @media (max-width: 767px) {
    .bottom-banner .thumb img {
      max-width: 100%; } }

.error-page {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 0 75px 0; }
  @media (max-width: 767px) {
    .error-page {
      padding-bottom: 25px; } }
  .error-page h1 {
    font-size: 38px; }
  .error-page p {
    font-size: 18px;
    color: #292c2f; }

.error-btn .btn, .error-btn .subscripe-box input[type="submit"], .subscripe-box .error-btn input[type="submit"] {
  width: 220px;
  font-size: 18px;
  padding: 14px 20px;
  text-align: center; }
  @media (max-width: 767px) {
    .error-btn .btn, .error-btn .subscripe-box input[type="submit"], .subscripe-box .error-btn input[type="submit"] {
      display: block;
      width: 100%; } }

.contact-page {
  background: url("../images/backgrounds/contact-bg.jpg") right center no-repeat;
  background-size: cover;
  padding: 40px 0 65px; }
  .contact-page select {
    background: url("../images/arrows/arrow-contact.svg") calc(100% - 15px) center no-repeat; }
  .contact-page textarea.form-contact {
    padding-top: 15px;
    height: 100px; }
  .contact-page .btn, .contact-page .subscripe-box input[type="submit"], .subscripe-box .contact-page input[type="submit"] {
    width: 240px;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px; }
    @media (max-width: 767px) {
      .contact-page .btn, .contact-page .subscripe-box input[type="submit"], .subscripe-box .contact-page input[type="submit"] {
        width: 100%; } }

.contact-form-wrapper {
  padding: 30px; }
  @media (max-width: 767px) {
    .contact-form-wrapper {
      padding: 20px 10px; } }

.contact-form-left {
  background-color: #f2f4f5; }

.contact-form-right {
  background-color: #fff;
  padding: 30px 30px 20px 0; }
  .contact-form-right .entry-content p {
    font-size: 13px;
    margin-bottom: 30px; }
    @media (max-width: 767px) {
      .contact-form-right .entry-content p {
        margin-bottom: 10px; } }
  .contact-form-right .entry-content address {
    margin-bottom: 5px; }
    .contact-form-right .entry-content address a {
      color: #333; }

.form-contact {
  width: 100%;
  height: 50px;
  font-size: 14px;
  color: #666666;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  margin-bottom: 20px; }

.contact-buttons .row, .contact-buttons .subscripe-box form, .subscripe-box .contact-buttons form, .contact-buttons .subscribe form, .subscribe .contact-buttons form {
  margin-left: -5px;
  margin-right: -5px; }

.contact-buttons .col-lg, .contact-buttons .subscripe-box input[type="text"], .subscripe-box .contact-buttons input[type="text"],
.contact-buttons .subscripe-box input[type="email"], .subscripe-box .contact-buttons input[type="email"], .contact-buttons .subscribe input[type=text], .subscribe .contact-buttons input[type=text] {
  padding-left: 5px;
  padding-right: 5px; }

.contact-btn {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  padding: 13px 0 16px;
  margin-bottom: 10px; }
  .contact-btn:not(:last-of-type) {
    margin-right: 9px; }
  .contact-btn:hover span {
    opacity: 1; }
  .contact-btn span {
    height: 28px;
    opacity: .5; }

.btn-call-supp {
  background-color: #2aa92a; }

.btn-chat {
  background-color: #ecba32; }

.btn-twitter-sup {
  background-color: #19bddb; }

.btn-follow {
  background-color: #1677cd; }

@media (max-width: 767px) {
  .contact-form-right {
    padding: 15px 15px 5px 15px; } }

@media (min-width: 1230px) {
  .listing-intro {
    padding: 80px 0 120px; } }

.listing-intro {
  padding: 50px 0; }

@media (max-width: 767px) {
  .listing-intro {
    padding: 20px 0; }
    .listing-intro figure {
      text-align: center;
      margin-top: 10px; }
      .listing-intro figure img {
        max-height: 50px; } }

.rank-num {
  position: relative; }

.best-live-casinos .tr-casino:nth-child(2) .rank-num-icon {
  color: #fff;
  background: #fff url("../images/icons/rank-icon-first.svg") center no-repeat; }

.rank-num-icon {
  position: absolute;
  top: 5px;
  left: -40px;
  margin: auto;
  width: 40px;
  height: 40px;
  color: #cd0303;
  font-size: 9px;
  text-align: center;
  line-height: 40px;
  background: #fff url("../images/icons/rank-icon-all.svg") center no-repeat; }

@media (min-width: 1230px) {
  .best-live-casinos .casino-name {
    flex: 0 0 269px;
    max-width: 269px; }
  .best-live-casinos .casino-buttons {
    flex: 0 0 156px;
    max-width: 156px; } }

@media (max-width: 767px) {
  .rank-num-icon {
    top: -4px;
    bottom: auto;
    left: -30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); } }

.widget-title {
  background-color: #d8d8da; }

.widget-menu {
  background-color: #f0f1f3; }
  .widget-menu a {
    position: relative;
    display: block;
    padding: 13px 25px 13px 45px;
    font-size: 12px;
    color: #666; }
    .widget-menu a:before {
      content: '';
      position: absolute;
      top: 0;
      left: 30px;
      bottom: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 6px solid rgba(0, 0, 0, 0.5); }
    .widget-menu a:hover {
      color: #fff;
      background-color: #cd0303; }
      .widget-menu a:hover:before {
        border-left-color: #fff; }
  .widget-menu li:not(:last-child) a:after {
    content: '';
    position: absolute;
    right: 25px;
    bottom: 0;
    left: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.listing-content h4 {
  font-size: 18px; }

.listing-content .entry-content table {
  width: 100%;
  font-size: 12px; }
  .listing-content .entry-content table thead {
    background-color: #f0f1f3; }
    .listing-content .entry-content table thead th {
      color: rgba(75, 89, 102, 0.7);
      height: 45px;
      text-align: center; }
      .listing-content .entry-content table thead th:first-child {
        text-align: left; }

.listing-content .entry-content h2 {
  font-size: 25px; }

.listing-content .entry-content h3 {
  font-size: 23px;
  margin: 15px 0; }

.listing-menu li {
  background: url("../images/icons/you-find-menu-icon.svg") 0 no-repeat; }

.listing-menu a {
  display: block;
  font-size: 13px;
  color: #4b5966;
  padding: 8px 30px; }
  .listing-menu a:hover {
    color: #cd0303; }

.casinos-table p {
  font-size: 12px; }

.casinos-table .tr-name {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding-left: 18px; }

.casinos-row:first-child {
  background-color: #f0f1f3;
  text-transform: uppercase;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
  .casinos-row:first-child p {
    color: rgba(75, 89, 102, 0.7); }
  .casinos-row:first-child > div:last-child {
    border-right: 0;
    border-left: 0; }
  .casinos-row:first-child .tr-name {
    border-left: 0; }

.casinos-row:last-child .tr-name {
  border-bottom-left-radius: 4px; }

.casinos-row:last-child .tr-visit {
  border-bottom-right-radius: 4px; }

.casinos-row > div {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
  .casinos-row > div p {
    color: #4b5966;
    line-height: 1;
    padding: 10px 0; }

@media (min-width: 768px) {
  .tr-name {
    flex: 0 0 178px;
    max-width: 178px; }
  .tr-rating {
    flex: 0 0 90px;
    max-width: 90px; }
  .tr-num-games {
    flex: 0 0 110px;
    max-width: 110px; }
  .tr-bonus {
    flex: 0 0 295px;
    max-width: 295px; }
  .tr-visit {
    flex: 0 0 157px;
    max-width: 157px; } }

@media (min-width: 992px) and (max-width: 1229px) {
  .tr-bonus {
    flex: 0 0 154px;
    max-width: 154px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .tr-bonus {
    flex: 0 0 154px;
    max-width: 154px; } }

@media (max-width: 767px) {
  .casinos-row {
    width: 300px;
    margin: 0 auto; }
    .casinos-row:not(:last-child) {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
    .casinos-row > div {
      border-right: 0;
      border-bottom: 0; }
  .casinos-table .tr-name {
    padding-left: 0;
    border-left: 0; } }

.mobile-casinos .casino-name {
  padding-right: 40px !important; }

.mobile-casinos .casino-highlights {
  flex: 0 0 230px;
  max-width: 230px;
  text-align: center;
  padding-right: 60px !important;
  padding-left: 60px !important; }
  .mobile-casinos .casino-highlights h3 {
    color: #4b5966;
    line-height: 1; }
  .mobile-casinos .casino-highlights .row, .mobile-casinos .casino-highlights .subscripe-box form, .subscripe-box .mobile-casinos .casino-highlights form, .mobile-casinos .casino-highlights .subscribe form, .subscribe .mobile-casinos .casino-highlights form {
    margin-right: -5px;
    margin-left: -5px; }
    .mobile-casinos .casino-highlights .row .col, .mobile-casinos .casino-highlights .subscripe-box form .col, .subscripe-box .mobile-casinos .casino-highlights form .col, .mobile-casinos .casino-highlights .subscribe form .col, .subscribe .mobile-casinos .casino-highlights form .col {
      padding-left: 5px;
      padding-right: 5px; }
  .mobile-casinos .casino-highlights .mobile-inactive img {
    opacity: .25; }

@media (max-width: 1229px) {
  .mobile-casinos .casino-highlights {
    flex: 0 0 185px;
    max-width: 185px;
    padding-right: 30px !important;
    padding-left: 30px !important; } }

@media (max-width: 767px) {
  .mobile-casinos .casino-highlights {
    padding-top: 15px;
    margin: 0 auto; } }

.bonuses-live-games .casino-name {
  flex: 0 0 225px;
  max-width: 225px; }

.bonuses-live-games .casino-live-tables {
  color: #4b5966; }
  .bonuses-live-games .casino-live-tables h3 {
    line-height: 1; }
  .bonuses-live-games .casino-live-tables p {
    line-height: 1; }

.bonuses-live-games .casino-highlights {
  flex: 0 0 130px;
  max-width: 130px; }
  .bonuses-live-games .casino-highlights li {
    line-height: 1;
    color: #4b5966; }

@media (min-width: 1230px) {
  .bonuses-live-games .casino-bonus {
    flex: 0 0 170px;
    max-width: 170px;
    padding-left: 20px;
    padding-right: 20px; }
    .bonuses-live-games .casino-bonus .bonus-gray p {
      padding-left: 30px;
      padding-right: 30px; }
  .bonuses-live-games .casino-live-tables {
    flex: 0 0 135px;
    max-width: 135px; }
    .bonuses-live-games .casino-live-tables h3 {
      font-size: 32px; } }

@media (min-width: 768px) and (max-width: 1229px) {
  .bonuses-live-games .casino-bonus {
    flex: 0 0 127px;
    max-width: 127px; }
  .bonuses-live-games .casino-live-tables {
    flex: 0 0 65px;
    max-width: 65px; }
    .bonuses-live-games .casino-live-tables h3 {
      font-size: 28px; }
  .bonuses-live-games .casino-highlights {
    flex: 0 0 114px;
    max-width: 114px; } }

@media (max-width: 991px) {
  .live-games .guides-row .col-12, .live-games .guides-row .subscripe-box input[type="text"], .subscripe-box .live-games .guides-row input[type="text"],
  .live-games .guides-row .subscripe-box input[type="email"], .subscripe-box .live-games .guides-row input[type="email"], .live-games .guides-row .subscripe-box input[type="submit"], .subscripe-box .live-games .guides-row input[type="submit"], .live-games .guides-row .subscribe input[type=text], .subscribe .live-games .guides-row input[type=text], .live-games .guides-row .subscribe input[type=submit], .subscribe .live-games .guides-row input[type=submit] {
    margin-bottom: 10px; }
  .casino-live-tables {
    display: block; } }

.casino-stats {
  color: #4b5966;
  font-size: 12px; }
  .casino-stats .swiper-slide {
    width: auto; }

.stats-box {
  width: 195px;
  border-right: 1px solid #e6e6e6; }
  .stats-box:first-child {
    border-left: 1px solid #e6e6e6; }
  .stats-box > div {
    height: 75px; }

.stats-value {
  font-size: 24px;
  line-height: 1;
  font-weight: bold; }

.how-to-play h1,
.how-to-play h2 {
  font-size: 45px; }

.how-to-play h4 {
  color: rgba(255, 255, 255, 0.5); }

@media (min-width: 1230px) {
  .how-to-play {
    padding: 165px 0 180px; } }

@media (min-width: 992px) and (max-width: 1229px) {
  .how-to-play {
    padding: 70px 0; }
    .how-to-play h1,
    .how-to-play h2 {
      font-size: 40px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .how-to-play h1,
  .how-to-play h2 {
    font-size: 35px; } }

@media (max-width: 991px) {
  .how-to-play {
    padding: 40px 0; }
    .how-to-play h4 {
      font-size: 16px; } }

@media (max-width: 767px) {
  .how-to-play h1,
  .how-to-play h2 {
    font-size: 30px; } }

.rules-border {
  position: relative;
  padding: 35px 0 40px; }
  @media (max-width: 767px) {
    .rules-border {
      padding: 0; } }
  .rules-border:before {
    position: absolute;
    content: '';
    width: 892px;
    left: -50px;
    top: 0;
    bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    @media (min-width: 992px) and (max-width: 1229px) {
      .rules-border:before {
        width: 690px; } }
    @media (max-width: 991px) {
      .rules-border:before {
        display: none; } }

.rules-content {
  background: url("../images/backgrounds/craps-rules-bg.png") right center no-repeat;
  background-size: cover; }

.craps-point {
  position: relative; }
  .craps-point:before, .craps-point:after {
    position: absolute;
    content: '';
    top: 7px; }
  .craps-point:before {
    left: -20px;
    height: 100%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.05); }
    @media (max-width: 767px) {
      .craps-point:before {
        left: -9px; } }
  .craps-point:after {
    left: -23px;
    background-color: #d7d8d9;
    width: 7px;
    height: 7px; }
    @media (max-width: 767px) {
      .craps-point:after {
        left: -12px; } }
  .craps-point p {
    color: #676b6e; }
  .craps-point p:not(:last-child) {
    margin-bottom: 20px; }
  .craps-point h4 {
    color: #252d34; }
  .craps-point ul li {
    background: url("../images/icons/red-diamond.svg") 0 12px no-repeat;
    color: #676b6e;
    padding: 5px 0 5px 15px; }

.craps-point:not(:last-child) {
  padding-bottom: 50px; }
  @media (min-width: 992px) and (max-width: 1229px) {
    .craps-point:not(:last-child) {
      padding-bottom: 30px; } }
  @media (max-width: 991px) {
    .craps-point:not(:last-child) {
      padding-bottom: 20px; } }

.red-arrow-box {
  border: 2px solid rgba(0, 0, 0, 0.05);
  background: url("../images/backgrounds/red-arrow-box-bg.png") right bottom no-repeat;
  margin-top: 20px; }
  .red-arrow-box h4 {
    color: #373b3f;
    padding-right: 10px; }
    @media (min-width: 992px) and (max-width: 1229px) {
      .red-arrow-box h4 {
        font-size: 18px; } }

.red-arrow-box-left {
  position: relative;
  height: 150px;
  box-shadow: 0 0 0 1px #d20909;
  background-image: linear-gradient(to right, #e30f0f 0%, #d20909 100%);
  background-repeat: repeat-x; }
  .red-arrow-box-left:after {
    position: absolute;
    content: '';
    top: 0;
    right: -23px;
    bottom: 0;
    margin: auto;
    width: 0;
    height: 0;
    border-top: 76px solid transparent;
    border-left: 22px solid #d20909;
    border-bottom: 76px solid transparent; }

.craps-strategy {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 45px; }

.red-num {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  line-height: 30px;
  text-align: center;
  background-color: #cd0303;
  width: 30px;
  height: 30px;
  margin-right: 15px; }

.craps-etiquette {
  background: url("../images/backgrounds/craps-etiquette-bg.png") right 70px no-repeat; }

.craps-best-casinos .casino-highlights {
  flex: 0 0 275px;
  max-width: 275px; }

.craps-best-casinos .casino-buttons {
  flex: 0 0 165px;
  max-width: 165px; }

@media (min-width: 992px) and (max-width: 1229px) {
  .red-arrow-box .h1 {
    font-size: 28px; } }

@media (max-width: 991px) {
  .craps-rules .mpcasinos,
  .slots-rules .mpcasinos,
  .video-poker-rules .mpcasinos,
  .blackjack-rules .mpcasinos,
  .single-blog-content .mpcasinos,
  .news-wrapper .mpcasinos {
    position: static !important;
    max-width: 235px;
    margin: 0 auto;
    margin-top: 15px; }
    .craps-rules .mpcasinos.is_stuck + div,
    .slots-rules .mpcasinos.is_stuck + div,
    .video-poker-rules .mpcasinos.is_stuck + div,
    .blackjack-rules .mpcasinos.is_stuck + div,
    .single-blog-content .mpcasinos.is_stuck + div,
    .news-wrapper .mpcasinos.is_stuck + div {
      display: none !important; } }

@media (min-width: 768px) and (max-width: 1229px) {
  .craps-best-casinos .casino-highlights {
    flex: 0 0 181px;
    max-width: 181px; }
  .craps-best-casinos .casino-buttons {
    flex: 0 0 145px;
    max-width: 145px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .red-arrow-box .h1 {
    font-size: 26px; } }

@media (max-width: 767px) {
  .craps-rules h3 {
    text-align: center; }
  .red-arrow-box {
    border: none;
    background: none; }
  .red-arrow-box-left {
    height: auto;
    padding: 20px;
    background: #cd0303;
    margin-bottom: 30px; }
    .red-arrow-box-left:after {
      content: '';
      top: 100%;
      bottom: auto;
      right: 0;
      left: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-top: 15px solid #cd0303; }
  .craps-best-casinos .casino-buttons {
    flex: 0 0 100%;
    max-width: 100%; } }

.online-slots-intro h4 {
  color: rgba(255, 255, 255, 0.5); }

@media (min-width: 1230px) {
  .online-slots-intro {
    height: 580px; }
    .online-slots-intro h1 {
      font-size: 45px; } }

@media (min-width: 992px) and (max-width: 1229px) {
  .online-slots-intro {
    height: 370px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .online-slots-intro {
    height: 290px; } }

@media (max-width: 767px) {
  .online-slots-intro {
    height: 260px; } }

.basics-slots {
  background-color: #f4f5f5;
  padding: 40px 30px; }
  @media (max-width: 767px) {
    .basics-slots {
      padding: 20px 10px; } }

.slot-payout {
  background: url("../images/backgrounds/slot-payout-bg.png") right 15px no-repeat; }

.bonus-symbols {
  background: url("../images/backgrounds/bonus-symbols-bg.png") left top no-repeat;
  padding: 15px 0;
  padding: 0; }

.read-more-box {
  border: 1px solid #e5e6e6;
  box-shadow: 7.5px 13px 25px rgba(4, 5, 5, 0.1);
  padding: 30px; }
  @media (max-width: 1229px) {
    .read-more-box {
      padding: 15px; } }

.slot-bonus-box {
  background-image: linear-gradient(to right, transparent 20%, #eeeeef 100%);
  background-repeat: repeat-x;
  padding-bottom: 20px; }

@media (max-width: 991px) {
  .bonus-symbols {
    background: none; } }

@media (min-width: 1230px) {
  .what-you-need {
    position: relative; }
    .what-you-need .row:first-of-type, .what-you-need .subscripe-box form:first-of-type, .subscripe-box .what-you-need form:first-of-type, .what-you-need .subscribe form:first-of-type, .subscribe .what-you-need form:first-of-type {
      margin-bottom: 150px; }
    .what-you-need figure {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      text-align: right; } }

.rouletter-rules .rules-content {
  background: url("../images/backgrounds/how-play-roulette-bg.png") right center no-repeat;
  background-size: cover; }

.bet-table {
  position: relative; }
  .bet-table:before, .bet-table:after {
    position: absolute;
    content: '';
    width: 61px;
    height: 61px;
    background-image: url("../images/logos/correct-logo-w.png");
    right: -20px;
    z-index: 1; }
  .bet-table:before {
    top: -20px; }
  .bet-table:after {
    bottom: -20px; }
  .bet-table .row:last-child .bet-table-left, .bet-table .subscripe-box form:last-child .bet-table-left, .subscripe-box .bet-table form:last-child .bet-table-left, .bet-table .subscribe form:last-child .bet-table-left, .subscribe .bet-table form:last-child .bet-table-left,
  .bet-table .row:last-child .bet-table-right,
  .bet-table .subscripe-box form:last-child .bet-table-right,
  .subscripe-box .bet-table form:last-child .bet-table-right,
  .bet-table .subscribe form:last-child .bet-table-right,
  .subscribe .bet-table form:last-child .bet-table-right {
    border-bottom: none; }

.bet-table-left {
  padding: 40px 40px 40px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .bet-table-left p {
    font-size: 16px;
    line-height: 1.375;
    color: #333; }

.bet-table-right {
  padding: 40px 0 40px 40px;
  border-bottom: 1px solid #e50f0f; }
  .bet-table-right li {
    font-size: 21px;
    color: #fff;
    line-height: 1; }
    .bet-table-right li:not(:last-child) {
      margin-bottom: 30px; }

.roulette-box {
  position: relative;
  border: 1px solid #e6e6e6;
  padding: 90px 30px 30px; }

.roulette-circle {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  transform: translate(0, -50%);
  width: 120px;
  height: 120px; }

@media (min-width: 992px) {
  .roulette-boxes {
    margin-top: 90px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .roulette-boxes {
    margin-top: 70px; } }

@media (max-width: 1229px) {
  .bet-table-left {
    padding: 20px 20px 20px 0; }
  .bet-table-right {
    padding: 20px 0 20px 20px; } }

@media (min-width: 768px) and (max-width: 1229px) {
  .bet-table-right li {
    font-size: 19px; }
    .bet-table-right li:not(:last-child) {
      margin-bottom: 20px; } }

@media (max-width: 767px) {
  .bet-table-right li {
    font-size: 16px; }
    .bet-table-right li:not(:last-child) {
      margin-bottom: 10px; }
  .roulette-boxes {
    margin-top: 50px; }
  .roulette-circle {
    width: 80px;
    height: 80px; }
  .roulette-box {
    position: relative;
    border: 1px solid #e6e6e6;
    padding: 50px 20px 20px; }
  .bet-table-left {
    padding: 15px 0; }
    .bet-table-left p {
      font-size: 15px; }
  .bet-table-right {
    padding: 15px 0; } }

.video-poker-rules .rules-content {
  background: url("../images/backgrounds/video-poker.png") right center no-repeat;
  background-size: cover; }

.video-poker-rules table {
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 15px; }
  .video-poker-rules table thead {
    background-color: #fcf2f2; }
    .video-poker-rules table thead td {
      color: #ce0a0a;
      text-transform: uppercase; }
  .video-poker-rules table tbody tr:nth-child(even) {
    background-color: #f2f2f2; }
  .video-poker-rules table td {
    line-height: 1;
    color: #333;
    padding: 20px 30px; }
    @media (max-width: 1229px) {
      .video-poker-rules table td {
        padding: 10px; } }

.table-info {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5); }

.video-poker-tips .red-num {
  position: absolute;
  left: 0;
  width: 36px;
  height: 36px;
  line-height: 36px; }

.video-poker-tips figure {
  height: 117px; }

.poker-tips-title {
  position: relative;
  font-size: 22px;
  padding-bottom: 25px; }
  @media (max-width: 767px) {
    .poker-tips-title {
      padding-bottom: 10px; } }
  .poker-tips-title:after {
    position: absolute;
    content: '';
    width: 70px;
    height: 3px;
    background-color: #cd0303;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }

@media (max-width: 991px) {
  .video-poker-tips .red-num {
    width: 30px;
    height: 30px;
    line-height: 30px; }
  .video-poker-tips figure {
    height: 100px; } }

@media (max-width: 767px) {
  .video-poker-tips .red-num {
    position: relative; }
  .video-poker-tips .col-12:not(:last-child), .video-poker-tips .subscripe-box input:not(:last-child)[type="text"], .subscripe-box .video-poker-tips input:not(:last-child)[type="text"],
  .video-poker-tips .subscripe-box input:not(:last-child)[type="email"], .subscripe-box .video-poker-tips input:not(:last-child)[type="email"], .video-poker-tips .subscripe-box input:not(:last-child)[type="submit"], .subscripe-box .video-poker-tips input:not(:last-child)[type="submit"], .video-poker-tips .subscribe input:not(:last-child)[type=text], .subscribe .video-poker-tips input:not(:last-child)[type=text], .video-poker-tips .subscribe input:not(:last-child)[type=submit], .subscribe .video-poker-tips input:not(:last-child)[type=submit] {
    margin-bottom: 20px; } }

.blackjack-rules .rules-content {
  background: none; }

.li-item-line > li {
  position: relative;
  padding: 10px 0 10px 22px; }
  .li-item-line > li:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 8px;
    height: 4px;
    border-radius: 2px;
    background-color: #cd0303; }

.blackjack-strategies ol li {
  padding-top: 5px;
  padding-bottom: 5px; }
  .blackjack-strategies ol li:before {
    color: #fff;
    content: counter(items);
    font-size: 13px;
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    line-height: 20px; }

.dealer-table {
  width: 100%;
  border: none;
  overflow: auto; }
  .dealer-table tr {
    border: none; }
  .dealer-table th, .dealer-table td {
    padding: 25px;
    font-size: 15px;
    border: none; }
    @media (max-width: 1229px) {
      .dealer-table th, .dealer-table td {
        padding: 10px; } }
  .dealer-table strong {
    font-weight: 900; }
  .dealer-table thead th {
    font-weight: 900; }
  .dealer-table thead th:not(:first-child) {
    color: #ce0a0a;
    border: 2px solid #e5e5e5;
    border-bottom-width: 0; }
  .dealer-table tbody tr td {
    color: #333;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.05); }
    .dealer-table tbody tr td:first-child {
      color: rgba(255, 255, 255, 0.7);
      text-align: left; }
    .dealer-table tbody tr td:last-child {
      border-right-width: 2px; }
  .dealer-table tbody tr:nth-child(even) td {
    background-color: #f7f7f7; }
  .dealer-table tbody tr:nth-child(even) td:first-child {
    background-color: #bd0808;
    border-right: 2px solid #bd0808; }
  .dealer-table tbody tr:nth-child(even):last-child td:first-child {
    border-bottom: 2px solid #bd0808; }
  .dealer-table tbody tr:nth-child(odd) td {
    background-color: #eaeaea; }
  .dealer-table tbody tr:nth-child(odd) td:first-child {
    background-color: #b20808;
    border-right: 2px solid #b20808; }
  .dealer-table tbody tr:nth-child(odd):last-child td:first-child {
    border-bottom: 2px solid #b20808; }
  .dealer-table tbody tr:first-child td {
    background-color: rgba(0, 0, 0, 0.1); }
    .dealer-table tbody tr:first-child td:first-child {
      color: #fff;
      background-color: #bd0808;
      border-bottom: 2px solid #ad0808;
      border-right: 2px solid #bd0808; }
  .dealer-table tbody tr:last-child td:not(:first-child) {
    border-bottom: 2px solid #e5e5e5; }

.key-table {
  background-color: #f7f7f7;
  border: 2px solid #e5e5e5;
  padding: 30px; }
  .key-table .col-12 p:not(:last-child), .key-table .subscripe-box input[type="text"] p:not(:last-child), .subscripe-box .key-table input[type="text"] p:not(:last-child), .key-table .subscripe-box input[type="email"] p:not(:last-child), .subscripe-box .key-table input[type="email"] p:not(:last-child), .key-table .subscripe-box input[type="submit"] p:not(:last-child), .subscripe-box .key-table input[type="submit"] p:not(:last-child), .key-table .subscribe input[type=text] p:not(:last-child), .subscribe .key-table input[type=text] p:not(:last-child), .key-table .subscribe input[type=submit] p:not(:last-child), .subscribe .key-table input[type=submit] p:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 2px solid #eaeaea; }
  .key-table .col-12 p:not(:first-child), .key-table .subscripe-box input[type="text"] p:not(:first-child), .subscripe-box .key-table input[type="text"] p:not(:first-child), .key-table .subscripe-box input[type="email"] p:not(:first-child), .subscripe-box .key-table input[type="email"] p:not(:first-child), .key-table .subscripe-box input[type="submit"] p:not(:first-child), .subscripe-box .key-table input[type="submit"] p:not(:first-child), .key-table .subscribe input[type=text] p:not(:first-child), .subscribe .key-table input[type=text] p:not(:first-child), .key-table .subscribe input[type=submit] p:not(:first-child), .subscribe .key-table input[type=submit] p:not(:first-child) {
    padding-top: 20px; }
  .key-table .col-12 p, .key-table .subscripe-box input[type="text"] p, .subscripe-box .key-table input[type="text"] p, .key-table .subscripe-box input[type="email"] p, .subscripe-box .key-table input[type="email"] p, .key-table .subscripe-box input[type="submit"] p, .subscripe-box .key-table input[type="submit"] p, .key-table .subscribe input[type=text] p, .subscribe .key-table input[type=text] p, .key-table .subscribe input[type=submit] p, .subscribe .key-table input[type=submit] p {
    font-size: 18px;
    color: #333; }

.table-wrapper {
  overflow: auto; }

@media (max-width: 1229px) {
  .key-table {
    padding: 15px; }
    .key-table .col-12 p:not(:last-child), .key-table .subscripe-box input[type="text"] p:not(:last-child), .subscripe-box .key-table input[type="text"] p:not(:last-child), .key-table .subscripe-box input[type="email"] p:not(:last-child), .subscripe-box .key-table input[type="email"] p:not(:last-child), .key-table .subscripe-box input[type="submit"] p:not(:last-child), .subscripe-box .key-table input[type="submit"] p:not(:last-child), .key-table .subscribe input[type=text] p:not(:last-child), .subscribe .key-table input[type=text] p:not(:last-child), .key-table .subscribe input[type=submit] p:not(:last-child), .subscribe .key-table input[type=submit] p:not(:last-child) {
      padding-bottom: 10px; }
    .key-table .col-12 p:not(:first-child), .key-table .subscripe-box input[type="text"] p:not(:first-child), .subscripe-box .key-table input[type="text"] p:not(:first-child), .key-table .subscripe-box input[type="email"] p:not(:first-child), .subscripe-box .key-table input[type="email"] p:not(:first-child), .key-table .subscripe-box input[type="submit"] p:not(:first-child), .subscripe-box .key-table input[type="submit"] p:not(:first-child), .key-table .subscribe input[type=text] p:not(:first-child), .subscribe .key-table input[type=text] p:not(:first-child), .key-table .subscribe input[type=submit] p:not(:first-child), .subscribe .key-table input[type=submit] p:not(:first-child) {
      padding-top: 10px; }
    .key-table .col-12 p, .key-table .subscripe-box input[type="text"] p, .subscripe-box .key-table input[type="text"] p, .key-table .subscripe-box input[type="email"] p, .subscripe-box .key-table input[type="email"] p, .key-table .subscripe-box input[type="submit"] p, .subscripe-box .key-table input[type="submit"] p, .key-table .subscribe input[type=text] p, .subscribe .key-table input[type=text] p, .key-table .subscribe input[type=submit] p, .subscribe .key-table input[type=submit] p {
      font-size: 16px; } }

@media (max-width: 767px) {
  .key-table p {
    padding: 10px 0;
    border-bottom: 2px solid #eaeaea; } }

.restricted .noc-review,
.restricted-content .noc-review,
.additions-restricted-content .noc-review,
.casino-bonuses-section .noc-review {
  background-color: #f2f4f5;
  border: none; }
  .restricted .noc-review h4,
  .restricted-content .noc-review h4,
  .additions-restricted-content .noc-review h4,
  .casino-bonuses-section .noc-review h4 {
    font-size: 18px; }
  .restricted .noc-review footer,
  .restricted-content .noc-review footer,
  .additions-restricted-content .noc-review footer,
  .casino-bonuses-section .noc-review footer {
    padding: 15px 20px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }
    .restricted .noc-review footer p,
    .restricted-content .noc-review footer p,
    .additions-restricted-content .noc-review footer p,
    .casino-bonuses-section .noc-review footer p {
      font-size: 16px; }

.restricted-title {
  font-size: 16px;
  background: url("../images/icons/restricted-i.svg") left center no-repeat; }

.unavailable {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  padding: 14px 55px; }

@media (max-width: 767px) {
  .restricted .noc-review,
  .restricted-content .noc-review {
    width: 270px;
    margin: 0 auto; } }

.restricted-dialog,
.additions-accepts-dialog,
.additions-restricted-dialog {
  max-width: 1170px;
  margin: 0 auto; }

.restricted-content,
.additions-accepts-content,
.additions-restricted-content {
  position: relative;
  padding: 25px 30px; }

.restricted-close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 35px;
  right: 30px; }
  .restricted-close:before, .restricted-close:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 2px;
    height: 22px;
    background-color: #babcc0; }
  .restricted-close:before {
    transform: rotate(45deg); }
  .restricted-close:after {
    transform: rotate(-45deg); }

.restricted-sub-title {
  font-size: 18px; }

.countries {
  background-color: #f2f4f5;
  padding: 20px 30px 0; }
  .countries ul li {
    color: #666;
    line-height: 1;
    padding: 12px 0; }
  .countries ul li:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.additions-accepts-content .accepts-title {
  line-height: 1; }

@media (max-width: 767px) {
  .additions-accepts-content .accepts-title,
  .additions-restricted-content .accepts-title {
    position: relative;
    padding-left: 40px; }
  .additions-accepts-content h2,
  .additions-restricted-content h2 {
    font-size: 24px; }
  .accepts-list-icon {
    position: absolute;
    left: 0;
    top: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .additions-accepts-content .bonus-baner {
    padding: 55px 65px 35px; } }

.casino-bonuses-header {
  background-color: #292c2f; }

.casino-bonuses-box {
  overflow: hidden; }
  .casino-bonuses-box header {
    color: rgba(0, 0, 0, 0.7);
    background-color: #d9dbdc; }
  .casino-bonuses-box .btn, .casino-bonuses-box .subscripe-box input[type="submit"], .subscripe-box .casino-bonuses-box input[type="submit"] {
    font-size: 14px; }

.restricted-btn {
  background-color: rgba(0, 0, 0, 0.15);
  padding-top: 14px;
  padding-bottom: 14px; }

.casino-bonuses-sticky {
  position: absolute;
  border: 1px solid #c9cccf; }
  .casino-bonuses-sticky h4 {
    font-size: 18px; }
    .casino-bonuses-sticky h4 a {
      color: #292c2f; }
  .casino-bonuses-sticky .raty-red-stars {
    width: 180px;
    margin: 0 auto; }

@media (min-width: 992px) {
  .casino-bonuses-sticky {
    width: 240px; } }

@media (max-width: 991px) {
  .casino-bonuses-sticky {
    position: static !important;
    max-width: 275px;
    margin: 0 auto; }
    .casino-bonuses-sticky.is_stuck + div {
      display: none !important; } }

@media (max-width: 767px) {
  .casino-bonuses-box {
    max-width: 275px;
    margin: 0 auto; } }

.compare-intro {
  background-color: #1b242c; }

.compare {
  position: relative;
  padding-bottom: 55px; }
  .compare:before {
    position: absolute;
    content: '';
    background-color: #1b242c;
    height: 30px;
    top: 0;
    left: 0;
    right: 0;
    margin: auto; }

.compare-box-wrapper footer {
  background-color: #eff1f3; }

.compare-top-line {
  height: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.compare-bottom-line {
  height: 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.casino-name-box {
  background-color: #eff1f3;
  padding-top: 60px; }
  .casino-name-box .noc-review {
    position: relative;
    background-color: #f7f8f9;
    border: none;
    padding-bottom: 20px; }
    .casino-name-box .noc-review:hover {
      border: none;
      box-shadow: none; }
      .casino-name-box .noc-review:hover .raty-score-sm span {
        background-color: #f7f8f9; }
    .casino-name-box .noc-review figure {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      transform: translateY(-50%); }

.name-box-wrapper {
  width: 210px;
  padding-top: 60px;
  margin: auto; }
  .name-box-wrapper .raty-red-stars-sm span {
    height: 14px; }
  .name-box-wrapper .raty-score-sm {
    font-size: 16px; }

.compare-table .compare-row:nth-child(odd) {
  background-color: #eff1f3; }

.compare-table .compare-row:nth-child(even) {
  background-color: #e3e5e7; }

.compare-row .stats-value {
  font-size: 20px;
  color: #4b5966; }

.compare-row p:not(.stats-value) {
  color: #4b5966; }

.payment-methods-slider .swiper-slide {
  width: auto; }

.payment-methods-slider button {
  padding: 0 5px; }

.vs h3 {
  color: #4b5966; }

.vs-circle {
  background: #4b5966 url("../images/arrows/arrows-compare.png") center no-repeat;
  width: 59px;
  height: 59px; }

@media (min-width: 768px) and (max-width: 991px) {
  .games-row {
    width: 180px;
    margin: auto; } }

@media (max-width: 767px) {
  .compare-box-wrapper {
    max-width: 290px; }
  .overflow-auto {
    overflow: auto;
    white-space: nowrap; } }

.calculator {
  padding-bottom: 95px; }

.question p {
  font-size: 22px; }
  @media (max-width: 1229px) {
    .question p {
      font-size: 19px; } }

.progress-line {
  font-size: 0; }

.progress-line-bar {
  display: inline-block;
  background-color: #d5d7db;
  width: 19%;
  height: 10px;
  border-radius: 5px; }
  .progress-line-bar:not(:last-child) {
    margin-right: 1%; }
  .progress-line-bar.active {
    background-color: #33cc33; }

.range-wrapper {
  background-color: #f0f1f3; }

.range-steps:not(.active) {
  display: none; }

.range-value {
  border: 1px solid #c0c1c2;
  height: 40px;
  width: 100%;
  font-size: 18px;
  padding-left: 20px; }
  @media (max-width: 1229px) {
    .range-value {
      font-size: 16px; } }
  @media (min-width: 992px) {
    .range-value {
      width: 168px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .range-value {
      width: 100px; } }

.noUi-target {
  height: 40px;
  overflow: hidden;
  padding: 15px 0; }

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

.noUi-connects {
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden; }

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: 0 0; }

.noUi-connect {
  background-image: linear-gradient(to right, #e37676 0, #f6d1d1 100%);
  background-repeat: repeat-x; }

.noUi-handle {
  background: url("../images/buttons/no-Ui-handle.png") center no-repeat;
  position: absolute;
  width: 20px;
  height: 42px;
  top: 0;
  bottom: 0;
  right: -10px;
  margin: auto; }
  .noUi-handle:focus {
    outline: none; }

.range-btn {
  width: 100%; }
  @media (min-width: 992px) {
    .range-btn {
      width: 137px; } }

.final-step .thumb {
  width: 98px;
  height: 98px;
  background-color: #fff; }
  @media (max-width: 767px) {
    .final-step .thumb {
      margin: 0 auto; } }

.single-blog {
  height: 255px; }
  .single-blog .published {
    background-color: rgba(255, 255, 255, 0.9); }

.single-blog-content .entry-content blockquote {
  background: none;
  margin-bottom: 20px; }

.related-news .dots,
.related-news .dots:before,
.related-news .dots:after {
  background-color: #666; }

@media (min-width: 768px) and (max-width: 991px) {
  .related-news .news-item {
    max-width: none; } }

@media (min-width: 768px) and (max-width: 1229px) {
  .single-blog {
    height: 200px; } }

@media (max-width: 991px) {
  .single-blog {
    height: 160px; } }

.news {
  background: url("../images/backgrounds/news-bg.png") center center no-repeat;
  background-size: cover;
  height: 200px; }

.news-content .btn, .news-content .subscripe-box input[type="submit"], .subscripe-box .news-content input[type="submit"] {
  padding: 15px 38px; }

.post-item {
  margin-bottom: 15px; }
  .post-item:last-child {
    margin-bottom: 0; }
    .post-item:last-child .post-item-content {
      border-bottom: none;
      padding-bottom: 0 !important; }

.post-item-content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.post-item-desc {
  font-size: 14px;
  line-height: 1.4285714286; }

.news-wrapper .navigation {
  position: relative; }
  .news-wrapper .navigation .prev-page,
  .news-wrapper .navigation .next-page {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px; }
  .news-wrapper .navigation .prev-page {
    left: 0; }
  .news-wrapper .navigation .next-page {
    right: 0; }

.news-wrapper .screen-reader-text {
  display: none; }

.news-wrapper .page-numbers {
  width: 34px;
  height: 34px;
  font-size: 15px;
  line-height: 2.2666666667; }
  .news-wrapper .page-numbers:not(:last-child) {
    margin-right: 5px; }
  .news-wrapper .page-numbers.current {
    border: 1px solid #caccd0; }

@media (max-width: 1229px) {
  .news-sidebar .btn, .news-sidebar .subscripe-box input[type="submit"], .subscripe-box .news-sidebar input[type="submit"] {
    padding: 15px; }
  .news-sidebar .post-item h3 {
    font-size: 18px; } }

@media (min-width: 992px) and (max-width: 1229px) {
  .news {
    height: 160px; } }

@media (max-width: 991px) {
  .news {
    height: 140px; }
  .news-content .btn, .news-content .subscripe-box input[type="submit"], .subscripe-box .news-content input[type="submit"] {
    font-size: 13px; }
  .post-item .thumb img {
    max-width: 100%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .news-content .btn, .news-content .subscripe-box input[type="submit"], .subscripe-box .news-content input[type="submit"] {
    padding: 10px; }
  .post-item h3 {
    font-size: 19px; } }

@media (max-width: 767px) {
  .post-item .thumb-news {
    margin: 0 auto; }
  .post-item h3 {
    font-size: 18px; }
  .news-wrapper .page-numbers {
    width: 25px;
    height: 25px;
    font-size: 14px;
    line-height: 1.7857142857; } }

.filter-wrapper {
  background-color: #f0f1f3; }

.filter-button {
  width: 100%;
  height: 53px;
  font-size: 18px;
  line-height: 2.9444444444;
  color: #4e5965;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.05);
  padding: 0 15px; }
  @media (max-width: 991px) {
    .filter-button {
      font-size: 15px; } }
  .filter-button:hover {
    background-color: #fff; }
  .filter-button.active {
    background-color: #fff;
    border-right: none;
    border-bottom: 1px solid #f0f1f3; }
  .filter-button span {
    margin-right: 10px; }

.filter .swiper-slide {
  width: auto; }

.slots-filters {
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.filter-label {
  color: rgba(41, 44, 46, 0.5); }

.slots-sort-btn {
  color: #292c2e;
  background-color: #f0f1f3;
  border: 1px solid #ccc;
  padding: 10px 17px; }
  .slots-sort-btn.active {
    background-color: #fff;
    color: rgba(41, 44, 46, 0.7); }

.volatility-btn {
  position: relative;
  background-color: #f0f1f3;
  color: rgba(41, 44, 46, 0.7);
  padding-left: 40px; }
  .volatility-btn.active {
    background-color: #fff;
    color: #292c2e; }
    .volatility-btn.active .radio-btn {
      border-color: #cd0303; }
      .volatility-btn.active .radio-btn:before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        background-color: #cd0303;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto; }

.select-btn {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='6' height='5' viewBox='0 0 6 5'%3e%3cpath fill='%237f7f7f' d='M3.002,5.000 L-0.000,-0.004 L6.005,-0.004 L3.002,5.000 Z'/%3e%3c/svg%3e") calc(100% - 17px) center no-repeat;
  width: 220px; }

.radio-btn {
  position: absolute;
  background-color: #fff;
  width: 18px;
  height: 18px;
  line-height: 46px;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 1px solid #ccc; }

.btn-morer-slots {
  font-size: 12px; }

@media (max-width: 1229px) {
  .select-btn {
    width: 100%; } }

@media (max-width: 767px) {
  .slots-sort-btn {
    padding: 10px 13px; }
  .volatility-btn {
    padding-left: 35px; } }

.btn-complain {
  width: 300px;
  background-color: #b91d10; }
  @media (max-width: 767px) {
    .btn-complain {
      width: 100%; } }
  .btn-complain:hover {
    background-color: #9b0202; }

.how-it-works-left {
  background-color: #f4f4f4;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }
  .how-it-works-left .form-complaints {
    max-width: 350px; }

.form-complaints {
  height: 50px;
  font-size: 14px;
  color: rgba(41, 44, 46, 0.5);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2); }

select.form-complaints {
  color: #292c2e;
  background: #fff url(../images/arrows/select-arrow.svg) calc(100% - 20px) 50% no-repeat; }

.how-it-works-right {
  background-color: #b91d10;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }
  .how-it-works-right ol {
    position: relative;
    counter-reset: items; }
    .how-it-works-right ol:before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      margin: auto;
      border-left: 4px dotted rgba(255, 255, 255, 0.2);
      left: 16px; }
    .how-it-works-right ol li {
      position: relative;
      font-size: 18px;
      padding-left: 50px; }
      @media (min-width: 1230px) {
        .how-it-works-right ol li {
          font-size: 18px; } }
      @media (max-width: 1229px) {
        .how-it-works-right ol li {
          font-size: 16px; } }
      .how-it-works-right ol li:before {
        position: absolute;
        counter-increment: items;
        content: counter(items);
        width: 34px;
        height: 34px;
        border-radius: 50%;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        text-align: center;
        color: #292c2e;
        line-height: 34px;
        background-color: #fff; }
      .how-it-works-right ol li:not(:last-child) {
        margin-bottom: 25px; }

.complaints-step-border {
  border-bottom: 2px solid rgba(0, 0, 0, 0.05); }

.btn-complain-question {
  display: block;
  text-align: left;
  white-space: normal;
  transition: none; }
  @media (min-width: 992px) {
    .btn-complain-question {
      font-size: 18px;
      line-height: 1.3888888889; } }
  @media (max-width: 991px) {
    .btn-complain-question {
      font-size: 16px;
      line-height: 1.375; } }
  .btn-complain-question:before {
    position: absolute;
    content: "";
    width: 28px;
    height: 28px;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 18px;
    color: #171717;
    background: #f4f4f4 url(../images/arrows/question-arrow-gray.svg) center center no-repeat;
    border: 2px solid #f4f4f4;
    border-radius: 50%; }
  .btn-complain-question[aria-expanded=true]:before {
    background: #fff url(../images/arrows/question-arrow-red.svg) center 8px no-repeat;
    border-color: #b91d10; }
  .btn-complain-question:not(:last-child) {
    margin-bottom: 30px; }
  .btn-complain-question .btn-arrow-down {
    border-top-color: #848484; }

.form__radio__indicator {
  width: 24px;
  height: 24px;
  background-color: #f4f4f4; }

.form__radio__label {
  color: #171717; }
  @media (min-width: 992px) {
    .form__radio__label {
      font-size: 18px; } }
  @media (max-width: 991px) {
    .form__radio__label {
      font-size: 16px; } }

input:checked ~ .form__radio__indicator {
  background-color: #67c84d; }
  input:checked ~ .form__radio__indicator:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%; }

.submission-form {
  background-color: #f4f4f4; }
  .submission-form label {
    color: rgba(41, 44, 46, 0.5); }
  .submission-form textarea {
    height: 100px;
    resize: none; }

.form__checkbox__indicator {
  width: 24px;
  height: 24px; }

.form__checkbox__label {
  font-size: 12px;
  color: #292c2e; }

input:checked ~ .form__checkbox__indicator {
  background-color: #67c84d; }
  input:checked ~ .form__checkbox__indicator:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%; }

.submision-sidebar {
  border: 2px solid #f4f4f4; }
  .submision-sidebar li {
    font-size: 12px;
    color: #292c2e;
    background: url(../images/icons/green-item.svg) 0 no-repeat;
    padding: 7px 30px; }

#formsubmission .has-error ~ .text-error {
  display: block; }

#formsubmission .text-error {
  display: none;
  color: #b91d10;
  margin-bottom: 20px; }

.providers__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.providers__thumb {
  height: 60px; }
  .providers__thumb img {
    max-height: 100%;
    width: auto; }

.providers__content p {
  font-size: 14px;
  color: #666666; }

.providers__footer {
  font-size: 14px;
  color: #4b5966;
  background-color: #f2f4f5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.providers__number {
  font-size: 16px; }

.providers__menu ol {
  position: relative;
  counter-reset: items; }
  .providers__menu ol li {
    position: relative;
    color: #4b5966;
    padding-left: 25px; }
    .providers__menu ol li:before {
      position: absolute;
      counter-increment: items;
      content: counter(items);
      width: 16px;
      height: 16px;
      font-size: 10px;
      line-height: 16px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: #4b5966;
      left: 0; }

.providers__sections {
  border-top: 1px solid rgba(0, 0, 0, 0.05); }

.providers__section--content p {
  padding-bottom: 35px; }

.site-footer {
  background: #292c2f url("../images/backgrounds/footer-bg.svg") right bottom no-repeat; }
  .site-footer ul li + li {
    margin-top: 15px; }
  .site-footer .icon-20 {
    font-size: 22px;
    color: #949697; }
  .site-footer .icon-19,
  .site-footer .icon-21 {
    font-size: 20px;
    color: #949697; }

.f-menu {
  color: rgba(255, 255, 255, 0.5); }
  .f-menu a:hover {
    color: #fff; }

.award-circle {
  background-color: #000;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 0; }
  .award-circle .icon-7 {
    font-size: 28px; }
  .award-circle .icon-8 {
    font-size: 33px; }
  .award-circle .icon-9 {
    font-size: 12px; }

.social-footer a:hover .icon {
  color: #fff; }

.social-right {
  display: inline-block;
  opacity: 0.5; }
  .social-right:hover {
    opacity: 0.9; }

.copyright {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  border-top: 2px solid rgba(255, 255, 255, 0.05); }

@media (max-width: 767px) {
  .site-footer ul li + li {
    margin-top: 5px; }
  .footer-award {
    width: 265px;
    margin: 0 auto; } }
