.slot-header {
	@include gradient-radial(#1a232b,#1d262e);

	p {
		display: inline-block;
	}

	.raty-red-stars {
		display: inline-flex;
		width: 185px;
	}
}

.slot-video {
	height: 100%;
	position: relative;

	&:hover {

		img {
			opacity: 1;
		}
	}

	@include media-breakpoint-only(md) {
		.thumb {
			height: 100%;

			img {
				height: 100%;
			}
		}
	} 
}

.slot-cta {
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.btn-slot-play {
	@include element-center;
	@include size(100%);
	z-index: 2;
	background-color: rgba($black, .5);

		&:hover {
			background-color: rgba($black, .1);
		}
}

.slot-play {
	@include size(106px);
	line-height: 106px;
	font-size: 0;
	margin-bottom: 10px;
}

.slot-content {
	background-color: #f2f4f5;
	height: 100%;

	.entry-content {

		h3 {
			font-size: 18px;
		}

		p {
			font-size: 12px;
			color: rgba(#666,.8);
		}
	}

	h5 {
		font-size: 14px;
	}

	a {
		width: 100%;
	}
}

@include media-breakpoint-down(xs) {

		.slot-thumb {
			height: 250px;
		}
}