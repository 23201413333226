.basics-slots {
	background-color: #f4f5f5;
	padding: 40px 30px;
	@include media-breakpoint-down(xs) {
		padding: 20px 10px;
	}
}

.slot-payout {
	background: url("#{$images-background}slot-payout-bg.png") right 15px no-repeat;
}

.bonus-symbols {
	background: url("#{$images-background}bonus-symbols-bg.png") left top no-repeat;
	padding: 15px 0;
	@include media-breakpoint-down(lg) {
		padding: 0;
	}
}

.read-more-box {
	border: 1px solid #e5e6e6;
	box-shadow: 7.5px 13px 25px rgba(4, 5, 5, 0.1);
	padding: 30px;
	@include media-breakpoint-down(md) {
		padding: 15px;
	}
}

.slot-bonus-box {
	@include gradient-x(transparent, #eeeeef, 20%, 100%);
	padding-bottom: 20px;
}

@include media-breakpoint-down(sm) {

	.bonus-symbols {
		background: none;
	}
}

@include media-breakpoint-up(lg) {
	.what-you-need {
		position: relative;

		.row:first-of-type {
			margin-bottom: 150px;
		}

		figure {
			position: absolute;
			top:0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			text-align: right;
		}	
	}
}

