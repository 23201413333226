.game-review {
	padding: 25px 0;

	.entry-content {

		h4 {
			@extend .muli;
			font-size: 15px;
			text-transform: uppercase;
			margin-bottom: 0;
			margin-top: 35px;
		}
	}
}

.slots-table {

	.row {

		&:nth-child(odd) {
			background-color: #eff1f3;
		}

		.col {

			&:nth-child(odd) {
				font-size: 12px;
				text-transform: uppercase;
			}

			&:nth-child(even) {
				@extend .muli-b;
				@extend .text-gray-600;
				text-align: right;
				font-size: 14px;
			}
		}
	}

	p {
		padding: 15px 0;

		span {
			display: inline-block;
			vertical-align: bottom;

			img {
				vertical-align: bottom;
			}
		}
	}
}