@mixin button-variant($background, $border, $active-background: darken($background, 10%), $active-border: darken($border, 10%)) {
  @include color-yiq($background);
  background-color: $background;
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  &:hover {
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow;
    } @else {
      box-shadow: none;
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $background;
    border-color: $border;
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    background-color: $active-background;
    background-image: none; // Remove the gradient for the pressed/active state
    border-color: $active-border;
    @include box-shadow($btn-active-box-shadow);
  }
}

@mixin button-outline-variant($color, $color-hover: #000) {
  @include color-yiq($color);
  background-color: transparent;
  background-image: none;
  border-color: $color;

  @include hover {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }

  &:focus,
  &.focus {
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow;
    } @else {
      box-shadow: none;
    }
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }
}