.btn {
	@extend .text-uppercase;
}

.btn-link {
	font-weight: $btn-font-weight;
	font-size: 13px;
	text-decoration: underline;

		@include hover-focus {
	    text-decoration: underline;
	  }
}

.btn-border {
  color: #666;
  border-color: #f2f2f2;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value, $black);
  }
}

.btn-black {
	@include button-variant($black, $black, $red, $red);
}

.btn-lg {
  @include button-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, $input-btn-font-size-lg, $line-height-lg, $btn-border-radius-md);
}

.btn-md {
  @include button-size($input-btn-padding-y-md, $input-btn-padding-x-md, $font-size-sm, $line-height-sm, $btn-border-radius-md);
}

.btn-sm {
  @include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $input-btn-font-size-sm, $line-height-sm, $btn-border-radius-md);
}

.btn-md-ellipse {
	@extend .btn-md;
	border-radius: 20px;
}

.btn-arrow-down {
	@extend .d-inline-block;
	@include size(0);
	margin-bottom: 2px;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 6px solid transparent;
}

.btn-slide-circle {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	z-index: 3;
	@include size(66px);
	background-color: $white;
	border: 2px solid fade(#010101, 10);

		&:before,
		&:after {
			content: '';
			@include element-center;
			@include size(22px, 5px);
			background-color: #4c4c4c;
			transition: $default-transition;
		}

		&.btn-slide-prev {
			left: 0;

				&:before {
					transform: translate(-2px, 6px) rotate(45deg);
				}

				&:after {
					transform: translate(-2px, -6px) rotate(-45deg);
				}
		}

		&.btn-slide-next {
			right: 0;

				&:before {
					transform: translate(2px, -6px) rotate(45deg);
				}

				&:after {
					transform: translate(2px, 6px) rotate(-45deg);
				}
		}

		&:hover {
			background-color: $red;
			border-color: $red;

				&:before,
				&:after {
					background-color: $white;
				}
		}
}

// .btn-outline-light

@include media-breakpoint-down(xs) {

	.btn-slide-circle {
		@include size(45px);

		&:before,
		&:after {
			@include size(14px, 3px);
		}

		&.btn-slide-prev {
			left: 0;

				&:before {
					transform: translate(-1px, 4px) rotate(45deg);
				}

				&:after {
					transform: translate(-1px, -4px) rotate(-45deg);
				}
		}

		&.btn-slide-next {
			right: 0;

				&:before {
					transform: translate(1px, -4px) rotate(45deg);
				}

				&:after {
					transform: translate(1px, 4px) rotate(-45deg);
				}
		}
	}
}