.subscribe {
	background: #cd0303 url("#{$images-background}subscribe-bg.png") center center no-repeat;

	h4 {
		font-size: 25px;
		font-weight: 400;
	}

	form {
		@extend .row;
		background-color: #d73535;
		@extend .rounded;
	}

	input[type=text] {
		background: url('#{$images-icons}at-subscr.svg') 25px center no-repeat;
		color: $white;
		font-size: 18px;
		@extend .muli-l;
		height: 60px;
		@extend .col-lg;
		@extend .col-12;
		padding-left: 60px;

		&::placeholder {
			color: fade(#fff,70);
		}
	}

	input[type=submit] {
		font-size: 18px;
		color: $red;
		@extend .muli-bl;
		border-radius: 4px;
		background-color: $white;
		text-transform: uppercase;
		padding: 13px 68px;
		@extend .col-12;
		@extend .col-lg-auto;
	}

}

@include media-breakpoint-between(sm, md) {

	.subscribe {
		background-size: 1180px;

		h4 {
			font-size: 22px;
		}

		h3 {
			font-size: 28px;
		}

		input[type=text] {
			font-size: 16px;
		}

		input[type=submit] {
			font-size: 16px;
			padding: 13px 25px;
		}
	}	
}

@include media-breakpoint-down(xs) {

	.subscribe {
		background-size: 900px;

		h4 {
			font-size: 18px;
		}

		h3 {
			font-size: 22px;
		}

		input[type=text] {
			font-size: 14px;
			height: 50px;
		}

		input[type=submit] {
			font-size: 15px;
		}
	}


}
