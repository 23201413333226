.btn-complain  {
	width: 300px;
	background-color: #b91d10;

	@include media-breakpoint-down(xs) {
		width: 100%;
	}

	&:hover {
		background-color: #9b0202;
	}
}