.single-blog {
	height: 255px;

	.published {
		background-color: rgba(#fff,.9);
	}
}

.single-blog-content {

	.entry-content {

		blockquote {
			background: none;
			margin-bottom: 20px;
		}
	}
}

.related-news {

	.dots,
	.dots:before,
	.dots:after {
		background-color: #666;
	}
}

@include media-breakpoint-only(sm) {

	.related-news {

		.news-item {
			max-width: none;
		}
	}
}

@include media-breakpoint-between(sm, md) {

	.single-blog {
		height: 200px;
	}
}

@include media-breakpoint-down(sm) {

	.single-blog {
		height: 160px;
	}
}
