.site-footer {
	// background-color: #292c2f;
	background: #292c2f url("#{$images-background}footer-bg.svg") right bottom no-repeat;

		ul {

				li + li {
					margin-top: 15px;
				}
		}

		.icon-20 {
			font-size: 22px;
			color: #949697;
		}

		.icon-19,,
		.icon-21 {
			font-size: 20px;
			color: #949697;
		}
}

.f-menu {
	color: fade($white, 50);

		a:hover {
			color: $white;
		}
}

.award-circle {
	background-color: $black;
	@include size(60px);
	line-height: 60px;
	font-size: 0;

		.icon-7 {
			font-size: 28px;
		}

		.icon-8 {
			font-size: 33px;
		}

		.icon-9 {
			font-size: 12px;
		}
}

.social-footer {

	a {

		&:hover {

			.icon {
				color: $white;
			}
		}
	}
}

.social-right {
	display: inline-block;
	opacity: 0.5;


		&:hover {
			opacity: 0.9;
		}
}

.copyright {
	font-size: 12px;
	color: fade(#fff,50);
	border-top: 2px solid fade(#fff,5);
}

@include media-breakpoint-down(xs) {

	.site-footer {

		ul {

			li + li {
				margin-top: 5px;
			}
		}
	}

	.footer-award {
		width: 265px;
		margin: 0 auto;
	}
}