.compare-intro {
	background-color: #1b242c;
}

.compare {
	position: relative;
	padding-bottom: 55px;

	&:before {
		position: absolute;
		content: '';
		background-color: #1b242c;
		height: 30px;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
}

.compare-box-wrapper {

	footer {
		background-color: #eff1f3;
	}
}

.compare-top-line {
	height: 10px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.compare-bottom-line {
	height: 10px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.casino-name-box {
	background-color: #eff1f3;
	padding-top: 60px;

	.noc-review {
		position: relative;
		background-color: #f7f8f9;
		border: none;
		padding-bottom: 20px;

		&:hover {
			border: none;
			box-shadow: none;

			.raty-score-sm span {
				background-color: #f7f8f9;
			}
		}

		figure {
			position: absolute;
	    left: 0;
	    right: 0;
	    top: 0;
	    margin: auto;
	    transform: translateY(-50%);
		}
	}
}

.name-box-wrapper {
	width: 210px;
	padding-top: 60px;
	margin: auto;

	.raty-red-stars-sm span {
    height: 14px;
	}

	.raty-score-sm {
		font-size: 16px;
	}
}

.compare-table {

	.compare-row {

		&:nth-child(odd) {
			background-color: #eff1f3;
		}

		&:nth-child(even) {
			background-color: #e3e5e7;
		}
	}
}

.compare-row {

	.stats-value {
		font-size: 20px;
		color: #4b5966;
	}

	p:not(.stats-value) {
		color: #4b5966;
	}
}

.payment-methods-slider {

	.swiper-slide {
		width: auto;
	}

	button {
		padding: 0 5px;
	}
}

.vs {

	h3 {
		color: #4b5966;
	}
}

.vs-circle {
	background: #4b5966 url('#{$images-arrows}arrows-compare.png') center no-repeat;
	width: 59px;
	height: 59px;
}

@include media-breakpoint-only(sm) {

	.games-row {
		width: 180px;
		margin: auto;
	}
}

@include media-breakpoint-down(xs) {

	.compare-box-wrapper {
		max-width: 290px;
	}

	.overflow-auto {
		overflow: auto;
	  white-space: nowrap;
	}
}

