.breadcrumb {
	background-color: #f2f4f5;
	font-size: 0;
	margin-bottom: 0;

	li {
		font-size: 10px;
		line-height: 18px;
		color: $gray-600;

		&:last-of-type {
			color: #000;
		}

		&:not(:last-child) {

			&:after {
		    content: "\002F";
		    display: inline-block;
		    margin: 0 5px;
		    vertical-align: middle;
		    color: #e0e0e0;
			}
		}
	}

	a {
		display: inline;
    vertical-align: middle;
	}

	.breadcrumb-item {

		.home {
			display: inline-block;
			width: 18px;
			height: 18px;
			background: url(../images/logos/breadcrumb-casino-logo.svg) center no-repeat;

			&:hover {
				background: url(../images/logos/breadcrumb-casino-logo-red.svg) center no-repeat;
			}
		}
	}
}

.breadcrumb-item+.breadcrumb-item:before {
	display: none;
}