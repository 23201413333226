.restricted-dialog,
.additions-accepts-dialog,
.additions-restricted-dialog {
	max-width: 1170px;
	margin: 0 auto;
}

.restricted-content,
.additions-accepts-content,
.additions-restricted-content {
	position: relative;
	padding: 25px 30px;
}

.restricted-close {
	position: absolute;
	@include size(20px);
	top: 35px;
	right: 30px;

	&:before,
	&:after {
		content: '';
		@include element-center;
		width: 2px;
		height: 22px;
		background-color: #babcc0;
	}

	&:before {
    transform: rotate(45deg);
  }

  &:after {
   transform: rotate(-45deg);
  }
}

.restricted-sub-title {
	font-size: 18px;
}
