
.hot-bonuses-nav {
  border-bottom: 1px solid #d1d5da;

  .icon {
  	font-size: 30px;
  }

  button {
    position: relative;
    color: #999;

	    &:hover {
	      color: #000;

		      .icon:before {
		        color: #000;
		      }
	    }

	    .icon:before {
	      color: #b0b1b2;
	    }

	    &.active {
	      color: #4b5966;

		      &:before {
		        position: absolute;
		        content: "";
		        left: 0;
		        bottom: 0;
		        width: 100%;
		        height: 4px;
		        background-color: #4b5966;
		      }
		      
		      .icon:before {
		        color: #4b5966;
		      }
	    }
  }
}

.hot-bonuses-nav-item {
  position: relative;
  
	  &:not(:last-child):before {
	    content: "";
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    height: 30px;
	    margin: auto;
	    border-right: 1px solid rgba(75, 89, 102, 0.1);
	  }
}

.bonuses {

  .tr-title {
    color: rgba(75, 89, 102, 0.8);
    font-weight: 400;
  }

  .casino-name {
  
    a {
      font-size: 16px;
	      @include media-breakpoint-down(xs) {
					font-size: 20px;
	      }
    }
    p {
      font-weight: 400;
    }
  }
  .thumb-rhb {
    width: 60px;
    height: 60px;
  }
  .raty-red-stars span {
    height: 11px;
  }

  .bonus-gray {
    display: inline-block;
    width: 130px;
    box-sizing: content-box;
    position: relative;
    background: #f6f7f7;
    color: #4b5966;
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-bottom: 10px solid #fff;
      border-left: 65px solid transparent;
      border-right: 65px solid transparent;
    }

    p {
      padding: 10px 15px 15px;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.5;
    }
  }
  .casino-highlights {

    li {
      font-size: 10px;
      padding: 5px 0 5px 25px;
      background: url('#{$images-icons}green-item.svg') 0 no-repeat;
    }
  }

  .casino-buttons {

    .btn {
      font-size: 14px;
    }

  	.btn-link:not(:hover) {
		  color: rgba(51, 51, 51, 0.7);
		}
  }
}

.tr-casino {

		&:not(:last-child) {
  		border-bottom: 1px solid #edeeef;
  	}

  	> .row {
		  margin-left: -13px;
		  margin-right: -13px;

				> .col-12 {
				  padding-left: 13px;
				  padding-right: 13px;
				}
		}
}

.rhbs-header {
	border-bottom: 1px solid fade($black, 10%);
}

.rhb-bonus {
	position: relative;
	background-color: #4b5966;
	margin-right: -20px;

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 100%;
		}

		&:before {
			width: 10px;
			top: 0;
			bottom: 0;
			background-color: inherit;
		}

		&:after {
			top: 100%;
			@include size(0);
			border-top: 18px solid #252b31;
			border-right: 10px solid transparent;
		}
}

.rhb-options {
	font-size: 14px;
	color: #4b5966;
	line-height: 1.4;
	background-color: fade(#4b5966, 10%);

		li + li {
			position: relative;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					margin: auto;
					@include size(1px, 19px);
					background-color: fade(#4b5966, 10%);
				}
		}

		.icon {
			vertical-align: middle;
		}

		.icon-4 {
			font-size: 18px;
		}

		.icon-5 {
			font-size: 20px;
		}
}

@include media-breakpoint-up(lg) { 

	  .casino-name {
	    flex: 0 0 255px;
	    max-width: 255px;
  	}

  	.casino-bonus {
	    flex: 0 0 160px;
	    max-width: 160px;
	  }

	  .casino-highlights {
	    flex: 0 0 230px;
	    max-width: 230px;
		}

		.casino-buttons {
	    flex: 0 0 141px;
	    max-width: 141px;
		}
}

@include media-breakpoint-between(sm,md) {

	.bonuses {

		.casino-name {
			flex: 0 0 220px;
    	max-width: 220px;
		}

	  .casino-highlights {
	    flex: 0 0 180px;
	    max-width: 180px;

	    li {
	      padding-left: 20px;
	    }
	  }

	  .casino-bonus {
	    flex: 0 0 130px;
	    max-width: 130px;
	  }

		.casino-buttons {
	    flex: 0 0 141px;
	    max-width: 141px;
		}

	  .bonus-gray {
	  	width: 100px;

	  	&:after {
	      border-left: 50px solid transparent;
	      border-right: 50px solid transparent;
	    }
	  }
	}
}

@include media-breakpoint-down(xs) {

	.bonuses {

		.bonus-gray {
    	width: 100%;
    
	    &:after {
	     display: none;
	    }
		}
	}


	.hot-bonuses-nav {
	 
	  .icon {
	  	font-size: 23px;
	  }
	}

	.hot-bonuses-nav-item {

		.h4 {
			font-size: 14px;
		}
	}

	.casino-bonus {
		text-align: center;
	}

	.casino-bonus,
	.casino-highlights,
	.casino-buttons {
		margin-top: 15px;
	}
}