
// Images path
// ------------
$images: '../images/';
$images-arrows: '#{$images}arrows/';
$images-background: '#{$images}backgrounds/';
$images-buttons: '#{$images}buttons/';
$images-examples: '#{$images}examples/';
$images-icons: '#{$images}icons/';
$images-img: '#{$images}img/';
$images-logos: '#{$images}logos/';
$images-separetor: '#{$images}separetors/';
$images-shadows: '#{$images}shadows/';
$images-sprites: '#{$images}sprites/';
$images-svg: '#{$images}svg/';

$font-path: "../fonts/" !default;

$default-transition: 250ms ease-in-out !default;

$bg-colors: (
	e0e3e7 : #e0e3e7,
	e6e6e6 : #e6e6e6,
);

$fonts: (
	muli-l: (
		family: ('Muli', sans-serif),
		weight: 300,
		style: normal
	),
	muli-l-it: (
		family: ('Muli', sans-serif),
		weight: 300,
		style: italic
	),
	muli: (
		family: ('Muli', sans-serif),
		weight: 400,
		style: normal
	),
	muli-it: (
		family: ('Muli', sans-serif),
		weight: 400,
		style: italic
	),
		muli-sb: (
		family: ('Muli', sans-serif),
		weight: 600,
		style: normal
	),
	muli-b: (
		family: ('Muli', sans-serif),
		weight: 700,
		style: normal
	),
		muli-b-it: (
		family: ('Muli', sans-serif),
		weight: 700,
		style: italic
	),
	muli-ex-b: (
		family: ('Muli', sans-serif),
		weight: 800,
		style: normal
	),
	muli-bl: (
		family: ('Muli', sans-serif),
		weight: 900,
		style: normal
	),
	muli-bl-it: (
		family: ('Muli', sans-serif),
		weight: 900,
		style: italic
	),
	poppins-m: (
		family: ('Poppins', sans-serif),
		weight: 500,
		style: normal
	)
);

$font-weight-black: 900;
