.payouts-row {
	margin-right: -8px;
	margin-left: -8px;
}

.payouts-col {
	padding-right: 8px;
	padding-left: 8px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.payouts-box {
	margin-bottom: 16px;
	border: 1px solid #e0e3e7;

	.thumb {
		width: 96px;
		height: 96px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	li {
		@extend .muli-b;
		padding-left: 20px;
		height: (96px / 3);

		&:nth-child(odd) {
			background-color: #fff;
			border-radius: 4px;
		}
	}

	p {
		padding: 0 7px;
		font-size: 12px;
	}
}

@include media-breakpoint-up(lg) {
	
		.payouts-col {
			flex: 0 0 (100% / 5);
			max-width: (100% / 5);
		}
}