.listing-intro {

	figure {

	}
}

@include media-breakpoint-up(lg) {

	.listing-intro {
		padding: 80px 0 120px;
	}
}

@include media-breakpoint-down(lg) {

	.listing-intro {
		padding: 50px 0;
	}
}

@include media-breakpoint-down(xs) {

	.listing-intro {
		padding: 20px 0;

		figure {
			text-align: center;
			margin-top: 10px;

			img {
				max-height: 50px;
			}
		}
	}
}