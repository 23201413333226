.casino-score {
	background-color: #eff1f3;
	padding: 30px 0;

	p {
		font-size: 16px;
		line-height: (18/16);
		color: #666;
	}

	>.row {

		>.col-sm:nth-child(1),
		>.col-sm:nth-child(2)  {
			border-right: 1px solid #d7d9db;
		}

		>.col-sm {

			.col-auto {
				padding-left: 60px;
			}
		}
	}
}

@include media-breakpoint-down(md) {

	.casino-score {


		>.row {

			>.col-sm {

				.col-auto {
					padding-left: 25px;
				}
			}
		}
	}
}

@include media-breakpoint-down(xs) {

	.casino-score {

		.btn {
			width: 100%;
		}


		>.row {

			>.col-sm:nth-child(1),
			>.col-sm:nth-child(2)  {
				border-right: none;
			}

			>.col-sm {

				.col-auto {
					padding-left: 10px;
				}
			}
		}
	}
}