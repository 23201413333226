.subscripe-box {
	border: 1px dashed $red;
	background-color: #f2f2f2;
	box-shadow: inset 0 0 0 8px fade($white, 75);

		form {
			@extend .row;
			margin: 0;
			@extend .rounded;
			background-color: #fff;
		}

		input[type="text"],
		input[type="email"] {
			@extend .col-lg;
			@extend .col-12;
			@extend .muli-l;
			@extend .pl-40;
			color: $black;
			font-size: 12px;
			height: 40px;
			box-shadow: 0 5px 15px fade(#010101, 10);
			background: url('#{$images-icons}at.svg') 15px center no-repeat;

				&::-placeholder {
					color: $gray-600;
				}
		}

		input[type="submit"] {
			@extend .col-12;
			@extend .col-lg-auto;
			@extend .btn;
			@extend .btn-danger;
			@extend .btn-md;
		}
}

@include media-breakpoint-up(lg) {

		.subscripe-box {
			width: 334px;
		}
}

@include media-breakpoint-down(sm) {

	.subscripe-box {
		position: static !important;
		max-width: 334px;
		margin: 0 auto;

			&.is_stuck + div {
				display: none !important;
			}
	}
}