
.noc-section {
	background-color: #f7f7f7;

		.swiper-slide {
			width: ( 100% / 5 );
		}
}

.noc-slider-wrap {
  overflow: hidden;

  @include media-breakpoint-up(sm) {
  	padding-right: ( 100% / 5 );

	  &:after {
	    content: "";
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    z-index: 2;
	    width: 200px;
	    background-image: linear-gradient(90deg, hsla(0, 0%, 97%, 0.2) 0, #f7f7f7 80%);
	    background-repeat: repeat-x;
	  }
  }

}

.noc-slider {
  overflow: visible;
}

.noc-review {
	position: relative;
	border: 1px solid transparent;
	font-size: 12px;
	transition: $default-transition;

		.entry-content {
			overflow: hidden;
			height: 76px;
		}

		.btn-link {
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 2;
			opacity: 0;
			background-color: #fff;
		}

		.raty-score-sm span {
			background-color: #f7f7f7;
		}

		.info {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 3;
			@include size(28px);
			line-height: 28px;
			color: $black;
			background-color: fade($black, 10);

				&:hover {
					color: $white;
					background-color: $red;
				}
		}

		&:hover {
			border: 1px solid #ccc;
			box-shadow: 0 15px 30px fade(#010101, 10);
			background-color: #fff;

				.btn-link {
					opacity: 1;
				}

				.raty-score-sm span {
					background-color: #fff;
				}
		}
}

@include media-breakpoint-between(sm, md) {

	.noc-section {

			.swiper-slide {
				width: ( 100% / 4 );
			}
	}

	.noc-slider-wrap {
	  padding-right: ( 100% / 4 );
	}
}

// @include media-breakpoint-down(xs) {

// 	.noc-section {

// 			.swiper-slide {
// 				width: ( 100% / 2 );
// 			}
// 	}

// 	.noc-slider-wrap {
// 	  padding-right: ( 100% / 2 );
// 	}
// }