.casino-boxes {
	display: flex;
	margin-right: -8px;
  margin-left: -8px;
}

.casino-box-wrapper {
	position: relative;
	margin-bottom: 16px;
  padding-right: 8px;
  padding-left: 8px;
}

.casino-box {
	height: 100%;
	border: 3px solid #eff1f3;
	padding: 23px 20px;

	figure {
		height: 102px;
	}

	p {
		font-size: 12px;
		color: $gray-600;
	}
}

@include media-breakpoint-up(md) {
	
		.casino-box-wrapper {
			flex: 0 0 20%;
		  max-width: 20%;
		}
}

@include media-breakpoint-down(md) {

	.casino-boxes {

		h3 {
			font-size: 20px;
		}
	}

	.casino-box {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@include media-breakpoint-only(sm) {
	
		.casino-box-wrapper {
			flex: 0 0 (100% / 3);
		  max-width: (100% / 3);
		}
}

@include media-breakpoint-down(xs) {
	
		.casino-box-wrapper {
			flex: 0 0 100%;
		  max-width: 100%;
		}
}