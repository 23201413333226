.rank-num {
	position: relative;
}

.best-live-casinos {

	.tr-casino:nth-child(2) {

		.rank-num-icon {
			color: $white;
			background: #fff url('#{$images-icons}rank-icon-first.svg') center no-repeat;
		}
	}
}

.rank-num-icon {
	position: absolute;
  top: 5px;
  left: -40px;
  margin: auto;
  width: 40px;
  height: 40px;
  color: $red;
  font-size: 9px;
  text-align: center;
  line-height: 40px;
  @extend .muli-ex-b;
  @extend .rounded-circle;
  background: #fff url('#{$images-icons}rank-icon-all.svg') center no-repeat;
}

@include media-breakpoint-up(lg) { 

	.best-live-casinos {

		.casino-name {
	    flex: 0 0 269px;
	    max-width: 269px;
	  }

	  .casino-buttons {
	  	flex: 0 0 156px;
	    max-width: 156px;
	  }
	}
}

@include media-breakpoint-down(xs) { 

	.rank-num-icon {
		top: -4px;
		bottom: auto;
		left: -30px;
		box-shadow: 0 4px 4px rgba(#000, .4);
	}
}
