.site-header {
	position: relative;
	z-index: 777;
}

.logo {
	line-height: 1;
}

.site-nav {
	font-size: 0;
}

.main-menu {

		> li {
			position: relative;
			float: left;

			&:hover, &.hover {

				a {

					&:before {
						transform: scaleX(1);
					}
				}
			}

			&.hover {
				color: $red;

				.sub-menu,
				.menu-two-col {
					display: block;
				}
			}

				> a {
					position: relative;
					padding-left: rem(15);
					padding-right: rem(15);
					color: $gray-700;
					font-weight: $font-weight-black;
					font-size: 13px;
					padding-top: 27px;
					padding-bottom: 27px;
					@include media-breakpoint-only(md) {
						padding-top: 15px;
						padding-bottom: 15px;
					}


					&:before {
				    content: "";
				    position: absolute;
				    right: 0;
				    bottom: 3px;
				    left: 0;
				    height: 5px;
				    background-color: $red;
				    transform: scaleX(0);
				    transition: .25s ease-in-out;
				  }
				}

				

				&:first-child {

						> a {
							padding-left: 0;
						}
				}
		}

		a {
			display: block;

				&:hover {
					color: $red;
				}
		}
}

.sub-menu {
	display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  text-align: left;
  background-color: $red;
  text-transform: none;
  z-index: 1;
  padding: 20px;
		@include media-breakpoint-down(xs) {
			position: static;
		}

  li {
  	margin-bottom: 20px;

  	&:last-of-type {
  		margin-bottom: 0;
  	}

  	a {
  		font-size: 14px;
  		color: $white;
  		white-space: nowrap;
  		line-height: 1;

  		&:hover {
  			color: #000;
  		}
  	}
  }
}

.menu-two-col {
	display: none;
	position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  text-align: left;
  background-color: $red;
  text-transform: none;
  z-index: 1;
  padding: 15px;
  	@include media-breakpoint-up(sm) {
			width: 380px;
		}
		@include media-breakpoint-down(xs) {
			position: static;
		}

	li {
		margin-bottom: 20px;

  	&:last-of-type {
  		margin-bottom: 0;
  	}

		a {
			font-size: 14px;
			color: $white;
  		white-space: nowrap;
  		line-height: 1;

  		&:hover {
  			color: #000;
  		}
		}
	}
}

.menu-item-title {
	font-size: 13px;
	@extend .muli-b;
	margin-bottom: 20px;
}

.btn-tgl-search:not(.btn) {
	color: $gray-700;
	font-weight: 900;
	font-size: 13px;

		.icon {
			font-size: 16px;
		}
}

.logging-in {
	color: $gray-600;
	border-left: 1px solid fade($black, 10);

		.icon {
			font-size: 16px;
			color: fade($black, 30);
		}

		a {

				&:hover {

						.icon {
							color: inherit;
						}
				}
		}
}

.search-form {
	top: 0;
	right: -1px;
	position: absolute;
	z-index: 2;

		&:not(.opened) {
			display: none;
		}

		input {
			height: 40px;
			color: $gray-700;
			border: 1px solid fade($black, 10%);
			background-color: $white;

				&::placeholder {
					color: $gray-700;
				}

				&:focus,
				&:valid {
					border-color: fade($black, 20%);
				}

				&:valid {
					background-color: #e6e6e6;

						+ .icon {
							color: $gray-700;
						}

						~ .btn-danger {
							display: block;
						}

						~ .btn-secondary {
							display: none;
						}
				}
		}

		.icon,
		button {
			position: absolute;
			top: 0;
			z-index: 2;
		}

		.icon {
			left: 0;
			@include size(50px, 40px);
			color: fade($gray-700, 50%);
			line-height: 40px;
		}

		button {
			right: 0;
		}

		.btn-danger {
			display: none;
		}
}

@include media-breakpoint-up(lg) {

	.search-form {

			input {
				width: 665px;
				padding-right: 145px;
			}

			button {
				width: 124px;
			}
	}
}

@include media-breakpoint-down(md) {


.main-menu {

	> li {

		> a {
			padding-left: 8px;
			padding-right: 8px;
		}
	}
}

	.search-form {

		input {
			width: 365px;
			padding-right: 145px;
		}

		button {
			width: 90px;
		}
	}

}

@include media-breakpoint-down(xs) {

	.main-menu {

		> li {
			float: none;

			> a {
				font-size: 16px;
				padding: 5px 0;
			}
		}
	}

	.button-menu {
		@include button-menu(40px, 24px, 4px, 8px, #292c2f, $red, 2px);
	}

	.site-nav {
		display: none;
	}

	.logging-in {
		border-left: 0;

		.icon {
			font-size: 19px;
		}
	}

	.btn-tgl-search:not(.btn) {

		.icon {
			font-size: 19px;
		}
	}

	.search-form {
	  top: 100%;
    right: 0;
    width: 100vw;
  }

  .position-st {
  	position: static;
  }
}