.sticky-headers-wrapper {
	opacity: 0;
	transform: translateY(-100%);
	transition: opacity 250ms ease-in-out, transform 400ms ease-in-out;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
}


.sticky-headers {
	background-color: #171717;
	padding: 5px 10px;

	.raty-red-stars {
		width: 150px;
		display: inline-flex;

		span {
			font-size: 12px;
		}
	}

	.btn {
		font-size: 14px;
		padding: 11px 26px;
	}

	p {
		font-size: 15px;
		@include media-breakpoint-up(lg) {
			font-size: 18px;
		}

		@include media-breakpoint-down(xs) {
			font-size: 13px;
		}

		span {
			font-size: 12px;
			color: #c8c8c8;
		}
	}
}

.sticky-header-show {
	opacity: 1;
	transform: translateY(0);
}


@include media-breakpoint-down(xs) {

	.thumb-sticky-headers {
		width: 40px;
		height: 40px;
	}

	.sticky-headers {

		.btn {
			padding: 11px 19px;
		}
	}
}