.bonuses-live-games {

	.casino-name {
		flex: 0 0 225px;
	  max-width: 225px;
	}

	.casino-live-tables {
	  color: #4b5966;

	  h3 {
	  	line-height: 1;
	  }

	  p {
	  	line-height: 1;
	  }
	}

	.casino-highlights {
		flex: 0 0 130px;
	  max-width: 130px;

	  li {
	  	@extend .muli-b;
	  	line-height: 1;
	  	color: #4b5966;
	  }
	}
}

@include media-breakpoint-up(lg) {

	.bonuses-live-games {

		.casino-bonus {
			flex: 0 0 170px;
		  max-width: 170px;
		  padding-left: 20px;
		  padding-right: 20px;

		  .bonus-gray {

				p {
					padding-left: 30px;
					padding-right: 30px; 
				}
			}
		}

		.casino-live-tables {
			flex: 0 0 135px;
		  max-width: 135px;

			h3 {
				font-size: 32px;
			}
		}
	}
} 


@include media-breakpoint-between(sm,md) {

	.bonuses-live-games {

		.casino-bonus {
			flex: 0 0 127px;
		  max-width: 127px;
		}

		.casino-live-tables {
			flex: 0 0 65px;
		  max-width: 65px;

			h3 {
				font-size: 28px;
			}
		}

		.casino-highlights {
	    flex: 0 0 114px;
	    max-width: 114px;
	  }
	}
}

@include media-breakpoint-down(sm) {

	.live-games {

		.guides-row {

			.col-12 {
				margin-bottom: 10px;
			}
		}
	}

	.casino-live-tables {
		display: block;
	}
}