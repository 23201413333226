.bottom-banner {
	background: #b81c0f url("#{$images-background}bottom-banner.svg") 86% bottom no-repeat;
	@include media-breakpoint-down(md) {
		background-position: right bottom;
	}

	h3 {
		line-height: 1;
		font-size: 25px;

		@include media-breakpoint-up(md) {
			font-size: 32px;
		}
	}

	h4 {
		@include media-breakpoint-down(md) {
			font-size: 16px;
		}
	}

	@include media-breakpoint-down(xs) {
		.thumb {

			img {
				max-width: 100%;
			}
		}
	}
}