.faq {

	.collapse.show {
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.collapse-border {
		border: 1px solid #ccc;
		border-top: 0;
		border-bottom-left-radius: 5px;
  	border-bottom-right-radius: 5px;
  	// box-shadow: inset 0 15px 30px fade(#010101, 10);

	}

	.entry-content {
		border-top: 2px solid #f2f2f2;

		p {
			color: #666;

			&:not(:last-child) {
				margin-bottom: 30px;
			}
		}
	}
}

.btn-accordion {
	display: block;
	position: relative;
	width: 100%;
	min-height: 66px;
	font-size: 20px;
	text-align: left;
	color: #4b5966;
	background-color: #f2f2f2;
	border: 1px solid transparent;
	border-bottom: 0;
	border-radius: 5px;
	transition-delay: 250ms;
	
	&:not(:first-child) {
		margin-top: 15px;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 20px;
		margin: auto;
		@include size(14px, 3px);
		background-color: #c0c4c8;
	}

	&:before {
		transform: translate(-4px, 0) rotate(45deg);
	}

	&:after {
		transform: translate(4px, 0) rotate(-45deg);
	}

	&[aria-expanded="true"] {
		color: #000;
		background-color: #fff;
		border-color: #ccc;
		border-bottom-left-radius: 0;
 		border-bottom-right-radius: 0;
 		transition: none;
		transition-delay: 1ms;

			&:before {
				background-color: #4b5966;
				transform: translate(4px, 0) rotate(45deg);
			}

			&:after {
				background-color: #4b5966;
				transform: translate(-4px, 0) rotate(-45deg);
			}
	}
}


@include media-breakpoint-down(xs) {

	.btn-accordion {
		min-height: 55px;
		padding: 10px 0;
		font-size: 16px;
	}
}