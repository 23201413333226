.how-to-play {

	h1,
	h2 {
		font-size: 45px;
	}

	h4 {
		color: rgba(#fff,0.5);
	}
}

@include media-breakpoint-up(lg) {

	.how-to-play {
		padding: 165px 0 180px;
	}
}

@include media-breakpoint-only(md) {

	.how-to-play {
		padding: 70px 0;

		h1,
		h2 {
			font-size: 40px;
		}
	}
}

@include media-breakpoint-only(sm) {

	.how-to-play {

		h1,
		h2 {
			font-size: 35px;
		}
	}
}

@include media-breakpoint-down(sm) {

	.how-to-play {
		padding: 40px 0;

		h4 {
			font-size: 16px;
		}
	}
}

@include media-breakpoint-down(xs) {

	.how-to-play {

		h1,
		h2 {
			font-size: 30px;
		}
	}
}