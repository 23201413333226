.online-slots-intro {

	h1 span {
		@extend .muli;
	}

	h4 {
		color: rgba(#fff,0.5);
	}
}

@include media-breakpoint-up(lg) {

	.online-slots-intro {
		height: 580px;

		h1 {
			font-size: 45px;
		}
	}
}

@include media-breakpoint-only(md) {

	.online-slots-intro {
		height: 370px;
	}
}

@include media-breakpoint-only(sm) {

	.online-slots-intro {
		height: 290px;
	}
}

@include media-breakpoint-down(xs) {

	.online-slots-intro {
		height: 260px;
	}
}