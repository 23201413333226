.raty-red-stars {
	font-size: 0;
	text-align: center;

		span {
			height: 16px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

				&.star-red-on {
					background-image: url("#{$images-icons}star-red-on.svg");
				}

				&.star-red-off {
					background-image: url("#{$images-icons}star-red-off.svg");
				}

				&.star-red-half {
					background-image: url("#{$images-icons}star-red-half.svg");
				}

				&.star-white-on,
				 .star-white-gray-on,{
					background-image: url("#{$images-icons}star-white-on.svg");
				}

				&.star-white-off {
					background-image: url("#{$images-icons}star-white-off.svg");
				}

				&.star-white-half {
					background-image: url("#{$images-icons}star-white-half.svg");
				}

				&.star-white-gray-off {
					background-image: url("#{$images-icons}star-white-off-gray.svg");
				}

				&.star-white-gray-half {
					background-image: url("#{$images-icons}star-white-half-gray.svg");
				}
		}
}

.raty-score-sm {
	position: relative;
	font-size: 14px;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 50%;
			box-shadow: inset 0 -1px 0 fade($black, 10);
		}

		span {
			position: relative;
			z-index: 2;
		}
}

.raty-red-stars-sm {

		span {
			height: 12px;
		}
}