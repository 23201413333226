.review-section-wrapper {
	max-width: 1240px;
	margin: 0 auto;
	@include media-breakpoint-up(sm) {
		padding: 30px 35px 0;
	}

	@include media-breakpoint-down(xs) {
		padding: 15px 15px 0;
	}
}

.review-section {
	@include gradient-y(rgba(#000,0.15), transparent, 0%, 100%);

	@include media-breakpoint-up(sm) {
		padding: 30px 15px;
	}

	@include media-breakpoint-down(xs) {
		padding: 15px 10px;
	}

	.raty-red-stars {
		width: 180px;
	}

	h4 {
		color: #d9d9d9;
	}

	.thumb {
		border: 5px solid #fff;
	}

	.btn {
		
		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}
}

.review-nav ul {
	font-size: 0;
	background-color: rgba(#fff,0.1);
	@include media-breakpoint-up(sm) {
		margin-top: rem(35);
	}

	@include media-breakpoint-down(xs) {
		margin-top: 20px;
	}

	li {
		position: relative;
		display: inline-block;
		font-size: 0;
		color: rgba(#fff,.6);
		text-transform: uppercase;

		&:not(:last-child):after {
			position: absolute;
			content: '';
			width: 2px;
			height: 25px;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: rgba(#fff,0.1);
			margin: auto;

		}
	}

	a {
		position: relative;
		display: block;
		font-size: 14px;
		line-height: 35px;

		@include media-breakpoint-up(sm) {
			padding: 10px rem(66);
		}
		@include media-breakpoint-down(xs) {
			padding: 10px 25px 10px 15px;
		}
	}

	li:not(.flexMenu-viewMore) > a {
		position: relative;

			&:hover {
					color: rgba(#000,.9);
					background-color: #fff;
					border-radius: 4px;

					&:after {
						border-top-color: rgba(#000,.9);
					}
				}

				&:after {
					position: absolute;
					content: '';
					width: 0;
		      height: 0;
		      top: 0;
		      bottom: 0;
		      margin: auto;
		      border-left: 3px solid transparent;
		      border-right: 3px solid transparent;
		      border-top: 6px solid rgba(#fff,.5);
		      @include media-breakpoint-up(sm) {
		      	right: 26%;
					}
					@include media-breakpoint-down(xs) {
						right: 12px;
					}
				}
	}

	li.flexMenu-viewMore {
		position: relative;
		
		> a {
			
			background: url(../images/svg/more-dots.svg) center no-repeat;
			@include media-breakpoint-up(sm) {
				padding-left: 45px;
				padding-right: 45px;
			}
			@include media-breakpoint-down(xs) {
				padding-left: 25px;
				padding-right: 25px;
			}
		}
	}

	.flexMenu-popup {
		position: relative;
		background-color: #fff;
		margin-top: 0;
		right: 0;
		box-shadow: 0 0 6px fade(#010101, 10);
		z-index: 1;

		&:before {
			position: absolute;
			content: '';
			top: -11px;
			right: 35px;
			width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 11px solid #fff;
		}

		li {
			display:block;
			border-right: none;

			&:not(:last-child) {
				border-bottom: 1px solid #f2f2f2;
			}

			a {
				color: rgba(#000,.9);

				&:hover {
					color: $red;

					&:after {
						border-top-color: $red;
					}
				}

				&:after {
					border-top-color: rgba(#000,.9);
				}
			}
		}
	}
}

.players-from {
	font-size: 10px;
	background-color: rgba(#000,.5);
	border-radius: 20px;
	line-height: 1;
	padding: 4px 12px 4px 5px;
}

.country-flag {
	@include size(18px);
	margin-right: 11px;
}



@include media-breakpoint-down(md) {

	.casino-review-name {
		font-size: 28px;
	}
}