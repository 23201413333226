.look-slider {

	.thumb {
		height: 234px;
		z-index: 2;
		background-color: #000;
	}
}

.look-slider-item {

	&:hover {

		.look-hover {
			transform: scale(1);
			z-index: 3;
		}

		img {
			opacity: .2;
		}
	}
}

.look-hover {
  transform: scale(0);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;

  span {
  	color: rgba(#fff,0.7);
  	text-decoration: underline;
  }
}

.look-slider-wrapper {

	.btn-slide-prev  {
		margin-left: -7%;
	}

	.btn-slide-next {
		margin-right: -7%;
	}
}

.look-pagination {

	.swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
		width: 6px;
		height: 6px;
		background-color: rgba(#000,0.2);
		border-radius: 50%;
		margin: 0 5px;
	}

	.swiper-pagination-bullet-active {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		border: 1px solid #d01010;
	}
}

@include media-breakpoint-down(md) {

	.look-slider-wrapper .btn-slide-next {
	    margin-right: 5px;
	}

	.look-slider-wrapper .btn-slide-prev {
    margin-left: 5px;
	}
}