.text-nowrap {
	white-space: normal !important;
}

.text-underline {
	text-decoration: underline !important;
}

.font-weight-black { font-weight: $font-weight-black; }

@each $color, $value in $grays {
  @include text-emphasis-variant('.text-gray-#{$color}', $value, $red);
}
