.casino-stats {
	color: #4b5966;
	font-size: 12px;

		// .swiper-wrapper {
		// 	justify-content: center;
		// }

		.swiper-slide {
			width: auto;
		}
}

.stats-box {
	width: 195px;
	border-right: 1px solid #e6e6e6;

		&:first-child {
			border-left: 1px solid #e6e6e6;
		}

		> div {
			height: 75px;
		}
}

.stats-value {
	font-size: 24px;
	line-height: 1;
	font-weight: bold;
}