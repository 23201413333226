.hts {
	background: url("#{$images-background}htbg.jpg") center no-repeat;
	background-size: cover;
}

.ht-news {
	position: relative;

		.thumb {
			background-color: $black;

				img {
					opacity: .4;
				}
		}

		a:not(.btn-success):hover {
			color: $green;
		}

		.title-limit {
			max-height: 100%;
			overflow: hidden;

				&.h2 {
					max-height: 60%;
				}
		}
}

.htn-content,
.htn-actions {
	@include element-center;
}

.htn-content {
	z-index: 2;
}

.htn-actions {
	z-index: 3;
	visibility: hidden;
	opacity: 0;
	background-color: fade($black, 90);
	transition: $default-transition;

		.ht-news:hover & {
			visibility: visible;
			opacity: 1;
		}
}


.mpcasinos {
	overflow: hidden;


		a:not(.thrills) {
			position: relative;
	    font-size: 12px;
	    padding-top: 15px;
	    padding-bottom: 15px;
	    border-bottom: 1px solid rgb(207, 207, 207);

				&:hover {
					background-color: $white;

						.btn-black {
							display: block;
						}
				}
		}

		.raty-red-stars {
	    padding-right: 45px;
	    padding-left: 10px;
		}

		.btn-black {
	    display: none;
	    position: absolute;
	    right: 12px;
		}
}

@include media-breakpoint-down(md) {

	.ht-news {

		.title-limit {
			font-size: 16px;
		}
	}

	.mpcasinos {

		h5 {
			font-size: 13px;
		}
	}
}

@include media-breakpoint-down(xs) {

	.mpcasinos {
		max-width: 282px;
		margin: 0 auto;
	}
}