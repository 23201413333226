.guides-row {
  margin-left: -5px;
  margin-right: -5px;

    .col-12 {
      padding-left: 5px;
      padding-right: 5px;
    }
}

.guides-box {
  padding: 20px 15px;
  background-color: #f2f2f2;
  height: 100%;

    &:hover {
      background-color: #fff;
      box-shadow: inset 0 0 0 1px #ccc, 0 15px 30px rgba(1, 1, 1, 0.1);
    }
}

.guides-box-img {
  height: 91px;
}