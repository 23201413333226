.video-poker-rules {

	.rules-content {
		background: url("#{$images-background}video-poker.png") right center no-repeat;
		background-size: cover;
	}

	table {
		width: 100%;
		border-bottom: 2px solid rgba(#000,.05);
		margin-bottom: 15px;

		thead {
			background-color: #fcf2f2;

			td {
				color: #ce0a0a;
				text-transform: uppercase;
				@extend .muli-ex-b;
			}
		}

		tbody {

			tr:nth-child(even) {
				background-color: #f2f2f2;
			}
		}

		td {
			line-height: 1;
			color: #333;
			padding: 20px 30px;
			@include media-breakpoint-down(md) {
				padding: 10px;
			}
		}
	}
}

.table-info {
	font-size: 13px;
	color: rgba(#000,.5);
}

.video-poker-tips {

	.red-num {
		position: absolute;
		left: 0;
		@include size(36px);
		line-height: 36px;
	}

	figure {
		height: 117px;
	}
}

.poker-tips-title {
	position: relative;
	font-size: 22px;
	padding-bottom: 25px;
	@include media-breakpoint-down(xs) {
		padding-bottom: 10px;
	}


	&:after {
		position: absolute;
		content: '';
		width: 70px;
		height: 3px;
		background-color: $red;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
}


@include media-breakpoint-down(sm) {
	.video-poker-tips {

		.red-num {
			@include size(30px);
			line-height: 30px;
		}

		figure {
			height: 100px;
		}
	}
}

@include media-breakpoint-down(xs) {
	.video-poker-tips {

		.red-num {
			position: relative;
		}

		.col-12:not(:last-child) {
			margin-bottom: 20px;
		}
	}
}
