.casino-guts-title {
	font-size: 18px;
}

.casino-guts-table {

	.row{

		&:nth-child(even) {
			background-color: #eff1f3;

			p {
				padding: 11px 0;
			}
		}
	}

	p {
		padding: 15px 0;
	}
}

.pros {
	border: 3px solid #33cc33;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;

	.casino-guts-title {
		background: url(../images/icons/green-item-big.svg) center left no-repeat;
	}

	li {
		color: #666;

		&:before {
			content: '+';
		}
	}
}

.pros, 
.coins {
	height: 100%;

	.casino-guts-title {
		margin-bottom: 20px;
		padding-left: 30px;
	}

	li {
		position: relative;
		font-size: 14px;
		line-height: (20/14);
		padding-left: 35px;

		&:before {
			position: absolute;
			top: 0;
			left: 6px;
			@extend .muli-bl;
			color: rgba(#000,.25);
			font-size: 18px;
			line-height: 1;
		}

		&:not(:last-child) {
			margin-bottom: 13px;
		}
	}
}

.coins {
	background-color: #f2f4f5;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;

	.casino-guts-title {
		color: rgba(#333,0.8);
		background: url(../images/icons/gray-item.svg) left center no-repeat;
	}

	li {
		color: rgba(#666,.8);

		&:before {
			content: '-';
		}
	}
}

.deposit-languages,
.deposit-withdraw {

	.btn {
		padding: 0 5px 5px 0;
	}
}

.languages {

	.btn {
		padding: 0 7px 10px 0;
	}
}

@include media-breakpoint-down(md) {

	.pros, 
	.coins {
		margin-top: 20px;
	}
}

@include media-breakpoint-down(xs) {
	.coins {
		margin-top: 40px;
	}
}
