.providers {

	&__header {
		border-bottom: 1px solid rgba(#000,.05);
	}

	&__thumb {
		height: 60px;

		img {
			max-height: 100%;
    	width: auto;
		}
	}

	&__content {

		p {
			font-size: 14px;
			color: $gray-600;
		}
	}

	&__footer {
		font-size: 14px;
		color: #4b5966;
		background-color: #f2f4f5;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	&__number {
		font-size: 16px;
	}

	&__menu {

		ol {
			position: relative;
			counter-reset: items;

			li {
				position: relative;
				color: #4b5966;
				padding-left: 25px;

				&:before {
					position: absolute;
					counter-increment: items;
					content: counter(items);
					width: 16px;
					height: 16px;
					font-size: 10px;
					line-height: 16px;
					top: 0;
					bottom: 0;
					margin: auto;
					background-color: #4b5966;
					@extend .rounded-circle;
					@extend .text-center;
					@extend .muli-b;
					@extend .text-white;
					left: 0;
				}
			}
		}
	}

	&__sections {
		border-top: 1px solid rgba(#000,.05);
	}

	&__section--content {

		p {
			padding-bottom: 35px;
		}
	}
}