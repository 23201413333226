
@function rem($pixels, $context: 16) {
  @return ($pixels / $context) * 1rem;
}

@function fade($color, $opacity) {
  @return rgba($color, ($opacity / 100));
}

// Color contrast
@mixin color-yiq($color) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= 150) {
    color: #fff;
  } @else {
    color: #fff;
  }
}