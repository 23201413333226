.bonus-baner {
	@include media-breakpoint-up(sm) {
		padding: 70px 70px 45px;
	}
}

.bonuses-section,
.games-selection {

	.btn-lg {
		padding-left: 30px;
		padding-right: 30px;
		z-index: 2;
    position: relative;
	}
}

.bg-title {
	position: absolute;
	top: 30px;
	left: 90px;
	font-size: 74px;
	color: #e60505;
	text-shadow: 0 20px 50px #c90303;
	z-index: 1;
}

.bonus-baner-cont {
	position: relative;
	z-index: 2;
	

	a {
		font-size: 10px;
		color: rgba(#fff,0.5);
		text-decoration: underline;

		&:hover {
			color: #fff;
		}
	}
}

@include media-breakpoint-between(sm, md) {

	.bg-title {
		font-size: 60px;
	}

	.bonuses-section,
	.games-selection {

		.btn-lg {
    	font-size: 16px;
    	padding-left: 15px;
			padding-right: 15px;
		}
	}
}

@include media-breakpoint-down(xs) {
	.bonus-baner {
		padding: 30px;
		text-align: center;

			.bg-title {
				font-size: 30px;
				top: 47px;
				left: 0;
				right: 0;
			}

			.btn {
				width: 100%;
				margin-top: 15px;
			}
	}
}