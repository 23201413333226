.slot-nav {
	background-color: rgba(#fff,.10);

	ul {
		font-size: 0;

		li {
			display: inline-block;
			font-size: 14px;
			color: $white;
			text-transform: uppercase;
			@include media-breakpoint-only(sm) {
				font-size: 13px;
			}
			@include media-breakpoint-down(xs) {
				display: block;
				text-align: center;
				border-bottom: 2px solid rgba(#000,.5);

				&:last-of-type {
					border-bottom: none;
				}
			}

			&:last-child {
				 
				 a {

				 	&:after {
				 		display: none;
				 	}
				 }
			}

			span {
				display: inline-block;
				vertical-align: middle;
				padding-right: 15px;
				@include media-breakpoint-only(sm) {
					padding-right: 10px;
				}
			}
		}

		a {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			@extend .muli-bl;
			padding: 17px 70px;
			@include media-breakpoint-only(md) {
				padding: 17px 39px;
			}
			@include media-breakpoint-only(sm) {
				padding: 17px 16px;
			}

			&:after {
				position: absolute;
		    content: "";
		    width: 2px;
		    height: 22px;
		    right: 0;
		    top: 0;
		    bottom: 0;
		    background-color: rgba(#000,.5);
		    margin: auto;

		    @include media-breakpoint-down(xs) {
					display: none;
		    }

			}

			&:hover {
				background-color: $red;
				color: $white;

				&:after {
					display: none;
				}
			}
		}
	}
}

@include media-breakpoint-down(xs) {

		.slot-nav {

				ul {

						a {
							width: 100%;
							padding: 15px;
						}
				}
		}
}