.widget-title {
	background-color: #d8d8da;
}

.widget-menu {
	background-color: #f0f1f3;

		a {
			position: relative;
			display: block;
			padding: 13px 25px 13px 45px;
			font-size: 12px;
			color: #666;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 30px;
					bottom: 0;
					margin: auto;
					@include size(0);
					border-top: 3px solid transparent;
					border-bottom: 3px solid transparent;
					border-left: 6px solid rgba($black, .5);
				}

				&:hover {
					color: $white;
					background-color: $red;

						&:before {
							border-left-color: $white;
						}
				}
		}

		li:not(:last-child) a {

				&:after {
					content: '';
					position: absolute;
					right: 25px;
					bottom: 0;
					left: 25px;
					border-bottom: 1px solid rgba($black, .05);
				}
		}
}