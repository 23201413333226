.error-page {
	border-top: 1px solid rgba(#000,.1);
	padding: 25px 0 75px 0;
	@include media-breakpoint-down(xs) {
		padding-bottom: 25px;
	}

	h1 {
		font-size: 38px;
	}

	p {
		font-size: 18px;
		color: #292c2f;
	}
}

.error-btn {

	.btn {
		width: 220px;
		font-size: 18px;
		padding: 14px 20px;
		text-align: center;
		@include media-breakpoint-down(xs) {
			display: block;
			width: 100%;
		}

	}
}