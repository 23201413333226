.ng-row {
	margin-left: -12px;
	margin-right: -12px;

		> .col,
		> [class*=col-] {
			padding-left: 12px;
			padding-right: 12px;
		}
}

.news-item {
	height: 412px;
	background-color: #f2f2f2;
	transition: $default-transition;

		figure {
			flex: 0 0 173px;
			max-height: 173px;
		}

		.h4 {
			max-height: 78px;
			line-height: 1.4;
			overflow: hidden;
		}

		.entry-content {
			flex-basis: 0;
	    flex-grow: 1;
			max-height: 100%;
			font-size: 14px;
			overflow: hidden;
		}

		&:hover {
			background-color: $white;
			box-shadow: inset 0 0 0 1px #ccc, 0 15px 30px fade(#010101, 10);
		}
}

.published {
	font-size: 12px;

		.icon {
			font-size: 13px;	
		}
}

.load-more-news {

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 50%;
			box-shadow: inset 0 -1px 0 fade($black, 5), 0 1px 0 fade($black, 5);
		}

		span {
			z-index: 2;
		}
}

@include media-breakpoint-down(sm) {

	.news-item {
		max-width: 256px;
		margin: 0 auto;
	}
}