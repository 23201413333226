.play-real-money {
	padding: 30px 0;

	@include media-breakpoint-up(lg) { 

  	.casino-bonus {
	    flex: 0 0 375px;
	    max-width: 375px;
	  }

		.bonus-gray {
	    width: 300px;

	    &:after {
	      right: 0;
	      left: auto;
	      bottom: 0;
	      top: 0;
	      border-right: 13px solid #fff;
	      border-top: 32px solid transparent;
	      border-bottom: 32px solid transparent;
	    }

	    p {
	      padding: 23px 15px;
	    }
	  }

	}

	@include media-breakpoint-only(md) { 

  	.casino-bonus {
	    flex: 0 0 275px;
	    max-width: 275px;
	  }

		.bonus-gray {
	    width: 250px;

	    &:after {
	      right: 0;
	      left: auto;
	      bottom: 0;
	      top: 0;
	      border-right: 13px solid #fff;
	      border-top: 32px solid transparent;
	      border-bottom: 32px solid transparent;
	    }

	    p {
	      padding: 23px 15px;
	    }
	  }

	  .casino-highlights {
	    flex: 0 0 250px;
	    max-width: 250px;
		}

		.casino-buttons {
	    flex: 0 0 171px;
	    max-width: 171px;
		}
	}

	@include media-breakpoint-down(xs) {

  	// .casino-bonus {
	  //   flex: 0 0 300px;
	  //   max-width: 300px;
	  // }

		.bonus-gray {
			margin: 0 auto;
	    width: 300px;
	    max-width: 100%;

	    &:after {
	      right: 0;
	      left: auto;
	      bottom: 0;
	      top: 0;
	      border-right: 13px solid #fff;
	      border-top: 32px solid transparent;
	      border-bottom: 32px solid transparent;
	    }

	    p {
	      padding: 23px 15px;
	    }
	  }
	}


	@include media-breakpoint-up(lg) { 
 		.casino-highlights {
	    flex: 0 0 275px;
	    max-width: 275px;
		}

		.casino-buttons {
	    flex: 0 0 251px;
	    max-width: 251px;
		}
	}
}