.news {
	background: url("#{$images-background}news-bg.png") center center no-repeat;
	background-size: cover;
	height: 200px;
}

.news-content {

	.btn {
		padding: 15px 38px;
	}
}

.post-item {
	margin-bottom: 15px;

	&:last-child {
		margin-bottom: 0;

		.post-item-content {
			border-bottom: none;
			padding-bottom: 0 !important;
		}
	}
}

.post-item-content {
	border-bottom: 1px solid rgba(#000,.1);
}

.post-item-desc {
	font-size: 14px;
	line-height: (20/14);
}

.news-wrapper {

	.navigation {
		position: relative;

		.prev-page,
		.next-page {
			position: absolute;
			background-color: rgba(#000,.2);
			border-radius: 4px;
		}

		.prev-page {
			left: 0;
		}

		.next-page {
			right: 0;
		}
	}

	.screen-reader-text {
		display: none;
	}

	.page-numbers {
		width: 34px;
		height: 34px;
		font-size: 15px;
		line-height: (34/15);

		&:not(:last-child) {
			margin-right: 5px;
		}

		&.current {
			border: 1px solid #caccd0;
		}
	}
}

.news-sidebar {

	@include media-breakpoint-down(md) {

		.btn {
			padding: 15px;
		}

		.post-item {

			h3 {
				font-size: 18px;
			}
		}
	}
}

@include media-breakpoint-only(md) {

	.news {
		height: 160px;
	}
}

@include media-breakpoint-down(sm) {

	.news {
		height: 140px;
	}

	.news-content {

		.btn {
			font-size: 13px;
		}
	}

	.post-item {

		.thumb {

			img {
				max-width: 100%;
			}
		}
	}
}

@include media-breakpoint-only(sm) {

	.news-content {

		.btn {
			padding: 10px;
		}
	}

	.post-item {

		h3 {
			font-size: 19px;
		}
	}
}

@include media-breakpoint-down(xs) {

	.post-item {

		.thumb-news {
			margin: 0 auto;
		}

		h3 {
			font-size: 18px;
		}
	}

	.news-wrapper {

		.page-numbers {
			width: 25px;
			height: 25px;
			font-size: 14px;
			line-height: (25/14);
		}
	}
}

