.quick-links {
	border: 1px solid #cccccc;
	box-shadow: 0 15px 30px fade(#010101, 10);

	h5 {
		background-color: #f6f7f7;
	}

	li {
		border-bottom: 1px solid #e5e5e5;
		font-size: 18px;
		color: #4b5966;

		&:last-of-type {
			border-bottom: 1px solid transparent;
		}
	}

	.icon-14,
	.icon-11,
	.icon-15 {
		font-size: 31px;
	}

	.icon-12 {
		font-size: 27px;
	}

	.icon-13 {
		font-size: 33px;
	}
}

@include media-breakpoint-down(md) {

	.quick-links {

		li {
			font-size: 15px;
		}
	}
}

@include media-breakpoint-down(xs) {

	.quick-links {
		max-width: 270px;
		margin: 0 auto;
	}
}