.entry-content {
	color: $gray-600;

	h1 {
		font-weight: 900;
	}

	h1, h2, h3, h4, h5, h6 {
		color: $gray-800;
	}

	h1, h2, h3, h4, h5, h6, p {
		margin-bottom: rem(20);

			&:last-child {
				margin-bottom: 0;
			}
	}

	ol {
		counter-reset: items;
		margin-bottom: 20px;

		li {
			position: relative;
			padding-left: 35px;

			&:before {
				position: absolute;
				counter-increment: items;
				content: counter(items) ". ";
				font-weight: 700;
				top: 0;
				bottom: 0;
				left: 0;
				margin: auto;
			}
		}
	}

	ul {

		li {
			padding-left: 30px;
			margin-bottom: 30px;
			background: url('#{$images-icons}green-item.svg') 0 5px no-repeat;
		}
	}

	blockquote {
		padding: 50px;
		border: 2px solid #eaeaea;
		border-style: dotted;
		position: relative;
		background: url(../images/icons/quote.png) 20px 20px no-repeat;
		@include media-breakpoint-down(sm) {
			padding: 20px;
		}

		p {
			font-size: 20px;
			@extend .muli-it;
			color: $gray-800;
		}
	}

	table {
		width: 100%;
		border-collapse: collapse;
    border-radius: 4px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #edeeef;

    td, th {
    	padding: 15px;
    }

		thead {

			th {
				font-size: 11px;
				color: #4b5966;
				text-transform: uppercase;

				&:not(:first-child) {
					text-align: center;
				}

				&:first-child {
					padding-left: 30px;
				}
			}
		}

		tbody {

			td {
				text-align: center;
			}
		}

		tr {
			border-bottom: 1px solid #edeeef;
		}


		.play-real-money {
			padding: 0;
		}

		.bonus-gray {
			width: 380px;
		}

		.casino-bonus {
			flex: 0 0 auto;
			max-width: 380px;
			padding: 0;
			margin: 0 auto;
		}

		.casino-buttons {
			padding-right: 10px;
		}

		.casino-name {

			@include media-breakpoint-up(sm) {
				padding-left: 5px;
			}

			.thumb {
				width: 60px;
				height: 60px;
				@include media-breakpoint-down(xs) {
					width: 45px;
					height: 45px;

					img {
						max-width: 100%;
					}
				}
			}

			a {
				color: #333;
			}
		}
	}
}

@include media-breakpoint-down(sm) {

	.entry-content {

		table {
			.bonus-gray {
				width: 100px;
			}
		}
	}

}

@include media-breakpoint-down(md) {

	.casino-name {
		width: 190px;
	}
}

@include media-breakpoint-down(xs) {

	.entry-content {

		.casino-name {
			width: 170px;
		}
		
		>p,
		strong {
			text-align: justify;
		}

		table {
			display: block;
			overflow: auto;

			thead,
			tbody {
				display: table;
				width: 100%;
				min-width: 767px;
				table-layout: fixed;
			}
		}
	}

	h2 {
		text-align: center;
	}
}
