.restricted,
.restricted-content,
.additions-restricted-content,
.casino-bonuses-section {

	.noc-review {
		background-color: #f2f4f5;
		border: none;

		h4 {
			font-size: 18px;
		}

		footer {
			padding: 15px 20px;
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;

			p {
				font-size: 16px;
			}
		}
	}
}

.restricted-title {
	font-size: 16px;
	background: url("#{$images-icons}restricted-i.svg") left center no-repeat;
}

.unavailable {
	font-size: 18px;
	color: rgba(#000,.5);
	padding: 14px 55px;
}

@include media-breakpoint-down(xs) {

	.restricted,
	.restricted-content {

		.noc-review {
			width: 270px;
			margin: 0 auto;
		}
	}
}