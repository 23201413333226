.craps-rules {

	.entry-content {

		h2 {
			@extend .muli-bl;

			span {
				@extend .muli;
			}
		}
	}
}

.rules-border {
	position: relative;
	padding: 35px 0 40px;
	@include media-breakpoint-down(xs) {
		padding: 0;
	}

	&:before {
		position: absolute;
		content: '';
		width: 892px;
		left: -50px;
		top: 0;
		bottom: 0;
		border: 1px solid rgba(#000,.1);
		@extend .rounded;

		@include media-breakpoint-only(md) {
			width: 690px;
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	
}

.rules-content {
	background: url("#{$images-background}craps-rules-bg.png") right center no-repeat;
	background-size: cover;
}

.craps-point {
	position: relative;

	&:before,
	&:after {
		position: absolute;
		content: '';
		top: 7px;
	}

	&:before {
		left: -20px;
		height: 100%;
		width: 1px;
		background-color: rgba(#000,.05);

		@include media-breakpoint-down(xs) {
			left: -9px;
		}
	}

	&:after {
		left: -23px;
		background-color: #d7d8d9;
		@include size(7px);
		@extend .rounded-circle;

		@include media-breakpoint-down(xs) {
			left: -12px;
		}
	}

	p {
		color: #676b6e;
	}

	p:not(:last-child) {
		margin-bottom: 20px;
	}

	h4 {
		color: #252d34;
	}

	ul {

		li {
			background: url('#{$images-icons}red-diamond.svg') 0 12px no-repeat;
			color: #676b6e;
			padding: 5px 0 5px 15px;
		}
	}
}

.craps-point:not(:last-child) {
	padding-bottom: 50px;

	@include media-breakpoint-only(md) {
		padding-bottom: 30px;
	}

	@include media-breakpoint-down(sm) {
		padding-bottom: 20px;
	}
}

.red-arrow-box {
	border: 2px solid rgba(#000,.05);
	background: url("#{$images-background}red-arrow-box-bg.png") right bottom no-repeat;
	margin-top: 20px;

	h4 {
		color: #373b3f;
		padding-right: 10px;

		@include media-breakpoint-only(md) {
			font-size: 18px;
		}
	}
}

.red-arrow-box-left {
	position: relative;
	height: 150px;
	box-shadow: 0 0 0 1px #d20909;
	@include gradient-x(#e30f0f, #d20909, 0%, 100%);

	&:after {
		position: absolute;
		content: '';
		top: 0;
		right: -23px;
		bottom: 0;
		margin: auto;
	  width: 0;
    height: 0;
    border-top: 76px solid transparent;
    border-left: 22px solid #d20909;
    border-bottom: 76px solid transparent;
	}
}

.craps-strategy {
	border-bottom: 1px solid rgba(#000,.05); 
	padding-bottom: 45px;
}


.red-num {
	display: inline-block;
	font-size: 16px;
	color: #fff;
	line-height: 30px;
	text-align: center;
	background-color: $red;
	@include size(30px);
	@extend .rounded-circle;
	margin-right: 15px;
}

.craps-etiquette {
	background: url("#{$images-background}craps-etiquette-bg.png") right 70px no-repeat;
}

.craps-best-casinos {

	.casino-highlights {
		flex: 0 0 275px;
    max-width: 275px;
	}

	.casino-buttons {
		flex: 0 0 165px;
    max-width: 165px;
	}
}

@include media-breakpoint-only(md) {

	.red-arrow-box {

		.h1 {
			font-size: 28px;
		}
	}
}

@include media-breakpoint-down(sm) {
	
	.craps-rules,
	.slots-rules,
	.video-poker-rules,
	.blackjack-rules,
	.single-blog-content,
	.news-wrapper {

		.mpcasinos {
			position: static !important;
			max-width: 235px;
			margin: 0 auto;
			margin-top: 15px;

			&.is_stuck + div {
				display: none !important;
			}
		}
	}
}

@include media-breakpoint-between(sm,md) {

	.craps-best-casinos {

		.casino-highlights {
			flex: 0 0 181px;
	    max-width: 181px;
		}

		.casino-buttons {
			flex: 0 0 145px;
	    max-width: 145px;
		}
	}
}

@include media-breakpoint-only(sm) {

	.red-arrow-box {

		.h1 {
			font-size: 26px;
		}
	}
}

@include media-breakpoint-down(xs) {

	.craps-rules {

		h3 {
			text-align: center;
		}
	}

	.red-arrow-box {
		border: none;
		background: none;
	}

	.red-arrow-box-left {
		height: auto;
		padding: 20px;
		background: $red;
		margin-bottom: 30px;

		&:after {
			content: '';
			top: 100%;
			bottom: auto;
			right: 0;
			left: 0;
	    border-left: 30px solid transparent;
	    border-right: 30px solid transparent;
	    border-top: 15px solid $red;
		}
	}

	.craps-best-casinos {

		// .casino-highlights {
		// 	flex: 0 0 181px;
	 //    max-width: 181px;
		// }

		.casino-buttons {
			flex: 0 0 100%;
	    max-width: 100%;
		}
	}
}
