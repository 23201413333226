$black: #000;
$gray-100: #f2f2f2;
$gray-300: #dbdee0;
$gray-500: #77787a;
$gray-600: #666666;
$gray-700: #4c4c4c;
$gray-800: #333;
$white: #fff;

$grays: (
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800
) !default;

$blue:    #007bff !default;
$red:     #cd0303 !default;
$green:   #33cc33 !default;

$theme-colors: (
  primary: $blue,
  secondary: $gray-300,
  success: $green,
  danger: $red,
  light: $gray-100,
) !default;

$enable-transitions: true;
$enable-print-styles: false;

$spacer: 0;
$spacers: (
  0: 0,
  5: 5px,
  10: 10px,
  15: 15px,
  20: 20px,
  25: 25px,
  30: 30px,
  35: 35px,
  40: 40px,
  45: 45px,
  50: 50px,
  55: 55px,
  60: 60px,
  85: 85px
);

$sizes: (
);

$body-color: $black;

$link-color: inherit;
$link-hover-color: $red;
$link-hover-decoration: none;

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1230px
);

$container-max-widths: (
  sm: 720px,
  md: 960px,
  lg: 1200px
);

$font-family-sans-serif: 'Muli', sans-serif;

$font-size-base: 15px;
$font-size-sm: 12px;
$font-size-lg: 16px;
$blockquote-font-size: $font-size-base;
$input-height-inner: $font-size-base;
$input-height-inner-sm: $font-size-sm;
$input-height-sm: $font-size-sm;
$input-height-inner-lg: $font-size-lg;
$navbar-brand-height: $font-size-base;
$close-font-size: $font-size-base;

$line-height-base: ( 24 / 15 );
$line-height-sm: 1;
$headings-font-weight: 700;
$headings-line-height: 1.3;
// $headings-color: $blue;

$h1-font-size: rem(35);
$h2-font-size: rem(28);
$h3-font-size: rem(24);
$h4-font-size: rem(20);
$h5-font-size: 15px;
$h6-font-size: 12px;

$display1-weight: 700;

$input-btn-line-height: 1;

$input-btn-border-width: 2px;

$input-btn-padding-y-sm: 6px;
$input-btn-padding-x-sm: 8px;
$input-btn-font-size-sm: 10px;

$input-btn-padding-y-md: 12px;
$input-btn-padding-x-md: 20px;

$input-btn-padding-y-lg: 13px;
$input-btn-padding-x-lg: rem(65);
$input-btn-font-size-lg: 20px;

// $input-btn-padding-y-lg: rem(14);
// $input-btn-padding-x-lg: rem(32);

$input-btn-padding-x: 20px;
$input-btn-padding-y: 15px;

$btn-color-gray: #848484;

$btn-font-weight: 700;
$btn-border-radius: 4px;
$btn-border-radius-sm: 4px;
$btn-border-radius-md: 4px;

$btn-transition: all .25s ease-in-out;

$btn-box-shadow: none;
$btn-focus-box-shadow: none;

$border-radius: 4px;

$list-inline-padding: -4px;

$modal-backdrop-opacity: .9;