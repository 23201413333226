.mobile-casino-banner {
	padding: 30px 0;

	.btn {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@include media-breakpoint-down(md) {

	.mobile-casino-banner {
		padding: 20px 0;
	}
}
