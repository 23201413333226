.listing-content {

	h4 {
		font-size: 18px;
	}
	
	.entry-content {

		table {
			width: 100%;
			font-size: 12px;

			thead {
				background-color: #f0f1f3;

				th {
					color: rgba(#4b5966,0.7);
					height: 45px;
					text-align: center;

					&:first-child {
						text-align: left;
					}
				}
			}
		}

		h2 {
			font-size: 25px;
			@extend .muli-b;
		}

		h3 {
			font-size: 23px;
			@extend .muli-bl;
			margin: 15px 0;
		}
	}
}

.listing-menu {

	li {
		background: url('#{$images-icons}you-find-menu-icon.svg') 0 no-repeat;
	}

	a {
		display: block;
		font-size: 13px;
		@extend .muli-sb;
		color: #4b5966;
		padding: 8px 30px;

		&:hover {
			color: $red;
		}
	}
}

.casinos-table {

	p {
		font-size: 12px;
	}

	.tr-name {
		border-left: 1px solid rgba(#000,0.05);
		padding-left: 18px;
	}
}

.casinos-row {

	&:first-child {
		background-color: #f0f1f3;
		text-transform: uppercase;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;

		p {
			color: rgba(#4b5966,0.7);
		}

		>div:last-child {
			border-right: 0;
			border-left: 0;
		}

		.tr-name {
			border-left: 0;
		}
	}

	&:last-child {

		.tr-name {
			border-bottom-left-radius: 4px;
		}

		.tr-visit {
			border-bottom-right-radius: 4px;
		}
	}


	>div {
		border-right: 1px solid rgba(#000,0.05);
		border-bottom: 1px solid rgba(#000,0.05);

		p {
			color: #4b5966;
			line-height: 1;
			padding: 10px 0;
		}
	}
}

@include media-breakpoint-up(sm) {
	.tr-name {
		flex: 0 0 178px;
	  max-width: 178px;
	}

	.tr-rating {
		flex: 0 0 90px;
	  max-width: 90px;
	}

	.tr-num-games {
		flex: 0 0 110px;
	  max-width: 110px;
	}

	.tr-bonus {
		flex: 0 0 295px;
	  max-width: 295px;
	}

	.tr-visit {
		flex: 0 0 157px;
	  max-width: 157px;
	}
}

@include media-breakpoint-only(md) {

	.tr-bonus {
		flex: 0 0 154px;
	  max-width: 154px;
	}
}

@include media-breakpoint-only(sm) {

	.tr-bonus {
		flex: 0 0 154px;
	  max-width: 154px;
	}
}

@include media-breakpoint-down(xs) {

	.casinos-row {
		width: 300px;
		margin: 0 auto;

		&:not(:last-child) {
			padding-bottom: 30px;
			margin-bottom: 30px;
			border-bottom: 1px solid rgba(#000,0.05);
		}


		>div {
			border-right: 0;
			border-bottom: 0;
		}
	}

	.casinos-table {

		.tr-name {
			padding-left: 0;
			border-left: 0;
		}
	}
}