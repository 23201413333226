.contact-page {
	background: url("#{$images-background}contact-bg.jpg") right center no-repeat;
	background-size: cover;
	padding: 40px 0 65px;

	select {
		background: url("#{$images-arrows}arrow-contact.svg") calc(100% - 15px) center no-repeat;
	}

	textarea.form-contact {
		padding-top: 15px;
		height: 100px;
	}

	.btn {
		width: 240px;
		font-size: 14px;
		padding-left: 20px;
		padding-right: 20px;
		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}
}

.contact-form-wrapper {
	padding: 30px; 
	@include media-breakpoint-down(xs) {
		padding: 20px 10px;
	}
}

.contact-form-left {
	background-color: #f2f4f5;
}

.contact-form-right {
	background-color: $white;
	padding: 30px 30px 20px 0;

	.entry-content {

		p {
			font-size: 13px;
			margin-bottom: 30px;
			@include media-breakpoint-down(xs) {
				margin-bottom: 10px;
			}
		}

		address {
			margin-bottom: 5px;

			a {
				@extend .muli-b;
				color: $gray-800;
			}
		}
	}
}

.form-contact {
	width: 100%;
	height: 50px;
	font-size: 14px;
	color: $gray-600;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding-left: 20px;
	margin-bottom: 20px;
}

.contact-buttons {

		.row {
			margin-left: -5px;
			margin-right: -5px;
		}

		.col-lg {
			padding-left: 5px;
			padding-right: 5px;
		}
}

.contact-btn {
	display: inline-block;
	width: 100%;
	font-size: 12px;
	padding: 13px 0 16px;
	margin-bottom: 10px;

	&:not(:last-of-type){
		margin-right: 9px;
	}

	&:hover {

		span {
			opacity: 1;
		}
	}

	span {
		height: 28px;
		opacity: .5;
	}
}

.btn-call-supp {
	background-color: #2aa92a;
}

.btn-chat {
	background-color: #ecba32;
}

.btn-twitter-sup {
	background-color: #19bddb;
}

.btn-follow {
	background-color: #1677cd;
}

@include media-breakpoint-down(xs) {

		.contact-form-right {
			padding: 15px 15px 5px 15px;
		}
}