.rouletter-rules {

	.rules-content {
		background: url("#{$images-background}how-play-roulette-bg.png") right center no-repeat;
		background-size: cover;
	}
}

.bet-table {
	position: relative;

	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 61px;
		height: 61px;
		background-image: url("#{$images-logos}correct-logo-w.png");
		right: -20px;
		z-index: 1;
	}

	&:before {
		top: -20px;
	}

	&:after {
		bottom: -20px;
	}

	.row:last-child {
		
		.bet-table-left,
		.bet-table-right {
			border-bottom: none;
		}
	}
}

.bet-table-left {
	padding: 40px 40px 40px 0;
	border-bottom: 1px solid rgba(#000,.1);

	p {
		font-size: 16px;
		line-height: (22/16);
		color: #333;
	}
}

.bet-table-right {
	padding: 40px 0 40px 40px;
	border-bottom: 1px solid #e50f0f;

	li {
		font-size: 21px;
		color: $white;
		@extend .muli-l;
		line-height: 1;

		&:not(:last-child) {
			margin-bottom: 30px;
		}

		span {
			@extend .muli-bl;
		}
	}
}

.roulette-box {
	position: relative;
	border: 1px solid #e6e6e6;
	padding: 90px 30px 30px;
}

.roulette-circle {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	margin: auto;
	transform: translate(0, -50%);
	@include size(120px);
}

@include media-breakpoint-up(md) {

	.roulette-boxes {
		margin-top: 90px;
	}
}

@include media-breakpoint-only(sm) {

	.roulette-boxes {
		margin-top: 70px;
	}
}

@include media-breakpoint-down(md) {

	.bet-table-left {
		padding: 20px 20px 20px 0;
	}

	.bet-table-right {
		padding: 20px 0 20px 20px;
	}
}

@include media-breakpoint-between(sm,md) {

	.bet-table-right {

		li {
			font-size: 19px;

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}
}

@include media-breakpoint-down(xs) {

	.bet-table-right {

		li {
			font-size: 16px;

			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}

	.roulette-boxes {
		margin-top: 50px;
	}

	.roulette-circle {
		@include size(80px);
	}

	.roulette-box {
		position: relative;
		border: 1px solid #e6e6e6;
		padding: 50px 20px 20px;
	}

	.bet-table-left {
		padding: 15px 0;

		p {
			font-size: 15px;

		}
	}

	.bet-table-right {
		padding: 15px 0;
	}
}

