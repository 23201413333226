
.thumb {
	position: relative;
	@extend .d-block;
	max-width: 100%;
	overflow: hidden;
	text-align: center;
	transition: $default-transition;
		
		img {
			@include element-center(image);
			backface-visibility: hidden;
			max-width: none;
			transition: $default-transition;
		}

		a {

				&:hover img {
					transform: scale(1.03);
				}
		}

}

.thumb-auto {

		img {
			position: static;
			@include img-fluid;
			height: auto;
		}
}

.thumb-opacity {
	background-color: $black;

	img {
		opacity: .5;
	}
}

$thumb-sizes: (
	mpc: (
		w: 30px,
		h: 30px
	),
	rhb: (
		w: 104px,
		h: 104px
	),
	noc: (
		w: 102px,
		h: 102px
	),
	news-item: (
		w: 100%,
		h: 173px
	),
	casino-rev: (
		w: 138px,
		h: 138px
	),
	casino-introduct: (
		w: 470px,
		h: 260px
	),
	casino-profile: (
		w: 70px,
		h: 70px
	),
	comments-profile: (
		w: 112px,
		h: 112px
	),
	lg: (
		h: 480px
	),
	logo-sm: (
		w: 40px,
		h: 40px
	),
	sticky-headers: (
		w: 66px,
		h: 66px
	),
	sticky-bonuses: (
		w: 91px,
		h: 91px
	),
	news: (
		w: 150px,
		h: 130px
	),
	botton-banner: (
		w: 80px,
		h: 80px
	),
);

@each $name, $sizes in $thumb-sizes {
	$width: map-get($sizes, w);
  $height: map-get($sizes, h);

		.thumb-#{$name} {
			width: $width;
			height: $height;
		}
}


$thumb-lg-sizes: (
	home-lg-casino: (
		w: 580px,
		h: 372px
	),
	home-md-casino: (
		w: 333px,
		h: 181px
	),
	home-sm-casino: (
		w: 161px,
		h: 181px,
	),
);

@include media-breakpoint-up(lg) {

		@each $name, $sizes in $thumb-lg-sizes {
			$width: map-get($sizes, w);
      $height: map-get($sizes, h);

				.thumb-#{$name} {
					width: $width;
					height: $height;
				}
		}
}

$thumb-md-sizes: (
	sports: (
		w: auto,
		h: 575px
	),
	post: (
		w: 290px,
		h: 290px
	),
	cat: (
		w: 100px,
		h: 100px
	),
	list: (
		w: 86px,
		h: 86px
	),
	comment: (
		w: 86px,
		h: 86px
	),
	home-lg-casino: (
		w: 460px,
		h: 372px
	),
	home-md-casino: (
		w: 333px,
		h: 181px
	),
	home-sm-casino: (
		w: 161px,
		h: 181px,
	),
);

@include media-breakpoint-only(md) {

		@each $name, $sizes in $thumb-md-sizes {
			$width: map-get($sizes, w);
      $height: map-get($sizes, h);

				.thumb-#{$name} {
					width: $width;
					height: $height;
				}
		}
}

$thumb-sm-sizes: (
	sports: (
		w: auto,
		h: 575px
	),
	post: (
		w: 330px,
		h: 330px
	),
	cat: (
		w: 100px,
		h: 100px
	),
	list: (
		w: 86px,
		h: 86px
	),
	comment: (
		w: 100px,
		h: 100px
	),
	home-lg-casino: (
		w: auto,
		h: 372px
	),
	home-md-casino: (
		w: auto,
		h: 181px
	),
	home-sm-casino: (
		w: auto,
		h: 181px,
	),
	news: (
		w: 115px,
		h: 100px
	),
);

@include media-breakpoint-only(sm) {

		@each $name, $sizes in $thumb-sm-sizes {
			$width: map-get($sizes, w);
      $height: map-get($sizes, h);

				.thumb-#{$name} {
					width: $width;
					height: $height;
				}
		}
}

$thumb-xs-sizes: (
	sports: (
		w: auto,
		h: 480px
	),
	post: (
		w: 290px,
		h: 290px
	),
	cat: (
		w: 80px,
		h: 80px
	),
	list: (
		w: 100px,
		h: 100px
	),
	comment: (
		w: 45px,
		h: 45px
	),
	home-lg-casino: (
		w: auto,
		h: 260px
	),
	home-md-casino: (
		w: auto,
		h: 181px
	),
	home-sm-casino: (
		w: auto,
		h: 181px,
	),
	news: (
		w: 115px,
		h: 100px
	),
	botton-banner: (
		w: 50px,
		h: 50px
	),
);

@include media-breakpoint-down(xs) {

		@each $name, $sizes in $thumb-xs-sizes {
			$width: map-get($sizes, w);
      $height: map-get($sizes, h);

				.thumb-#{$name} {
					width: $width;
					height: $height;
				}
		}
}

