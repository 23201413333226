
.ng-slot {
	padding-bottom: 30px;
	transition: $default-transition;

		.thumb {
			height: 152px;
			z-index: 2;
			background-color: $black;
		}

		.hover {
			transform: scale(0);
			@include element-center;
		}

		.h5 {
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			transition: $default-transition;
		}

		&:hover {
			padding-bottom: 0;

				.thumb {
					height: 182px;
				}

				img {
					opacity: .22;
				}

				.h5 {
					opacity: 0;
				}

				.hover {
					z-index: 3;
					transform: scale(1);
				}
		}
}

.ng-row {
	margin-left: -13px;
	margin-right: -13px;

		> .col,
		> [class*=col-] {
			padding-left: 13px;
			padding-right: 13px;
		}
}

@include media-breakpoint-down(xs) {

	.ng-slot {
		max-width: 190px;
		margin: 0 auto;
	}
}