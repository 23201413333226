.submission-form {
	background-color: #f4f4f4;

	label {
		color: 	rgba(#292c2e,.5);
	}

	textarea {
		height: 100px;
		resize: none;
	}
}

.form__checkbox {

	&__indicator {
		width: 24px;
		height: 24px;
	}

	&__label {
		font-size: 12px;
		color: #292c2e;
	}
}

input:checked~.form__checkbox__indicator {
	background-color: #67c84d;

	&:before {
		content: "";
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  margin: auto;
	  width: 12px;
	  height: 12px;
	  background-color: #fff;
	  border-radius: 50%;
	}
}

.submision-sidebar {
	border: 2px solid #f4f4f4;

	li {
		font-size: 12px;
		color: #292c2e;
		background: url(../images/icons/green-item.svg) 0 no-repeat;
		padding: 7px 30px;
	}
}

#formsubmission {

	.has-error {

		~.text-error {
			display: block;
		}
	}

	.text-error {
		display: none;
		color: #b91d10;
		margin-bottom: 20px;
	}
}