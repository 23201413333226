.comments-text {

	.raty-red-stars {
    width: 270px;
  }
}

.comments-write {
	text-align: right;
	font-size: 11px;
	text-transform: uppercase;
	color: rgba(#333,.5);

	textarea {
		display: block;
		width: 100%;
		height: 110px;
		resize: none;

		&::placeholder {
			font-size: 15px;
			padding-left: 20px;
		}
	}
}

.comments-boxes {
	border: 2px solid #e0e3e7;
	padding: 30px 30px 0;
}

.gray-line {
	width: 100%;
	height: 2px;
	background-color: #e0e3e7;
	margin-bottom: 30px;
}

.checkboxes {
	font-size: 0;
}

.form-checkbox {
	position: relative;
	margin-bottom: 30px;
}

.form-checkbox-label {
	position: relative;
	display: inline-block;
	font-size: 14px;
	color: $gray-600;
	line-height: (22/14);
	padding-left: 40px;

	&:before {
		position: absolute;
		content: '';
		width: 30px;
		height: 30px;
		left: 0;
		top: 0;
		border-radius: 4px;
		border: 2px solid #e5e6e6;
	}
}

.form-checkbox-input:checked ~ {

	.form-checkbox-label {

		&:before {
			background: url(../images/icons/checked-icon.svg) center no-repeat;
			border-color: $red;
			background-color: $red;
		}
	}
}
    