.mobile-casinos {

	.casino-name {
		padding-right: 40px !important;
	}

	.casino-highlights {
		flex: 0 0 230px;
	  max-width: 230px;
	  text-align: center;
		padding-right: 60px !important;
		padding-left: 60px !important;

		h3 {
			color: #4b5966;
			line-height: 1;
		}

		.row {
			margin-right: -5px;
			margin-left: -5px;

			.col {
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		.mobile-inactive {
				
				img {
					opacity: .25;
				}
		}
	}
}

@include media-breakpoint-down(md) {

	.mobile-casinos {

		.casino-highlights {
			flex: 0 0 185px;
		  max-width: 185px;
			padding-right: 30px !important;
			padding-left: 30px !important;
		}
	}
}

@include media-breakpoint-down(xs) {

	.mobile-casinos {

		.casino-highlights {
			padding-top: 15px;
			margin: 0 auto;
		}
	}
}

