// Color contrast
@mixin color-yiq($color) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= 200) {
    color: $btn-color-gray;
    .btn-arrow-down {
      border-top-color: $btn-color-gray;
    }
  } @else {
    color: $white;
    .btn-arrow-down {
      border-top-color: $white;
    }
  }

  

  &:hover {

  		@if ($yiq >= 200) {
		    color: darken($btn-color-gray, 15%);
        
          .btn-arrow-down {
            border-top-color: darken($btn-color-gray, 15%);
          }
		  } @else {
		    color: $white;

          .btn-arrow-down {
            border-top-color: $white;
          }
		  }
  }
}