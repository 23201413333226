@each $font, $settings in $fonts {
	$family: map-get($settings, family);
	$weight: map-get($settings, weight);
  $style: map-get($settings, style);

		.#{$font} {
			font-family: $family !important;
			font-weight: $weight !important;
			font-style: $style !important;
		}
}

@include media-breakpoint-down(sm) {

	h1, .h1 { font-size: 30px; }
	h2, .h2 { font-size: 25px; }
	h3, .h3 { font-size: 22px; }
	h4, .h4 { font-size: 18px; }
	h5, .h5 { font-size: 14px; }
}

@include media-breakpoint-down(xs) {

	h1, .h1 { font-size: 25px; }
	h2, .h2 { font-size: 22px; }
	h3, .h3 { font-size: 20px; }
	h4, .h4 { font-size: 16px; }
	h5, .h5 { font-size: 12px; }
}