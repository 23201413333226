.software-providers {

	.thumb {
		height: 130px;
		z-index: 2;

	}

	.row {
		// margin-left: 
	}
}

.btn-providers {
	@include element-center;
	display: none;
	z-index: 3;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	transition: $default-transition;
	box-shadow: inset 0 0 0 3px #eff1f3;

		.btn {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			width: 100%;
			padding: 10px 20px;
			font-size: 14px;
			border-radius: 0;
		}
}

.providers-box {

	&:hover {

	 .btn-providers {
	 		display: block;
		}
	}

	.btn {
		white-space: normal;
		border-bottom-right-radius: 4px;
	 	border-bottom-left-radius: 4px;
	}
}

@include media-breakpoint-down(xs) {

	.software-providers {

		.thumb {

			img {
				max-width: 100%;
			}
		}
	}
}

