.comments {
	padding: rem(60) 0;
}

.comments-wrapper {
	max-width: 1310px;
	padding: 35px 0 15px;
	margin: 0 auto;

	.btn-lg {
		font-size: 14px;
		background-color: #cd0303;
    border-color: #cd0303;
    padding: 17px 55px;

    @include media-breakpoint-down(xs) {
			width: 100%;
    }

		&:hover {
			background-color: #9b0202;
    	border-color: #9b0202;
		}
	}
}

.comments-sort {

	li {
		display: inline-block;
		cursor: pointer;
		@include media-breakpoint-up(sm) {
			padding-left: rem(35);
			padding-right: rem(35);
		}
		@include media-breakpoint-down(xs) {
			padding-left: 6px;
			padding-right: 6px;
		}

		&:first-of-type {
			padding-left: 0;
		}

		&:last-of-type {
			padding-left: 0;
		}
	}

	a:hover {

		.btn-arrow-down{
			border-top-color: $red;
		}
	}

	.btn-arrow-down {
		border-top-color: #848484;
	}
}

.write-review-title {
	font-size: 18px;
}

.commets-review {

	>li {
		background-color: #fff;
		border-radius: 4px;
		padding: 35px 0;
		margin-bottom: 35px;

		>ol {
			@include media-breakpoint-up(md) {
				padding-left: rem(170);
				padding-right: 20px;
			}

			@include media-breakpoint-down(sm) {
				padding-left: 15px;
				padding-right: 15px;
			}

			>li {
				border-top: 1px solid #e5e5e5;
				margin-top: 20px;
				@include media-breakpoint-up(md) {
					padding-right: rem(115);
				}

				.thumb {
					width: 66px;
					height: 66px;
				}
			}
		}
	}

	.thumb-comments-profile {
		margin: 0 auto;
	}

	.btn-outline-light {
		font-size: 11px;
		border-color: #dbdee0;
	}
}

.profile-info {

	p {
		font-size: 12px;
		color: #999;
	}

	img {
		margin-top: 10px;
	}
}

.review-comment {

	h5 {
		font-size: $font-size-lg;
	}

	.raty-red-stars {
		width: 270px;
	}
}

.positive-comment {
	background: url(../images/svg/positive-comments.svg) 0 5px no-repeat;
}

.negative-comment {
	background: url(../images/svg/negative-comments.svg) 0 5px no-repeat;
}

.negative-comment,
.positive-comment {

	p {
		margin-bottom: rem(25);
	}
}

.dots,
.dots:before,
.dots:after {
	width: 5px;
	height: 5px;
	background-color: rgba(#000,0.2);
	border-radius: 50%;
	// margin: 0 5px;
}

.dots {
	position: relative;
	display: inline-block;

	&:before {
		position: absolute;
		content: '';
		top: 0;
		right: 10px;
		margin: auto;
	}

	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 10px;
		margin: auto;
	}
}

.dots-popup {
	position: relative;
	background-color: $red;
	margin-top: 0;
	top: 10px;
	right: 33px;
	text-align: center;
	box-shadow: 0 0 6px fade(#010101, 10);
	z-index: 1;

	&:before {
		position: absolute;
		content: '';
		top: -11px;
		right: 23px;
		width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid $red;
	}

	li {
		display:block;
		border-right: none;

		&:not(:last-child) {
			border-bottom: 1px solid #fff;
		}

		a {
			color: #fff;
			padding: 10px;

			&:hover {
				color: #000;

				&:after {
					border-top-color: $red;
				}
			}

			&:after {
				border-top-color: rgba(#000,.9);
			}
		}
	}
}

.review-comment-options {
	height: 100%;
}

.btn-like {
	flex: 0 0 auto;
  width: auto;
  max-width: none;
	font-size: 10px;
	color: rgba(#666,0.5);

	&:not(.active) {
		background: url(../images/svg/btn-like-border.svg) 0 center no-repeat;
	}

	&.active {
		background: url(../images/svg/btn-liked.svg) 0 center no-repeat;
	}

	&.focus {
		color: $gray-600;
	}
}

.comments-nav {
	text-align: center;
	font-size: 0;
}

.page-numbers {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	font-size: 18px;
	text-align: center;
	color: #999;
	@include media-breakpoint-up(sm) {
		width: 65px;
		height: 58px;
		line-height: (58/18);
	}
	@include media-breakpoint-down(xs) {
		width: 45px;
		height: 45px;
		line-height: 45px;
	}

	&:hover {
		color: $red;
	}

	&:not(.current):after {
		position: absolute;
		content: '';
		width: 1px;
		height: 18px;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		background-color: #e0e3e7;
	}

	&.current {
		font-weight: 700;
		color: $red;
		background-color: #fff;
		border-radius: 4px;
	}
}

.next-page,
.prev-page {
	opacity: 0.4;

	&:hover {
		opacity: 1;
	}

	&:after {
		display: none;
	}
}

.next-page {
	background: url(../images/arrows/pagination-next-arrow.svg) center center no-repeat;
}

.prev-page {
	background: url(../images/arrows/pagination-prev-arrow.svg) center center no-repeat;
}

.line-title {
	position: relative;
	font-size: 40px;

	&:after {
		position: absolute;
		content: '';
		width: 100%;
		height: 5px;
		background-color: $green;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		margin: auto;
	}

	span {
		position: relative;
		display: inline-block;
		padding-right: 15px;
		background-color: $white;
		z-index: 2;
	}
}

@include media-breakpoint-up(lg) {

	.review-comment {
		margin-left: -30px;
	}
}

@include media-breakpoint-only(md) {

	.review-comment {
		margin-left: -15px;
	}
}

@include media-breakpoint-down(sm) {

	.line-title {
		font-size: 25px;

		span {
			padding-left: 15px;
		}
	}
}