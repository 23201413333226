.calculator {
	padding-bottom: 95px;
}

.question {
	
	p {
		font-size: 22px;
		@extend .muli-l;
		@include media-breakpoint-down(md) {
			font-size: 19px;
		}
	}
}

.progress-line {
	font-size: 0;
}

.progress-line-bar {
	display: inline-block;
	background-color: #d5d7db;
	width: 19%;
	height: 10px;
	border-radius: 5px;

	&:not(:last-child) {
		margin-right: 1%;
	}

	&.active {
		background-color: $green;
	}
}

.range-wrapper {
	background-color: #f0f1f3;
}

.range-steps {

		&:not(.active) {
			display: none;
		}
}
.range-value {
	border: 1px solid #c0c1c2;
	height: 40px;
	width: 100%;
	font-size: 18px;
	@extend .muli-b;
	padding-left: 20px;

	@include media-breakpoint-down(md) {
		font-size: 16px;
	}

	@include media-breakpoint-up(md) {
		width: 168px;
	}

	@include media-breakpoint-only(sm) {
		width: 100px;
	}
}

.noUi-target {
	height: 40px;
	overflow: hidden;
	padding: 15px 0;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-connects {
	background-color: $white;
  border-radius: 5px;
  overflow: hidden;
}

.noUi-connect,
.noUi-origin  {
	will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
}

.noUi-connect {
	@include gradient-x(#e37676, #f6d1d1, 0, 100%);
}

.noUi-handle {
	background: url('#{$images-buttons}no-Ui-handle.png') center no-repeat;
	position: absolute;
	width: 20px;
	height: 42px;
	top: 0;
	bottom: 0;
	right: -10px;
	margin: auto;

	&:focus {
		outline: none;
	}
}

.range-btn {
	width: 100%;
	@include media-breakpoint-up(md) {
		width: 137px;
	}
}

.final-step {

	.thumb {
		width: 98px;
		height: 98px;
		background-color: $white;
		@include media-breakpoint-down(xs) {
			margin: 0 auto;
		}
	}
}