.how-it-works-left {
	background-color: #f4f4f4;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;

	.form-complaints {
		max-width: 350px;
	}
}

.form-complaints {
	height: 50px;
	font-size: 14px;
	color: rgba(#292c2e,.5);
	background-color: #fff;
	border: 1px solid rgba(#000,.2);
}

select.form-complaints {
	color: #292c2e;
	background: #fff url(../images/arrows/select-arrow.svg) calc(100% - 20px) 50% no-repeat;
}

.how-it-works-right {
	background-color: #b91d10;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;

	ol {
		position: relative;
		counter-reset: items;

		&:before {
			position: absolute;
			content: "";
			top: 0;
			bottom: 0;
			margin: auto;
			border-left: 4px dotted rgba(#fff,.2);
			left: 16px;
		}

		li {
			position: relative;
			font-size: 18px;
			@extend .text-white;
			padding-left: 50px;
			@include media-breakpoint-up(lg) {
				font-size: 18px;
			}
			@include media-breakpoint-down(md) {
				font-size: 16px;
			}

			&:before {
				position: absolute;
				counter-increment: items;
				content: counter(items);
				width: 34px;
				height: 34px;
				border-radius: 50%;
				top: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				text-align: center;
				color: #292c2e;
				line-height: 34px;
				background-color: #fff;
			}

			&:not(:last-child) {
				margin-bottom: 25px;
			}
		}
	}
}

.complaints-step-border {
	border-bottom: 2px solid rgba(0,0,0,.05);
}

.btn-complain-question {
	display: block;
	text-align: left;
	white-space: normal;
	transition: none;
	@include media-breakpoint-up(md) {
		font-size: 18px;
		line-height: (25/18);
	}
	@include media-breakpoint-down(sm) {
		font-size: 16px;
		line-height: (22/16);
	}

	&:before {
		position: absolute;
		content: "";
		width: 28px;
		height: 28px;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		font-size: 18px;
		color: #171717;
		background: #f4f4f4 url(../images/arrows/question-arrow-gray.svg) center center no-repeat;
		border: 2px solid #f4f4f4;
		border-radius: 50%;
	}

	&[aria-expanded=true] {

		&:before {
			background: #fff url(../images/arrows/question-arrow-red.svg) center 8px no-repeat;
			border-color: #b91d10;
		}

	}

	&:not(:last-child) {
		margin-bottom: 30px;
	}

	.btn-arrow-down {
		border-top-color: #848484;
	}
}

.form__radio {

	&__indicator {
		width: 24px;
		height: 24px;
		background-color: #f4f4f4;
	}

	&__label {
		color: #171717;
		@include media-breakpoint-up(md) {
			font-size: 18px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 16px;
		}
	}
}

input:checked~.form__radio__indicator {
	background-color: #67c84d;

	&:before {
		content: "";
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  margin: auto;
	  width: 12px;
	  height: 12px;
	  background-color: #fff;
	  border-radius: 50%;
	}
}

