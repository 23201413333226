.casino-bonuses-header {
	background-color: #292c2f;
}

.casino-bonuses-box {
	overflow: hidden;

	header {
		color: rgba(#000, .7);
		background-color: #d9dbdc;
	}

	.btn {
		font-size: 14px;
	}
}

.restricted-btn {
	background-color: rgba(#000,.15);
	padding-top: 14px;
	padding-bottom: 14px;
}

.casino-bonuses-sticky {
	position: absolute;
	border: 1px solid #c9cccf;

	h4 {
		font-size: 18px;

		a {
			color: #292c2f;
		}
	}

	.raty-red-stars {
		width: 180px;
		margin: 0 auto;
	}
}

@include media-breakpoint-up(md) { 

	.casino-bonuses-sticky {
		width: 240px;
	}
}

@include media-breakpoint-down(sm) { 

	.casino-bonuses-sticky {
		position: static !important;
		max-width: 275px;
		margin: 0 auto;

		&.is_stuck + div {
			display: none !important;
		}
	}
}

@include media-breakpoint-down(xs) { 

	.casino-bonuses-box {
			max-width: 275px;
			margin: 0 auto;
	}
}


