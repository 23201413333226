.blackjack-rules {

	.rules-content {
		background: none;
	}
}

.li-item-line {

	>li {
		position: relative;
		@extend .muli-b;
		@extend .text-gray-600;
		padding: 10px 0 10px 22px;

		&:before {
			position: absolute;
			content: '';
			top: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			width: 8px;
			height: 4px;
			border-radius: 2px;
			background-color: $red;
		}
	}
}

.blackjack-strategies {

	ol {

		li {
			@extend .muli-b;
			padding-top: 5px;
			padding-bottom: 5px;

			&:before {
				color: $white;
				content: counter(items);
				font-size: 13px;
				@include size(20px);
				@extend .rounded-circle;
				background-color: rgba(#000,.6);
				text-align: center;
				line-height: 20px;
			}
		}
	}
}

.dealer-table {
	width: 100%;
	border: none;
	overflow: auto;

	tr {
		border: none;
	}

		th, td {
			padding: 25px;
			font-size: 15px;
			border: none;
			@include media-breakpoint-down(md) {
				padding: 10px;
			}
		}

		strong {
			font-weight: 900;
		}

		thead {

				th {
					font-weight: 900;
				}

				th:not(:first-child) {
					color: #ce0a0a;
					border: 2px solid #e5e5e5;
					border-bottom-width: 0;
				}
		}

		tbody {

				tr {

					td {
						color: #333;
						text-align: center;
						border-right: 1px solid rgba($black, .05);

						&:first-child {
							color: rgba($white, .7);
							text-align: left;
						}

						&:last-child {
							border-right-width: 2px;
						}
					}

					&:nth-child(even) {

							td {
								background-color: #f7f7f7;
							}

							td:first-child {
								background-color: #bd0808;
								border-right: 2px solid #bd0808;
							}

							&:last-child {

									td:first-child {
										border-bottom: 2px solid #bd0808;
									}
							}

					}

					&:nth-child(odd) {

							td {
								background-color: #eaeaea;
							}

							td:first-child {
								background-color: #b20808;
								border-right: 2px solid #b20808;
							}

							&:last-child {

									td:first-child {
										border-bottom: 2px solid #b20808;
									}
							}
					}

					&:first-child {

							td {
								background-color: rgba($black, .1);
								
									&:first-child {
										color: $white;
										background-color: #bd0808;
										border-bottom: 2px solid #ad0808;
										border-right: 2px solid #bd0808;
									}
							}
					}

					&:last-child {

						td:not(:first-child) {
							border-bottom: 2px solid #e5e5e5;
						}
					}
				}
		}
}

.key-table {
	background-color: #f7f7f7;
	border: 2px solid #e5e5e5;
	padding: 30px;

	.col-12 {

		p:not(:last-child) {
			padding-bottom: 20px;
			border-bottom: 2px solid #eaeaea;
		}

		p:not(:first-child) {
			padding-top: 20px;
		}

		p {
			font-size: 18px;
			color: #333;
		}
	}
}

.table-wrapper {
	overflow: auto;
}

@include media-breakpoint-down(md) {

	.key-table {
		padding: 15px;

		.col-12 {

			p:not(:last-child) {
				padding-bottom: 10px;
			}

			p:not(:first-child) {
				padding-top: 10px;
			}

			p {
				font-size: 16px;
			}
		}
	}
}

@include media-breakpoint-down(xs) {

	.key-table {

		p{
			padding: 10px 0;
			border-bottom: 2px solid #eaeaea;
		}
	}
}
